import React from "react";
import { Circle, RingContainer, Ringering } from "./style";

const BlinkDotIcon = () => {
    return (
        <RingContainer>
            <Ringering />
            <Circle />
        </RingContainer>
    );
};

export default BlinkDotIcon;
