import { Client } from "../../commoncomponents/Base/api";
import { getDashboardDataApiUrl } from "../../constants/apiConstants";
import { DashboardRecord } from "./type";

const trasformData = (data: any): DashboardRecord[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        rank: item.rank,
        contract: item.contract || "",
        collectionName: item.collectionName || "",
        totVolNow: item?.totVolNow ? Math.round(item.totVolNow) : "",
        totVolDelta: item?.totVolDelta ? Math.round(item.totVolDelta * 100) : "",
        medianSaleNow: parseFloat(item.medianSaleNow).toFixed(2) || "",
        medianSaleDelta: item?.medianSaleDelta ? Math.round(item.medianSaleDelta * 100) : "",
        numTransNow: item.numTransNow,
        numTransDelta: item?.numTransDelta ? Math.round(item.numTransDelta * 100) : "",
        socialLinks: {
            imageUrl: item.imageUrl || "",
            twitterUrl: item.twitterUrl,
            websiteUrl: item.websiteUrl,
            discordUrl: item.discordUrl,
            marketAffUrl: item.marketAffUrl || "",
            openseaSlug: item.openseaSlug || "",
            etherscanUrl: item.etherscanUrl || ""
        },
        twitterUsername: item.twitterUsername || ""
    }));
};

export const fetchDashboardData = (timeFilter: [number, string] | undefined): Promise<DashboardRecord[]> => {
    return Client.getInstance()
        .getData(getDashboardDataApiUrl(getTimeInMins(timeFilter)), true)
        .then((res) => Promise.resolve(trasformData(res.data)))
        .catch((err) => Promise.reject(err));
};

const getTimeInMins = (timeFilter: [number, string] | undefined): number => {
    if (!timeFilter) {
        return 0;
    }
    let multiplier = 1;
    if (timeFilter[1] === "hours") {
        multiplier = 60;
    }
    if (timeFilter[1] === "days") {
        multiplier = 24 * 60;
    }
    return timeFilter[0] * multiplier;
};
