import { Container } from "@mui/material";
import React from "react";
import { oldWebsiteUrl } from "../../constants/appConstants";

const WebsiteChartView = () => {
    return (
        <Container style={{ height: "100vh" }}>
            <iframe width={"100%"} style={{ height: "100%" }} src={oldWebsiteUrl} allowFullScreen={true}></iframe>
        </Container>
    );
};

export default WebsiteChartView;
