/* eslint-disable no-console */
import { ethers } from "ethers";
import React, { FC, useState } from "react";
import { TargetPaymentInfo } from "../payment/Types";
import { ConnectButton } from "./style";

type SubscribeButtonProps = {
    targetPaymentInfo: TargetPaymentInfo;
    reportPaymentMade: (txHash: string) => void;
};

const SubscribeButton: FC<SubscribeButtonProps> = ({ targetPaymentInfo, reportPaymentMade }) => {
    const [error, setError] = useState("");
    const [hasInsufficientFunds, setHasInsufficientFunds] = useState<boolean>(false);
    const [txs, setTxs] = useState<ethers.providers.TransactionResponse[]>([]);
    const startPayment = async () => {
        try {
            if (!targetPaymentInfo) {
                return;
            }
            await window.ethereum.request({ method: "eth_requestAccounts" });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            ethers.utils.getAddress(targetPaymentInfo.securedWalletAddress);
            const tx = await signer.sendTransaction({
                to: targetPaymentInfo.securedWalletAddress,
                value: ethers.utils.parseEther(targetPaymentInfo.paymentValue)
            });
            console.log("tx", tx);
            setTxs([tx]);

            reportPaymentMade(tx.hash);

            // console.log("Transaction request successfully sent! See Etherscan for details:");
            // console.log(`https://rinkeby.etherscan.io/tx/${tx.hash}`);
            // console.log("Now just waiting for transaction to be completed..");

            // const completedTransaction = await tx.wait();
            // console.log("logs bloom", completedTransaction.logsBloom);
            // delete completedTransaction.logsBloom;
            // console.log("Transaction request successfully sent! Details:");
            // console.log(completedTransaction);
        } catch (err: any) {
            if (err.code === "INSUFFICIENT_FUNDS") {
                setHasInsufficientFunds(true);
            } else {
                setError(err.message);
            }
        }
    };

    const handleSubmit = async (e: any) => {
        setError("");
        await startPayment();
    };

    if (hasInsufficientFunds) {
        return (
            <ConnectButton disabled>
                <span color={"red"}>Insufficient funds</span>
            </ConnectButton>
        );
    }

    return (
        <>
            {targetPaymentInfo && (
                <ConnectButton
                    id={"wallet-connect-button"}
                    onClick={(e) => {
                        handleSubmit(e);
                    }}>
                    <div>
                        Subscribe
                        <div style={{ fontSize: 10 }}>Lifetime Ξ{targetPaymentInfo.paymentValue}</div>
                    </div>
                </ConnectButton>
            )}
        </>
    );
};
export default SubscribeButton;
