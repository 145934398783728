import { Client } from "../../commoncomponents/Base/api";
import {
    getAllLiveWhaleDataApiUrl,
    getAllLiveWhaleDuoFeed,
    getLiveWhaleDataApiUrl,
    getLiveWhaleDuoFeed
} from "../../constants/apiConstants";
import { LiveWhaleDuoFeedType, LiveWhaleType } from "./type";

const trasformData = (data: any): LiveWhaleType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        buyRatio: parseFloat(item.buyRatio) || "",
        txBuys: parseInt(item.txBuys) || "",
        avgBuys: item.avgBuys || "",
        totvolBuys: parseFloat(item.totvolBuys) || "",
        txSales: parseInt(item.txSales) || "",
        avgSales: item.avgSales || "",
        txMint: parseInt(item.txMint) || "",
        totvolSales: parseFloat(item.totvolSales) || "",
        txTotal: parseInt(item.txTotal) || "",
        avgTotal: item.avgTotal || "",
        totvol: parseFloat(item.totvol) || "",
        createdDate: item.createdDate || "",
        totalSupply: item.totalSupply || "",
        contractId: item.contract || "",
        schemaName: item.schemaName || "",
        collectionName: item.collectionName || "",
        socialLinks: {
            imageUrl: item.imageUrl || "",
            websiteUrl: item.websiteUrl || "",
            discordUrl: item.discordUrl || "",
            twitterUrl: item.twitterUrl || "",
            marketAffUrl: item.marketAffUrl || "",
            openseaSlug: item.openseaSlug || "",
            etherscanUrl: item.etherscanUrl || ""
        }
    }));
};

export const transformDuoFeedData = (data: any): LiveWhaleDuoFeedType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        ...item,
        timeStamp: item.timeStamp || "",
        whalename: item.whaleName || "",
        txtype: item.txType || "",
        value: item.value || "",
        contractId: item.contractId || "",
        collectionName: item.collectionName || "",
        socialLinks: {
            imageUrl: item.imageUrl || "",
            marketAffUrl: item.marketAffUrl || "",
            openseaSlug: item.marketAffUrl || "",
            tokenMarketAffUrl: item.tokenMarketAffUrl || "",
            websiteUrl: item.websiteUrl || "",
            discordUrl: item.discordUrl || "",
            twitterUrl: item.whaleTwitterUsername || "",
            whaleTwitterUrl: item.whaleTwitterUsername || "",
            whaleMarketAffUrl: item.whaleMarketAffUrl || "",
            etherscanUrl: item.etherscanUrl || ""
        }
    }));
};

export const getLiveWhaleData = (timeFilter: [number, string] | undefined): Promise<LiveWhaleType[]> =>
    Client.getInstance()
        .getData(timeFilter ? getLiveWhaleDataApiUrl(timeFilter[0], timeFilter[1]) : getAllLiveWhaleDataApiUrl(), true)
        .then((res) => Promise.resolve(trasformData(res.data)))
        .catch((err) => Promise.reject(err));

export const getLiveWhaleDuoFeedData = (timeFilter: [number, string] | undefined): Promise<LiveWhaleDuoFeedType[]> =>
    Client.getInstance()
        .getData(timeFilter ? getLiveWhaleDuoFeed(timeFilter[0], timeFilter[1]) : getAllLiveWhaleDuoFeed(), true)
        .then((res) => Promise.resolve(transformDuoFeedData(res.data)))
        .catch((err) => Promise.reject(err));

export const embedTwitter = () => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
};

export const onFilterLiveDuoFeed = (filters: string[], rows: LiveWhaleDuoFeedType[]) =>
    rows.filter((item) => filters.length === 0 || filters.includes(item.txtype.toLowerCase()));
