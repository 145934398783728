import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import ListComponent from "../../commoncomponents/ListComponent";
import { AuthContext } from "../../context/AuthenticationProvider";
import { walletConnect } from "../../utils/connector";
import { renderWhaleFeedColumns } from "./Hooks";
import { LockIcon, LockScreenContainer } from "./Style";
import { CalendarWhaleListType } from "./Type";
import { getCalendarWhaleList } from "./Util";

const CalendarWhaleFeed = () => {
    const [whaleList, setWhaleList] = useState<CalendarWhaleListType[]>([]);
    const { nftid, nftname } = useParams<{
        nftname: string;
        nftid: string;
    }>();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const { isPaidUser } = useContext(AuthContext);

    useEffect(() => {
        getCalendarWhaleList(nftid)
            .then((res: CalendarWhaleListType[]) => {
                setWhaleList(res);
            })
            .catch(() => {
                setWhaleList([]);
            });
    }, []);

    return (
        <Grid container justifyContent={"center"}>
            <Grid position={"relative"} item xs={12} sm={10}>
                {!isPaidUser && <LockIcon style={{ zIndex: 1 }} onClick={walletConnect} />}
                <LockScreenContainer ispaid={isPaidUser}>
                    <ListComponent
                        columns={renderWhaleFeedColumns(true, mobileView, nftname)}
                        isDarkMode
                        compact={false}
                        items={whaleList}
                        noDataFoundText={!isPaidUser ? "." : "No Data Found"}
                        initialSortKey={"score"}
                        initialSortDirection={"DESC"}
                    />
                </LockScreenContainer>
            </Grid>
        </Grid>
    );
};

export default CalendarWhaleFeed;
