import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { walletConnect } from "../../utils/connector";
import AlertsFadeImage from "../../images/alerts_fade_btn.png";
import { AuthContext } from "../../context/AuthenticationProvider";
import { clickHereTextColor } from "../../constants/ColorConstants";

const AlertsView = () => {
    const { isPaidUser } = useContext(AuthContext);
    return (
        <Grid container direction="column" alignItems="center" justifyContent={"flex-start"}>
            <Grid item>
                <h1>NFT Alerts</h1>
            </Grid>
            <Grid item style={{ fontSize: 26, margin: 5, color: "lightgrey" }}>
                Never miss a beat with our real time NFT alerts.
            </Grid>
            <Grid
                item
                style={{ fontSize: 20, color: "grey", marginTop: 5, marginLeft: 5, marginRight: 5, marginBottom: 10 }}>
                Following subscription, join our{" "}
                <a href="https://discord.com/invite/VnGeKyptSA" target="_blank" style={{ color: clickHereTextColor }}>
                    Discord
                </a>
                , and update your{" "}
                <a
                    href="https://45z29xvhwhc.typeform.com/to/Hu59Yk1K"
                    target="_blank"
                    style={{ color: clickHereTextColor }}>
                    role
                </a>
                .
            </Grid>
            {!isPaidUser && (
                <Grid item alignItems={"center"}>
                    <img
                        onClick={walletConnect}
                        src={AlertsFadeImage}
                        style={{ width: "100%", maxHeight: "500px", margin: "10px 0px", cursor: "pointer" }}
                        alt={"unpaidUser"}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default AlertsView;
