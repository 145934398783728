import { Grid, Switch, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GroupButtonTitle } from "../../commoncomponents/CommonStyle";
import ListComponent from "../../commoncomponents/ListComponent";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { moveToCollectionId } from "../../utils/siteUtil";
import { DataGridContainer } from "../neftdata/style";
import { renderIndexColumns, renderNonSignalIndexColumns } from "./hooks";
import { IndexSignalType } from "./Type";
import { getSignalData } from "./Util";

const IndexPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [indexSignalRows, setIndexSignalRows] = useState<IndexSignalType[]>([]);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const [signalView, setSignalView] = useState(false);

    useEffect(() => {
        fetchSignalData(true);
        setSignalView(localStorage.getItem("cryptoSignalView") === "true");
    }, []);

    const fetchSignalData = useCallback((showLoadIndicator) => {
        showLoadIndicator && setIsLoading(true);
        getSignalData()
            .then((data) => {
                setIndexSignalRows(data);
            })
            .catch(() => setIndexSignalRows([]))
            .finally(() => setIsLoading(false));
    }, []);

    const toggleSignalSwitch = () => {
        localStorage.setItem("cryptoSignalView", (!signalView).toString());
        setSignalView(!signalView);
    };

    const getColumns =  useMemo(
        () => (signalView ? renderIndexColumns(true, mobileView) : renderNonSignalIndexColumns(true, mobileView)),
        [signalView, mobileView]
    );

    return (
        <Grid>
            <DataGridContainer style={{ width: "100%" }}>
                <Grid container justifyContent="center">
                    <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                        <Grid item>
                            <h1 style={{ textAlign: "center" }}>cryptoscores Signals Index</h1>
                        </Grid>
                        <Grid item style={{ fontSize: 20, margin: 5, color: "lightgrey", textAlign: "center" }}>
                            Top NFT projects based on our inhouse liquidity thresholds. Follow the colour signals to understand longer term price changes.
                        </Grid>
                    </Grid>
                    <Grid item style={{ padding: mobileView ? 10 : 0, marginTop: 30 }} xs={mobileView ? 12 : 9}>
                        <Grid container justifyContent={"flex-end"}>
                            <Grid item style={{ marginLeft: 10 }}>
                                <GroupButtonTitle>Signals view</GroupButtonTitle>
                                <Switch checked={signalView} onChange={toggleSignalSwitch} />
                            </Grid>
                        </Grid>
                        <Grid marginTop={"20px"}>
                            <LoadingComponent isLoading={isLoading}>
                                <ListComponent
                                    columns={getColumns}
                                    isDarkMode={true}
                                    compact={false}
                                    items={indexSignalRows}
                                    initialSortDirection={"DESC"}
                                    onRowClick={(rows, columnName) => {
                                        if (columnName !== "collectionName") {
                                            moveToCollectionId(rows.contractId);
                                        }
                                    }}
                                />
                            </LoadingComponent>
                        </Grid>
                    </Grid>
                </Grid>
            </DataGridContainer>
        </Grid>
    );
};

export default IndexPage;
