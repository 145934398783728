import React, { createContext, ReactNode, useState } from "react";
import { ThemeProviderType } from "./types";

export const ThemeContext = createContext<ThemeProviderType>({
    darkMode: false,
    setDarkMode: () => {
        return;
    }
});
type ThemeProviderProps = {
    children: ReactNode;
};
export const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const [darkMode, setDarkMode] = useState<boolean>(false);
    const changeFavIcon = (darkMode: boolean) => {
        const link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
        if (link) {
            link.href = darkMode ? "/darkFavIcon32.png" : "/favicon32.png";
        }
    };
    const context = {
        darkMode,
        setDarkMode: (value: boolean) => {
            changeFavIcon(value);
            setDarkMode(value);
        }
    };
    return <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>;
};
