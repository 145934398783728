import React, { useContext, useState, useEffect, useCallback } from "react";
import {
    CustomAppBar,
    CustomToolbar,
    HeaderContainer,
    HeaderMenuContainer,
    LogoImage,
    MenuLinks,
    BookIcon,
    HeaderButton
} from "./headerStyled";
import { Toolbar, useMediaQuery, useTheme, Menu, MenuItem } from "@mui/material";
import whitelogo from "../../public/images/logo_white.png";
import blackLogo from "../../public/images/logo_beta.png";
import { ThemeContext } from "../../context/ThemeProvider";
import MobileHeader from "./MobileHeader";
import { guideUrl } from "../../constants/urlConstants";
import { useHistory, useLocation } from "react-router";
import {
    huntersRoute,
    launchedNFTRoute,
    livemint,
    livesale,
    mintradar,
    walletfeed,
    alerts,
    livewhalebuysale,
    livewhaleduofeed,
    signal
} from "../../constants/routeConstants";
import { footerSocialMediaData, footerSocialMediaDataType } from "../../constants/menuData";
import abacusIcon from "../../images/abacus.png";
import whaleIcon from "../../images/whale.svg";
import BlinkDotIcon from "../../commoncomponents/blinkdot/BlinkDotIcon";
import Subscription from "../subscribe/Subscription";
import MainBanner from "./MainBanner";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { BUYSELL } from "../../constants/appConstants";
import { getIcon, iconMap } from "../../utils/appIcons";

const Header = (): JSX.Element => {
    const themeContext = useContext(ThemeContext);
    const { darkMode } = themeContext;
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const [tabLink, setTabLink] = useState("/");
    const history = useHistory();
    const [showBanner, setShowBanner] = useState(false);
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setTabLink(window.location.pathname);
    }, [location]);

    const onMenuLinkClick = (link: string) => {
        history.push(link);
    };

    const handleMenuClick = useCallback(
        (route: string) => {
            setAnchorEl(null);
            onMenuLinkClick(route);
        },
        [onMenuLinkClick]
    );

    return (
        <>
            <CustomAppBar darkmode={darkMode.toString()} position="fixed">
                {showBanner && <MainBanner onClose={() => setShowBanner(false)} />}
                <CustomToolbar>
                    <HeaderContainer>
                        <HeaderMenuContainer>
                            <LogoImage src={darkMode ? blackLogo : whitelogo} alt="logo" />
                            {mobileView && (
                                <HeaderMenuContainer>
                                    <Subscription />
                                </HeaderMenuContainer>
                            )}
                            {!mobileView && (
                                <>
                                    <HeaderButton
                                        variant="text"
                                        size={"small"}
                                        color={"primary"}
                                        onClick={handleClick}
                                        endIcon={<KeyboardArrowDownIcon />}
                                    >
                                        <img src={getIcon(iconMap.calendar)} height={20} width={20} style={{ marginRight: "5px" }} />
                                        Wen mint
                                    </HeaderButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            "aria-labelledby": "basic-button"
                                        }}
                                    >
                                        <MenuItem onClick={() => handleMenuClick("/")}>Wen mint</MenuItem>
                                        <MenuItem onClick={() => handleMenuClick(launchedNFTRoute)}>Minted</MenuItem>
                                        <MenuItem onClick={() => handleMenuClick(huntersRoute)}>Hunters</MenuItem>
                                    </Menu>
                                    {/* <MenuLinks
                                        selected={tabLink === calenderRoute}
                                        darkmode={darkMode.toString()}
                                        onClick={() => onMenuLinkClick("/")}>
                                        <img src={calendarLogo} height={20} width={20} />
                                        Wen mint
                                    </MenuLinks> */}
                                    <MenuLinks
                                        style={{ gap: 0 }}
                                        darkmode={darkMode.toString()}
                                        onClick={() => onMenuLinkClick(signal)}
                                        selected={tabLink === signal}
                                    >
                                        <span style={{ fontSize: 20 }}>🚦</span>Signals
                                    </MenuLinks>
                                    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                        <BlinkDotIcon />
                                        <MenuLinks
                                            selected={tabLink === livemint}
                                            darkmode={darkMode.toString()}
                                            onClick={() => onMenuLinkClick(livemint)}
                                        >
                                            Live Mint
                                        </MenuLinks>
                                    </div>
                                    <MenuLinks
                                        darkmode={darkMode.toString()}
                                        onClick={() => onMenuLinkClick(mintradar)}
                                        selected={tabLink === mintradar}
                                    >
                                        🎯Mint Radar
                                    </MenuLinks>
                                    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                        <BlinkDotIcon />
                                        <MenuLinks
                                            darkmode={darkMode.toString()}
                                            onClick={() => onMenuLinkClick(livesale)}
                                            selected={tabLink === livesale}
                                        >
                                            Live Sale
                                        </MenuLinks>
                                    </div>
                                    <MenuLinks
                                        darkmode={darkMode.toString()}
                                        onClick={() => onMenuLinkClick(`/livewhale/${BUYSELL}`)}
                                        selected={[livewhalebuysale, livewhaleduofeed].includes(tabLink)}
                                    >
                                        <img src={whaleIcon} height={25} width={25} />
                                        Whales
                                    </MenuLinks>

                                    {/* <MenuLinks
                                        selected={tabLink === launchedNFTRoute}
                                        darkmode={darkMode.toString()}
                                        onClick={() => onMenuLinkClick(launchedNFTRoute)}>
                                        <img src={mintLogo} height={20} width={20} />
                                        Minted
                                    </MenuLinks>
                                    <MenuLinks
                                        selected={tabLink === huntersRoute}
                                        darkmode={darkMode.toString()}
                                        onClick={() => onMenuLinkClick(huntersRoute)}>
                                        <img src={trophyIcon} height={20} width={20} />
                                        Hunters
                                    </MenuLinks> */}
                                    <MenuLinks
                                        darkmode={darkMode.toString()}
                                        onClick={() => onMenuLinkClick(walletfeed)}
                                        selected={tabLink.includes(walletfeed)}
                                    >
                                        👀 Wallet
                                    </MenuLinks>
                                    <MenuLinks
                                        darkmode={darkMode.toString()}
                                        onClick={() => onMenuLinkClick(alerts)}
                                        selected={tabLink.includes(alerts)}
                                    >
                                        🚨 Alerts
                                    </MenuLinks>
                                </>
                            )}
                        </HeaderMenuContainer>
                        {!mobileView && (
                            <HeaderMenuContainer>
                                <MenuLinks
                                    darkmode={darkMode.toString()}
                                    onClick={() => onMenuLinkClick("/scoreresults")}
                                >
                                    <img src={abacusIcon} height={20} width={20} />
                                    Score Results
                                </MenuLinks>
                                <a href={guideUrl} target="_blank">
                                    <MenuLinks darkmode={darkMode.toString()}>
                                        <BookIcon>📒</BookIcon>Guide
                                    </MenuLinks>
                                </a>
                                {/* <a href={nftCheatSheetUrl} target="_blank">
                                    <MenuLinks darkmode={darkMode.toString()}>NFT Cheat Sheet</MenuLinks>
                                </a> */}
                                {/* <a href={methodForScoring} target="_blank">
                                    <MenuLinks darkmode={darkMode.toString()}>How We Score</MenuLinks>
                                </a> */}
                                {/* <a href={submitProject} target="_blank">
                                    <MenuLinks darkmode={darkMode.toString()}>Submit Project</MenuLinks>
                                </a> */}
                                <HeaderMenuContainer>
                                    {footerSocialMediaData.map((data: footerSocialMediaDataType, index) => (
                                        <img
                                            style={{ cursor: "pointer" }}
                                            src={data.imageUrl}
                                            height={data.height}
                                            alt={data.alt}
                                            onClick={() => window.open(data.url, "_blank")}
                                            key={`${index}${data.url}`}
                                        />
                                    ))}
                                </HeaderMenuContainer>
                                <HeaderMenuContainer>
                                    <Subscription />
                                </HeaderMenuContainer>
                            </HeaderMenuContainer>
                        )}
                        {mobileView && (
                            <HeaderMenuContainer>
                                <MobileHeader />
                            </HeaderMenuContainer>
                        )}
                    </HeaderContainer>
                </CustomToolbar>
            </CustomAppBar>
            <Toolbar />
        </>
    );
};

export default Header;
