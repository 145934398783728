import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useState, FC, useEffect, useMemo } from "react";
import { SortDirection } from "react-data-grid";
import { DataGridWrapper } from "../components/neftdata/style";

export interface ListComponentProps {
    columns: any[];
    items: any[];
    isDarkMode: boolean;
    initialSortKey?: string;
    initialSortDirection?: SortDirection;
    compact: boolean;
    isPaddingRequired?: boolean;
    noDataFoundText?: string;
    onRowClick?: (row: any, columnName: string) => void;
}

const ListComponent: FC<ListComponentProps> = ({
    items,
    columns,
    isDarkMode,
    initialSortKey,
    initialSortDirection = "ASC",
    compact,
    isPaddingRequired = false,
    onRowClick,
    noDataFoundText
}) => {
    const initialSort: { columnKey: string; direction: SortDirection }[] = [
        { columnKey: initialSortKey || "", direction: initialSortDirection }
    ];
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const [sortColumns, setSortColumns] = useState(initialSort);
    const [rows, setRows] = useState(items);

    useEffect(() => {
        setRows(items);
        initialSortKey && onSortColumnsChange(initialSort);
    }, [items]);

    const onSortColumnsChange = (newSortColumns: { columnKey: string; direction: SortDirection }[]) => {
        if (newSortColumns.length === 0) {
            setSortColumns(initialSort);
        } else {
            setSortColumns(newSortColumns);
        }
        const comparer = (a: any, b: any): number => {
            try {
                if (newSortColumns[0].direction === "ASC") {
                    if (typeof a[newSortColumns[0].columnKey] === "number") {
                        return a[newSortColumns[0].columnKey] > b[newSortColumns[0].columnKey] ? 1 : -1;
                    }
                    return a[newSortColumns[0].columnKey].toString().split(" ").join("").toLowerCase() >
                        b[newSortColumns[0].columnKey].toString().split(" ").join("").toLowerCase()
                        ? 1
                        : -1;
                }
                if (newSortColumns[0].direction === "DESC") {
                    if (typeof a[newSortColumns[0].columnKey] === "number") {
                        return a[newSortColumns[0].columnKey] < b[newSortColumns[0].columnKey] ? 1 : -1;
                    }
                    return a[newSortColumns[0].columnKey].toString().split(" ").join("").toLowerCase() <
                        b[newSortColumns[0].columnKey].toString().split(" ").join("").toLowerCase()
                        ? 1
                        : -1;
                }
                return a[newSortColumns[0].columnKey].toString().split(" ").join("").toLowerCase() >
                    b[newSortColumns[0].columnKey].toString().split(" ").join("").toLowerCase()
                    ? 1
                    : -1;
            } catch (error) {
                return 0;
            }
        };
        setRows(newSortColumns.length === 0 ? items : [...items].sort(comparer));
    };

    const rowHeight = useMemo(() => {
        if (compact) {
            return mobileView ? 125 : 40;
        }
        return mobileView ? 125 : 65;
    }, [mobileView]);

    const getTableHeight = useMemo(() => {
        if (mobileView) {
            return rows.length * rowHeight + (rowHeight + 12);
        }
        return rows.length * rowHeight + (rowHeight + 2);
    }, [mobileView, rowHeight, rows.length]);

    return (
        <DataGridWrapper
            rowHeight={rowHeight}
            columns={columns}
            rows={rows}
            isPaddingRequired={isPaddingRequired ? 1 : 0}
            tableHeight={rows.length === 0 ? 300 : getTableHeight}
            noRowsFallback={
                <Grid container justifyContent={"center"} alignItems={"center"} style={{ height: "80%" }}>
                    {noDataFoundText || "No data found"}
                </Grid>
            }
            onRowClick={(e, row) => {
                onRowClick && onRowClick(e, row.key);
            }}
            sortColumns={sortColumns}
            onSortColumnsChange={onSortColumnsChange}
            isdarkmode={isDarkMode ? 1 : 0}
            className={!isDarkMode ? "rdg-light" : "rdg-dark"}
        />
    );
};

export default ListComponent;
