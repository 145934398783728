import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { FC, useContext } from "react";
import { Bar } from "react-chartjs-2";
import { gridStyleOptions } from "../../commoncomponents/CommonStyle";
import { GraphNeftDataType } from "./util";
import { getBarHeight } from "../../utils/bargraphUtils";
import ScoreChartUnPaid from "../../images/scoreschart_fade2.png";
import NonPaidUserWrapper from "../common/NonPaidUserWrapper";
import { AuthContext } from "../../context/AuthenticationProvider";

type ScoreChartViewProps = {
    nftLists: GraphNeftDataType;
};

const ScoreChartView: FC<ScoreChartViewProps> = ({ nftLists }) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const { isPaidUser } = useContext(AuthContext);

    if (nftLists.labels.length === 0 && isPaidUser) {
        return (
            <Typography variant={"h6"} align={"center"}>
                Nothing to show
            </Typography>
        );
    }

    return (
        <NonPaidUserWrapper imageSrc={ScoreChartUnPaid}>
            <Bar
                key={mobileView.toString()}
                data={nftLists}
                options={gridStyleOptions("cryptoscores.io: Score for next NFT Launches")}
                height={getBarHeight(nftLists.labels.length, mobileView)}
            />
        </NonPaidUserWrapper>
    );
};

export default ScoreChartView;
