import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HuntersView from "../components/hunters/HuntersView";
import LiveMintView from "../components/livemint/LiveMintView";
import MintedNftView from "../components/minted/MintedNftView";
import ScoreResultsView from "../components/scoreresults/ScoreResultsView";
import UpcomingNftView from "../components/upcomingneft/UpcomingNftView";
import WebsiteChartView from "../components/websitechart/WebsiteChartView";
import {
    calenderRoute,
    huntersRoute,
    launchedNFTRoute,
    livemint,
    livesale,
    livewhale,
    metamask,
    mintradar,
    scoreResultsRoute,
    walletfeed,
    websiteChartroute,
    alerts,
    collection,
    dashboardRoute,
    calendarBeta,
    calendarPagesRoute,
    signalRoute
} from "../constants/routeConstants";
import LiveWhaleView from "../components/livewhale/LiveWhaleView";
import LiveSaleView from "../components/livesale/LiveSaleView";
import MetaMaskExperiment from "../components/Login/MetaMaskExperiment";
import MintRadarView from "../components/mintradar/MintRadarView";
import WalletSearchFeedView from "../components/walletfeed/WalletSearchFeedView";
import WalletFeedDataGrid from "../components/walletfeed/WalletFeedDataGrid";
import AlertsView from "../components/alerts/AlertsView";
import CollectionView from "../components/collection/CollectionView";
import { CALENDAR } from "../constants/appConstants";
import { DashboardView } from "../components/dashboard/DashboardView";
import CalendarView from "../components/calendarbeta/CalendarView";
import CalendarPageView from "../components/calendarpage/CalendarPageView";
import IndexPage from "../components/indexpage/IndexPage";

const ApplicationRoutes = () => {
    return (
        <Switch>
            <Route exact path={"/"} render={() => <Redirect to={`calendar/${CALENDAR}`} />} />
            <Route exact path={dashboardRoute} component={DashboardView} />
            <Route exact path={calendarPagesRoute} component={CalendarPageView} />
            <Route exact path={calenderRoute} component={UpcomingNftView} />
            <Route exact path={websiteChartroute} component={WebsiteChartView} />
            <Route exact path={launchedNFTRoute} component={MintedNftView} />
            <Route exact path={huntersRoute} component={HuntersView} />
            <Route exact path={scoreResultsRoute} component={ScoreResultsView} />
            <Route exact path={livemint} component={LiveMintView} />
            <Route exact path={livewhale} component={LiveWhaleView} />
            <Route exact path={livesale} component={LiveSaleView} />
            <Route exact path={mintradar} component={MintRadarView} />
            <Route exact path={metamask} component={MetaMaskExperiment} />
            <Route exact path={walletfeed} component={WalletSearchFeedView} />
            <Route exact path={`${walletfeed}/:walletAddress`} component={WalletFeedDataGrid} />
            <Route exact path={alerts} component={AlertsView} />
            <Route exact path={`${collection}/:collectionId`} component={CollectionView} />
            <Route exact path={calendarBeta} component={CalendarView} />
            <Route exact path={signalRoute} component={IndexPage} />
        </Switch>
    );
};

export default ApplicationRoutes;
