import { Grid, Tooltip } from "@mui/material";
import { HeaderRendererProps, SortableHeaderCell } from "react-data-grid";
import HeaderTooltipView from "../../commoncomponents/HeaderTooltip";
import { pricesSignalColor } from "../../constants/ColorConstants";
import { renderValue } from "../common/grid/Utils";
import { ColumnHeader } from "../mintradar/Style";
import ProjectNameView from "../neftdata/ProjectNameView";
import { ColorText } from "./Style";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const renderPriceSignals = (value: number) => {
    if (value >= 1) {
        return pricesSignalColor.green;
    }
    if (value === 0) {
        return pricesSignalColor.yellow;
    }
    return pricesSignalColor.red;
};

const renderPriceSignalsIcon = (value: number) => {
    if (value >= 1) {
        return <ArrowDropUpIcon />;
    }
    if (value === 0) {
        return "";
    }
    return <ArrowDropDownIcon />;
};

export const renderIndexColumns = (isDarkMode: boolean, isMobileView: boolean) => [
    {
        key: "id",
        name: "Rank",
        width: 1,
        sortable: true,
        frozen: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <SortableHeaderCell {...props}>
                <ColumnHeader style={{ justifyContent: "center" }}>
                    <span style={{ fontSize: 10 }}>Rank</span>
                </ColumnHeader>
            </SortableHeaderCell>
        ),
        formatter: (rows: any) => <p style={{ textAlign: "center" }}>{renderValue(`${rows.row.id}`, isDarkMode)}</p>
    },
    {
        key: "collectionName",
        name: "Collection",
        frozen: true,
        width: isMobileView ? "50%" : "35%",
        formatter: (rows: any) => (
            <ProjectNameView
                openWebsiteOnClick
                isDarkMode={isDarkMode}
                projectName={rows.row.collectionName}
                socialLinks={rows.row.socialLinks}
                collectId={rows.row.contractId}
            />
        ),
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Collection"
                isMobileView={isMobileView}
                headerProps={props}
                hideTooltip
                isNotCenter
            />
        ),
        sortable: true,
        sortDescendingFirst: true
    },

    {
        key: "priceSignals",
        name: "Price Signals",
        sortable: true,
        sortDescendingFirst: true,
        width: "20%",
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="1d 3d 7d 30d"
                description="Green - Current Price has increased, compared to previous price from the indicated interval. Red - Current Price has decreased. Yellow - Current price is stable. 1d is 1 day ago, 3d is 3 days ago, and so on."
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => (
            <Grid height={"100%"} justifyContent={"center"} alignItems={"center"} container gap={"5px"}>
                {[
                    {
                        text: rows.row.medianSaleDel2d,
                        color: rows.row.medSaleScore2d
                    },
                    {
                        text: rows.row.medianSaleDel3d,
                        color: rows.row.medSaleScore3d
                    },
                    {
                        text: rows.row.medianSaleDel4d,
                        color: rows.row.medSaleScore4d
                    },
                    {
                        text: rows.row.medianSaleDel5d,
                        color: rows.row.medSaleScore5d
                    }
                ].map((item, index) => (
                    <Tooltip key={index} title={`${Math.round(item.text * 100)}%`}>
                        <Grid
                            item
                            bgcolor={renderPriceSignals(item.color)}
                            borderRadius={"50%"}
                            height={"20px"}
                            width={"20px"}
                        />
                    </Tooltip>
                ))}
            </Grid>
        )
    },
    {
        key: "medsaleSignalScore",
        name: "Score",
        width: "5%",
        sortable: true,
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Score"
                description="Sum of Signals (1 for Green, 0 for Yellow, -1 for Red)"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => (
            <ColorText color={renderPriceSignals(parseInt(rows.row.medsaleSignalScore))}>
                {renderValue(rows.row.medsaleSignalScore, isDarkMode)}
            </ColorText>
        )
    },
    {
        key: "floorsale1D",
        name: "Price",
        frozen: isMobileView ? false : true,
        width: "10%",
        sortable: true,
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Price"
                description="Floor Sales Price, with outliers removed"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => (
            <p>{renderValue(`${rows.row.floorsale1D}${rows.row.floorsale1D ? " Ξ" : ""}`, isDarkMode)}</p>
        )
    },
    {
        key: "numTrans1d",
        name: "Sales",
        width: "10%",
        sortable: true,
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Sales"
                description="Sales in the last 1 day"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => <p>{renderValue(rows.row.numTrans1d, isDarkMode)}</p>
    },
    {
        key: "totVol1d",
        name: "Volume",
        sortable: true,
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Volume"
                description="Volume traded in the last 1 day"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => (
            <p>{renderValue(`${rows.row.totVol1d}${rows.row.totVol1d ? " Ξ" : ""}`, isDarkMode)}</p>
        )
    }
];

export const renderNonSignalIndexColumns = (isDarkMode: boolean, isMobileView: boolean) => [
    {
        key: "id",
        name: "Rank",
        width: "1%",
        sortable: true,
        frozen: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <SortableHeaderCell {...props}>
                <ColumnHeader style={{ justifyContent: "center" }}>
                    <span style={{ fontSize: 10 }}>Rank</span>
                </ColumnHeader>
            </SortableHeaderCell>
        ),
        formatter: (rows: any) => <p style={{ textAlign: "center" }}>{renderValue(`${rows.row.id}`, isDarkMode)}</p>
    },
    {
        key: "collectionName",
        name: "Collection",
        frozen: true,
        width: isMobileView ? "50%" : "30%",
        formatter: (rows: any) => (
            <ProjectNameView
                openWebsiteOnClick
                isDarkMode={isDarkMode}
                projectName={rows.row.collectionName}
                socialLinks={rows.row.socialLinks}
                collectId={rows.row.contractId}
            />
        ),
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Collection"
                isMobileView={isMobileView}
                headerProps={props}
                hideTooltip
                isNotCenter
            />
        ),
        sortable: true,
        sortDescendingFirst: true
    },
    {
        key: "floorsale1D",
        name: "Price",
        frozen: isMobileView ? false : true,
        width: "10%",
        sortable: true,
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Price"
                description="Floor Sales Price, with outliers removed"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => (
            <p>{renderValue(`${rows.row.floorsale1D}${rows.row.floorsale1D ? " Ξ" : ""}`, isDarkMode)}</p>
        )
    },
    {
        key: "medianSaleDel2d",
        name: "1D",
        sortable: true,
        sortDescendingFirst: true,
        width: "5%",
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="1D"
                description="% Change in Price compared to 1 day ago"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => (
            <ColorText color={renderPriceSignals(rows.row.medSaleScore2d)}>
                {renderPriceSignalsIcon(Math.round(rows.row.medianSaleDel2d * 100))}
                {renderValue(`${Math.round(rows.row.medianSaleDel2d * 100)}%`, isDarkMode)}
            </ColorText>
        )
    },
    {
        key: "medianSaleDel3d",
        name: "3D",
        sortable: true,
        sortDescendingFirst: true,
        width: "5%",
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="3D"
                description="% Change in Price compared to 3 days ago"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => (
            <ColorText color={renderPriceSignals(rows.row.medSaleScore3d)}>
                {renderPriceSignalsIcon(Math.round(rows.row.medianSaleDel3d * 100))}
                {renderValue(`${Math.round(rows.row.medianSaleDel3d * 100)}%`, isDarkMode)}
            </ColorText>
        )
    },
    {
        key: "medianSaleDel4d",
        name: "7D",
        sortable: true,
        sortDescendingFirst: true,
        width: "5%",
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="7D"
                description="% Change in Price compared to 7 days ago"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => (
            <ColorText color={renderPriceSignals(rows.row.medSaleScore4d)}>
                {renderPriceSignalsIcon(Math.round(rows.row.medianSaleDel4d * 100))}{" "}
                {renderValue(`${Math.round(rows.row.medianSaleDel4d * 100)}%`, isDarkMode)}
            </ColorText>
        )
    },
    {
        key: "medianSaleDel5d",
        name: "30D",
        sortable: true,
        sortDescendingFirst: true,
        width: "5%",
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="30D"
                description="% Change in Price compared to 30 days ago"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => (
            <ColorText color={renderPriceSignals(rows.row.medSaleScore5d)}>
                {renderPriceSignalsIcon(Math.round(rows.row.medianSaleDel5d * 100))}
                {renderValue(`${Math.round(rows.row.medianSaleDel5d * 100)}%`, isDarkMode)}
            </ColorText>
        )
    },
    {
        key: "medsaleSignalScore",
        name: "Score",
        width: "5%",
        sortable: true,
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Score"
                description="Sum of Signals (1 for Green, 0 for Yellow, -1 for Red)"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => (
            <ColorText color={renderPriceSignals(parseInt(rows.row.medsaleSignalScore))}>
                {renderValue(rows.row.medsaleSignalScore, isDarkMode)}
            </ColorText>
        )
    },

    {
        key: "numTrans1d",
        name: "Sales",
        width: "5%",
        sortable: true,
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Sales"
                description="Sales in the last 1 day"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => <p>{renderValue(rows.row.numTrans1d, isDarkMode)}</p>
    },
    {
        key: "totVol1d",
        name: "Volume",
        sortable: true,
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Volume"
                description="Volume traded in the last 1 day"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => (
            <p>{renderValue(`${rows.row.totVol1d}${rows.row.totVol1d ? " Ξ" : ""}`, isDarkMode)}</p>
        )
    }
];
