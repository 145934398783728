import { format, sub } from "date-fns";

export const getUtcDate = (date: string) => new Date(date);
export const getUtcTime = (time: string) => new Date(time);
export const getUtcDateTime = (time: string) => new Date(time);

export const compareTimeRange = (date: string, hours: number) => {
    const nftDateTime = new Date(date.concat("Z"));
    const maxDateTime = new Date(new Date().setHours(new Date().getHours() + hours));
    return nftDateTime >= new Date() && nftDateTime <= maxDateTime;
};

export const compareTimeRangeInHours = (date: string, hours: number) => {
    const nftDateTime = new Date(date.concat("Z"));
    const maxDateTime = new Date(new Date().setHours(new Date().getHours() + hours));
    return nftDateTime >= new Date() && nftDateTime <= maxDateTime;
};

export const compareAddedDate = (date: string) => {
    const nftDateTime = new Date(date.concat("Z"));
    const filterDateTime = sub(new Date(), { hours: 36 });
    return nftDateTime >= filterDateTime;
};

export const comparePresaleLauchDateTimeRange = (preSaledate: string, lauchDate: string, days: number) => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + days);
    const preSaleDateTime = new Date(preSaledate.concat("Z"));
    const lauchSaleDateTime = new Date(lauchDate.concat("Z"));
    return preSaleDateTime > maxDate || lauchSaleDateTime > maxDate;
};

export const dateConverts = (dateTime: string): string => {
    const newDateTime = new Date(dateTime + "Z");
    return format(newDateTime, "MMM dd, H:mm") || "";
};

export const timeConverts = (dateTime: string): string => {
    const newDateTime = new Date(dateTime + "Z");
    return format(newDateTime, "H:mm aaa") || "";
};
