import { Grid, Paper, Typography } from "@mui/material";
import { SummaryContainer } from "../collection/Styled";
import { CalendarPageListType } from "./Type";
import { FC, useContext } from "react";
import { formatNumber } from "../../utils/siteUtil";
import { AuthContext } from "../../context/AuthenticationProvider";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { walletConnect } from "../../utils/connector";
import { LOCKED } from "../../constants/appConstants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { HeaderTooltip } from "../mintradar/Style";

type CalendarInfoProps = {
    collectionDescription: CalendarPageListType;
};

const CalendarInfo: FC<CalendarInfoProps> = ({ collectionDescription }) => {
    const { isPaidUser } = useContext(AuthContext);

    return (
        <Grid container height={"100%"} justifyContent="center">
            <Grid item style={{ borderRadius: 5 }} xs={12}>
                <Paper style={{ padding: 10, height: "100%" }} elevation={2}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography fontSize={14} display={"flex"} alignItems={"center"} style={{ color: "grey" }}>
                                Score
                                <HeaderTooltip title={"A score based on quantitative inputs. Please read our Guide"}>
                                    <InfoOutlinedIcon style={{ width: 14 }} />
                                </HeaderTooltip>
                            </Typography>
                            <SummaryContainer>
                                {isPaidUser ? (
                                    <Typography variant={"body1"} style={{ fontSize: 18 }}>
                                        {collectionDescription.score && collectionDescription.score !== LOCKED
                                            ? formatNumber(parseInt(collectionDescription.score))
                                            : "-"}
                                    </Typography>
                                ) : (
                                    <LockOutlinedIcon
                                        onClick={() => walletConnect()}
                                        style={{ color: "#fff", cursor: "pointer" }}
                                    />
                                )}
                            </SummaryContainer>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontSize={14} display={"flex"} alignItems={"center"} style={{ color: "grey" }}>
                                Sell Out %
                                <HeaderTooltip
                                    title={"Our beta AI model estimating the probability to sell out at launch."}
                                >
                                    <InfoOutlinedIcon style={{ width: 14 }} />
                                </HeaderTooltip>
                            </Typography>
                            <SummaryContainer>
                                <Typography
                                    display={"flex"}
                                    alignItems={"center"}
                                    variant={"body1"}
                                    style={{ fontSize: 18 }}
                                >
                                    {isPaidUser ? (
                                        <Typography variant={"body1"} style={{ fontSize: 18 }}>
                                            {collectionDescription.selloutChance &&
                                            collectionDescription.selloutChance !== LOCKED
                                                ? `${
                                                      parseFloat(collectionDescription.selloutChance) > 0
                                                          ? `${Math.round(
                                                                parseFloat(collectionDescription.selloutChance) * 100
                                                            )}%`
                                                          : 0
                                                  }`
                                                : "-"}
                                        </Typography>
                                    ) : (
                                        <LockOutlinedIcon
                                            onClick={() => walletConnect()}
                                            style={{ color: "#fff", cursor: "pointer" }}
                                        />
                                    )}
                                </Typography>
                            </SummaryContainer>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 10 }}>
                        <Grid item xs={6}>
                            <Typography fontSize={14} display={"flex"} alignItems={"center"} style={{ color: "grey" }}>
                                Audience
                                <HeaderTooltip
                                    title={
                                        "Audience Score is a community score we give from 0-100 of the 'active' audience in the project."
                                    }
                                >
                                    <InfoOutlinedIcon style={{ width: 14 }} />
                                </HeaderTooltip>
                            </Typography>
                            <SummaryContainer>
                                <Typography
                                    display={"flex"}
                                    alignItems={"center"}
                                    variant={"body1"}
                                    style={{ fontSize: 18 }}
                                >
                                    {isPaidUser ? (
                                        <Typography variant={"body1"} style={{ fontSize: 18 }}>
                                            {collectionDescription.activeScore &&
                                            collectionDescription.activeScore !== LOCKED
                                                ? `${formatNumber(parseInt(collectionDescription.activeScore))}`
                                                : "-"}
                                        </Typography>
                                    ) : (
                                        <LockOutlinedIcon
                                            onClick={() => walletConnect()}
                                            style={{ color: "#fff", cursor: "pointer" }}
                                        />
                                    )}
                                </Typography>
                            </SummaryContainer>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontSize={14} display={"flex"} alignItems={"center"} style={{ color: "grey" }}>
                                Glue
                                <HeaderTooltip
                                    title={
                                        "GLUE is another community score we give from 0-100 on how 'engaged' the audience is, relative to the size of the audience. Projects with high engagement will get a high score, and vice versa."
                                    }
                                >
                                    <InfoOutlinedIcon style={{ width: 14 }} />
                                </HeaderTooltip>
                            </Typography>
                            <SummaryContainer>
                                <Typography
                                    display={"flex"}
                                    alignItems={"center"}
                                    variant={"body1"}
                                    style={{ fontSize: 18 }}
                                >
                                    {isPaidUser ? (
                                        <Typography variant={"body1"} style={{ fontSize: 18 }}>
                                            {collectionDescription.engagementScore &&
                                            collectionDescription.engagementScore !== LOCKED
                                                ? `${formatNumber(parseInt(collectionDescription.engagementScore))}`
                                                : "-"}
                                        </Typography>
                                    ) : (
                                        <LockOutlinedIcon
                                            onClick={() => walletConnect()}
                                            style={{ color: "#fff", cursor: "pointer" }}
                                        />
                                    )}
                                </Typography>
                            </SummaryContainer>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CalendarInfo;
