import { Grid, TextField, useMediaQuery, useTheme } from "@mui/material";
import React, { FC, useContext } from "react";
import { nftFilterData } from "../../constants/appConstants";
import { AuthContext } from "../../context/AuthenticationProvider";
import { walletConnect } from "../../utils/connector";
import { FilterButton } from "../neftdata/style";

type UpcomingNftFilterProps = {
    searchText: string;
    setSearchText: (text: string) => void;
    selectedFilter: string[];
    onFilterClick: (filter: string) => void;
    isFilterDisabled: boolean;
};

const UpcomingNftFilter: FC<UpcomingNftFilterProps> = ({
    searchText,
    setSearchText,
    selectedFilter,
    onFilterClick,
    isFilterDisabled
}) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const { isPaidUser } = useContext(AuthContext);

    return (
        <Grid
            container
            gap={2}
            justifyContent={"flex-start"}
            alignItems={"center"}
            style={{ margin: "20px 0px", pointerEvents: isFilterDisabled ? "none" : "all" }}>
            <Grid item>
                <TextField
                    variant={"outlined"}
                    placeholder={"Search Collection"}
                    size={"small"}
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    fullWidth={mobileView}
                />
            </Grid>
            {nftFilterData.map((filter) => (
                <Grid item key={filter.value}>
                    <FilterButton
                        isdisabled={!isPaidUser && filter.isPaidDisabled ? 1 : 0}
                        selected={selectedFilter.includes(filter.value) ? 1 : 0}
                        onClick={() => {
                            !isPaidUser && filter.isPaidDisabled ? walletConnect() : onFilterClick(filter.value);
                        }}>
                        {filter.label}
                    </FilterButton>
                </Grid>
            ))}
        </Grid>
    );
};

export default UpcomingNftFilter;
