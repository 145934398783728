import { Client } from "../../commoncomponents/Base/api";
import { getCalendarListApiUrl, getWhaleApiUrl } from "../../constants/apiConstants";
import { CalendarPageListType, CalendarWhaleListType } from "./Type";

const tranformData = (data: any): CalendarPageListType[] => {
    if (!data) {
        return [];
    }

    return data.map((item: any) => ({
        activeScore: item.activeScore || "",
        addedDate: item.addedDate || "",
        blockchain: item.blockchain || "",
        crawlTs: item.crawlTs || "",
        discordActiveFollowers: item.discordActiveFollowers || "",
        discordMembers: item.discordMembers || "",
        engagementScore: item.engagementScore || "",
        launchConfirmed: item.launchConfirmed || "",
        launchDate: item.launchDate || "",
        mintCurrency: item.mintCurrency || "",
        mintPriceCurr: item.mintPriceCurr || "",
        mintPriceEth: item.mintPriceEth || "",
        mintSupply: item.mintSupply || "",
        nftId: item.nftId || "",
        nftName: item.nftName || "",
        poolScore: item.poolScore || "",
        referred: item.referred || "",
        calendarLink: item.calendarLink || "",
        score: item.score || "",
        team1: item.team1 || "",
        team2: item.team2 || "",
        team3: item.team3 || "",
        team1Role: item.team1Role || "",
        team2Role: item.team2Role || "",
        team3Role: item.team3Role || "",
        selloutChance: item.selloutChance || "",
        selloutMins: item.selloutMins || "",
        selloutMinsRange: item.selloutMinsRange || "",
        showInWebsite: item.showInWebsite || "",
        status: item.status || "",
        twitterAvgLikes: item.twitterAvgLikes || "",
        twitterFollowers: item.twitterFollowers || "",
        whaleFollows: item.whaleFollows || "",
        hunter: item.referringUser || "",
        thumbnailUrl: item.thumbnailUrl || "",
        socialLinks: {
            websiteUrl: item.websiteUrl || "",
            discordUrl: item.discordUrl || "",
            twitterUrl: item.twitterUrl || "",
            imageUrl: item.imageUrl || ""
        }
    }));
};

const transformCalendarWhale = (data: any): CalendarWhaleListType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        id: item.id || "",
        bftId: item.nftId || "",
        whaleTwitterName: item.whaleTwitterName || "",
        whaleTwitterUrl: item.whaleTwitterUrl || "",
        whaleCsWallet: item.whaleCsWallet || "",
        whaleLooksAffUrl: item.whaleLooksAffUrl || "",
        whaleOsAffUrl: item.whaleOsAffUrl || "",
        whaleTradeName: item.whaleTradeName || "",
        thumbnailurl: item.thumbnailUrl || "",
        tweetToWhaleLink: item.tweetToWhaleLink || ""
    }));
};

export const embedLink = (className: string) => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName(className)[0].appendChild(script);
};

export const getCalendarCollectionList = (collectionId: string) =>
    Client.getInstance()
        .getData(getCalendarListApiUrl(collectionId))
        .then((res) => Promise.resolve(tranformData(res.data)))
        .catch((err) => Promise.reject(err));

export const getCalendarWhaleList = (collectionId: string): Promise<CalendarWhaleListType[]> =>
    Client.getInstance()
        .getData(getWhaleApiUrl(collectionId))
        .then((res) => Promise.resolve(transformCalendarWhale(res.data)))
        .catch((err) => Promise.reject(err));
