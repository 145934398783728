import { useMemo } from "react";
import {
    range010,
    range1020,
    range2030,
    range3040,
    range4050,
    range5060,
    range6070,
    range7080,
    range8090,
    range90100
} from "../../constants/ColorConstants";

export const getRowHeight = (mobileView: boolean) => (mobileView ? 125 : 65);

export const getTableHeight = (rowCount: number) => rowCount * 65 + 65;

const renderPercentage = (value: number) => (
    <div style={{ backgroundColor: getBackgroundColorByPercenetage(value) }}>{value}%</div>
);

const colorMap = [
    {
        minValue: 0,
        maxValue: 10,
        color: range010
    },
    {
        minValue: 10,
        maxValue: 20,
        color: range1020
    },
    {
        minValue: 20,
        maxValue: 30,
        color: range2030
    },
    {
        minValue: 30,
        maxValue: 40,
        color: range3040
    },
    {
        minValue: 40,
        maxValue: 50,
        color: range4050
    },
    {
        minValue: 50,
        maxValue: 60,
        color: range5060
    },
    {
        minValue: 60,
        maxValue: 70,
        color: range6070
    },
    {
        minValue: 70,
        maxValue: 80,
        color: range7080
    },
    {
        minValue: 80,
        maxValue: 90,
        color: range8090
    },
    {
        minValue: 90,
        maxValue: 101,
        color: range90100
    }
];

const getBackgroundColorByPercenetage = (value: number) =>
    colorMap.find(({ minValue, maxValue }) => value >= minValue && value < maxValue)?.color || "";

export const getColumns = (mobileView: boolean) =>
    useMemo(
        () => [
            {
                key: "groupType",
                name: mobileView ? "Score" : "Score, Groups",
                sortable: true,
                width: "15%"
            },
            {
                key: "soldoutPerc",
                name: mobileView ? "SO %" : "SOLD OUT, % of NFTs",
                sortable: true,
                formatter: (rows: any) => renderPercentage(Math.round(rows.row.soldoutPerc * 100))
            },
            {
                key: "soldoutN",
                name: mobileView ? "SO #" : "SOLD OUT, Count of NFTs",
                sortable: true,
                width: "20%"
            },
            {
                key: "notsoldoutPerc",
                name: mobileView ? "NOT SO %" : "NOT SOLD OUT % of NFTs",
                sortable: true,
                formatter: (rows: any) => <>{Math.round(rows.row.notsoldoutPerc * 100)}</>,
                width: "20%"
            },
            {
                key: "notsoldoutN",
                name: mobileView ? "NOT SO #" : "NOT SOLD OUT, Count of NFTS",
                sortable: true,
                width: "20%"
            }
        ],
        [mobileView]
    );
