import { useTheme, useMediaQuery, Grid } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ListComponent from "../../commoncomponents/ListComponent";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { DataGridContainer } from "../neftdata/style";
import { getColumns } from "./hooks";
import { Hunter } from "./types";
import { getHuntersData } from "./utils";

const HuntersView: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [gridData, setGridData] = useState<Hunter[]>([]);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));

    useEffect(() => {
        setIsLoading(true);
        getHuntersData().then((data) => {
            setGridData(data);
            setIsLoading(false);
        });
    }, []);

    return (
        <DataGridContainer>
            <LoadingComponent isLoading={isLoading}>
                <Grid container justifyContent={"center"}>
                    <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                        <ListComponent
                            columns={getColumns(true, mobileView)}
                            isDarkMode={true}
                            items={gridData}
                            initialSortKey={"nftsHunted"}
                            initialSortDirection={"DESC"}
                            compact={true}
                            isPaddingRequired
                        />
                    </Grid>
                </Grid>
            </LoadingComponent>
        </DataGridContainer>
    );
};

export default HuntersView;
