import { useMediaQuery, useTheme } from "@mui/material";
import { FC, useMemo } from "react";
import { nftCurrencies } from "../../constants/appConstants";
import { getIcon, iconMap } from "../../utils/appIcons";
import {
    DataColumnTitle,
    TableIconsContainer,
    TableDataContainer,
    IconContainer,
    SocialIconContainer,
    MobileCollectionGrid
} from "./style";
import { socialLinksType } from "./type";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import { bannerBackgroundColor } from "../../constants/ColorConstants";
import broken from "../../images/broken.jpg";
import { moveToCollectionId } from "../../utils/siteUtil";

type ProjectNameViewProps = {
    socialLinks: socialLinksType;
    projectName: string;
    notes?: string;
    mintCurrency?: string;
    isDarkMode?: boolean;
    openWebsiteOnClick: boolean;
    collectId?: string;
    openColumnClick?: () => void;
};

const ProjectNameView: FC<ProjectNameViewProps> = ({
    socialLinks,
    projectName,
    isDarkMode,
    notes,
    mintCurrency,
    openWebsiteOnClick,
    collectId,
    openColumnClick
}): JSX.Element => {
    const theme = useTheme();
    const { twitterUrl, discordUrl, websiteUrl, imageUrl, marketAffUrl, openseaSlug, etherscanUrl } = socialLinks;
    const mobileView = useMediaQuery(theme.breakpoints.down(800));

    const renderProjectName = useMemo(() => {
        const projectNameTitle = (
            <DataColumnTitle isdarkmode={isDarkMode ? 1 : 0}>
                {projectName}
                {notes && (
                    <IconContainer>
                        <img src={getIcon(iconMap.alarm)} title={notes} height={15} width={15} alt="alarmIcon" />
                    </IconContainer>
                )}
            </DataColumnTitle>
        );
        if (collectId) {
            return (
                <span
                    onClick={(event) => {
                        moveToCollectionId(collectId);
                        event.stopPropagation();
                    }}
                    style={{ cursor: "pointer" }}
                >
                    {projectNameTitle}
                </span>
            );
        }
        if (openWebsiteOnClick && marketAffUrl) {
            return (
                <a href={marketAffUrl} target={"_blank"}>
                    {projectNameTitle}
                </a>
            );
        }
        return projectNameTitle;
    }, [projectName, openWebsiteOnClick]);

    return (
        <TableDataContainer
            onClick={(event) => {
                openColumnClick && openColumnClick();
                collectId && moveToCollectionId(collectId);
                event.stopPropagation();
            }}
            ismobileview={mobileView}
        >
            {!mobileView ? (
                collectId ? (
                    <div style={{ marginTop: 2, cursor: "pointer" }}>
                        <img
                            onError={(event: any) => {
                                event.target.src = broken;
                            }}
                            style={{ borderRadius: 24 }}
                            src={imageUrl ? imageUrl : broken}
                            height={40}
                            width={40}
                        />
                    </div>
                ) : (
                    (websiteUrl || imageUrl) && (
                        <div style={{ marginTop: 2 }}>
                            <a href={websiteUrl} target={"_blank"}>
                                <img
                                    onError={(event: any) => {
                                        event.target.src = broken;
                                    }}
                                    style={{ borderRadius: 24 }}
                                    src={imageUrl ? imageUrl : broken}
                                    height={40}
                                    width={40}
                                />
                            </a>
                        </div>
                    )
                )
            ) : (
                <>
                    <MobileCollectionGrid>
                        <img
                            onError={(event: any) => {
                                event.target.src = broken;
                            }}
                            style={{ borderRadius: 24 }}
                            src={imageUrl ? imageUrl : broken}
                            height={30}
                            width={30}
                        />
                        <DataColumnTitle
                            onClick={(event) => {
                                openColumnClick && openColumnClick();
                                collectId && moveToCollectionId(collectId);
                                event.stopPropagation();
                            }}
                            isdarkmode={isDarkMode ? 1 : 0}
                        >
                            {projectName}
                        </DataColumnTitle>
                    </MobileCollectionGrid>
                </>
            )}
            <div
                onClick={(event) => {
                    openColumnClick && openColumnClick();
                    collectId && moveToCollectionId(collectId);
                    event.stopPropagation();
                }}
                style={{ marginLeft: websiteUrl || imageUrl || collectId ? 0 : !mobileView ? 60 : 0 }}
            >
                {!mobileView && renderProjectName}
                <TableIconsContainer>
                    {websiteUrl && (
                        <SocialIconContainer>
                            <a
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                href={websiteUrl}
                                target={"_blank"}
                            >
                                <WebAssetIcon style={{ fontSize: 20, color: bannerBackgroundColor }} />
                            </a>
                        </SocialIconContainer>
                    )}
                    {twitterUrl && (
                        <SocialIconContainer>
                            <a
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                href={twitterUrl}
                                target={"_blank"}
                            >
                                <img src={getIcon(iconMap.twitter)} height={20} width={20} />
                            </a>
                        </SocialIconContainer>
                    )}
                    {discordUrl && (
                        <SocialIconContainer>
                            <a
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                href={discordUrl}
                                target={"_blank"}
                            >
                                <img src={getIcon(iconMap.discord)} height={21} width={21} />
                            </a>
                        </SocialIconContainer>
                    )}
                    {etherscanUrl && (
                        <SocialIconContainer>
                            <a
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                href={etherscanUrl}
                                target={"_blank"}
                            >
                                <img src={getIcon(iconMap.etherscan)} height={20} width={20} />
                            </a>
                        </SocialIconContainer>
                    )}
                    {openseaSlug && marketAffUrl && (
                        <SocialIconContainer>
                            <a
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                href={marketAffUrl}
                                target={"_blank"}
                            >
                                <img src={getIcon(iconMap.opensea)} height={20} width={20} />
                            </a>
                        </SocialIconContainer>
                    )}
                    {mintCurrency === nftCurrencies.eth && marketAffUrl && (
                        <IconContainer>
                            <a
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                href={marketAffUrl}
                                target={"_blank"}
                            >
                                <img src={getIcon(iconMap.opensea)} height={15} width={15} />
                            </a>
                        </IconContainer>
                    )}
                    {mintCurrency === nftCurrencies.sol && marketAffUrl && (
                        <IconContainer>
                            <a
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                href={marketAffUrl}
                                target={"_blank"}
                            >
                                <img src={getIcon(iconMap.solana)} height={15} width={15} />
                            </a>
                        </IconContainer>
                    )}
                    {mobileView && notes && (
                        <IconContainer>
                            <img src={getIcon(iconMap.alarm)} title={notes} height={15} width={15} alt="alarmIcon" />
                        </IconContainer>
                    )}
                </TableIconsContainer>
            </div>
        </TableDataContainer>
    );
};

export default ProjectNameView;
