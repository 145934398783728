import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { CALENDAR, nftFilters, SCORE } from "../../constants/appConstants";
import { DataGridContainer, FilterButton } from "../neftdata/style";
import {
    getNeftUpcomingData,
    onFilter,
    includeInFilteredItems,
    sortListByKey,
    filterGridByRange,
    filterCalendarGridByRange
} from "../neftdata/util";
import { neftDataGridType } from "../neftdata/type";
import ScoreChartView from "../scorechart/ScoreChartView";
import { tranformNeftDataList } from "../scorechart/util";
import SelloutChartView from "../selloutchart/SelloutChartView";
import { tranformSellOutNeftDataList } from "../selloutchart/util";
import UpcomingNftFilter from "./UpcomingNftFilter";
import UpcomingNftTabs from "./UpcomingNftTabs";
import { AuthContext } from "../../context/AuthenticationProvider";
import { submitProject } from "../../constants/urlConstants";
import { clickHereTextColor, messageTextColor } from "../../constants/ColorConstants";
import { useHistory, useParams } from "react-router";
import AdvanceFilter from "./AdvanceFilter";
import { AdvanceFilterType } from "./Type";
import CalendarView from "../calendarbeta/CalendarView";
import CalendarAdvanceFilter from "../calendarbeta/CalenderAdvanceFilter";
import { CalendarAdvanceFilterType } from "../calendarbeta/Type";
import CalendarFilter from "../calendarbeta/CalendarFilter";

const UpcomingNftView = () => {
    const params: any = useParams();
    const [selectedTab, setSelectedTab] = useState<string>(params.tab);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
    const [searchItem, setSearchItem] = useState("");
    const [rows, setRows] = useState<neftDataGridType[]>([]);
    const [filteredRows, setFilteredRows] = useState<neftDataGridType[]>([]);
    const [filterSelectedKey, setFilterSelctedKey] = useState(Math.random());
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const { isPaidUser } = useContext(AuthContext);
    const history = useHistory();
    const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
    const [advanceFilterRanges, setAdvanceFilterRanges] = useState<AdvanceFilterType>({
        mintPriceMinMax: [0, 0],
        supplyPriceMinMax: [0, 0]
    });
    const [calendarAdvanceFilterRanges, setCalendarAdvanceFilterRanges] = useState<CalendarAdvanceFilterType>({
        twitterMinMax: [0, 0],
        discordMinMax: [0, 0],
        mintPriceMinMax: [0, 0],
        supplyPriceMinMax: [0, 0]
    });

    useEffect(() => {
        setSelectedTab(params.tab);
    }, [params]);

    useEffect(() => {
        setIsLoading(true);
        getNeftUpcomingData(true).then((res) => {
            setRows(res);
            setFilteredRows(res);
            setAdvanceFilterRanges({
                supplyPriceMinMax: sortListByKey("mintSupply", res),
                mintPriceMinMax: sortListByKey("mintPrice", res)
            });
            setCalendarAdvanceFilterRanges({
                twitterMinMax: sortListByKey("twitterFollowers", res),
                discordMinMax: sortListByKey("discordMembers", res),
                supplyPriceMinMax: sortListByKey("mintSupply", res),
                mintPriceMinMax: sortListByKey("mintPrice", res)
            });
            setIsLoading(false);
        });
    }, [isPaidUser]);

    const getFilteredItems = (): any =>
        filteredRows.filter(
            (item: neftDataGridType) => searchItem === "" || includeInFilteredItems(searchItem.toLowerCase(), item)
        );

    const onFilterClick = (value: string) => {
        let filteredValues = [];
        setFilterSelctedKey(Math.random());
        if (
            value === nftFilters.other_chains &&
            (selectedFilter.includes(nftFilters.only_eth) || selectedFilter.includes(nftFilters.only_sol))
        ) {
            filteredValues = selectedFilter.filter(
                (item) => item !== nftFilters.only_eth && item !== nftFilters.only_sol
            );

            setSelectedFilter([...filteredValues, value]);
            setFilteredRows(onFilter([...filteredValues, value], rows));
            return;
        }
        if (
            value === nftFilters.only_eth &&
            (selectedFilter.includes(nftFilters.only_sol) || selectedFilter.includes(nftFilters.other_chains))
        ) {
            filteredValues = selectedFilter.filter(
                (item) => item !== nftFilters.only_sol && item !== nftFilters.other_chains
            );
            setSelectedFilter([...filteredValues, value]);
            setFilteredRows(onFilter([...filteredValues, value], rows));
            return;
        }

        if (
            value === nftFilters.only_sol &&
            (selectedFilter.includes(nftFilters.only_eth) || selectedFilter.includes(nftFilters.other_chains))
        ) {
            filteredValues = selectedFilter.filter(
                (item) => item !== nftFilters.only_eth && item !== nftFilters.other_chains
            );
            setSelectedFilter([...filteredValues, value]);
            setFilteredRows(onFilter([...filteredValues, value], rows));
            return;
        }

        if (selectedFilter.includes(value)) {
            filteredValues = selectedFilter.filter((item) => item !== value);
            setSelectedFilter(filteredValues);
            setFilteredRows(onFilter(filteredValues, rows));
            return;
        }
        setSelectedFilter((prev) => [...prev, value]);
        setFilteredRows(onFilter([...selectedFilter, value], rows));
    };

    const filterByRange = (value: AdvanceFilterType) => {
        setFilterSelctedKey(Math.random());
        setFilteredRows(filterGridByRange(value, selectedFilter, rows));
    };

    const filterByCalendar = (value: CalendarAdvanceFilterType) => {
        setFilterSelctedKey(Math.random());
        setFilteredRows(filterCalendarGridByRange(value, selectedFilter, rows));
    };

    const renderSelectedView = () => {
        if (selectedTab === CALENDAR) {
            return <CalendarView nftLists={getFilteredItems()} />;
        }

        if (selectedTab === SCORE) {
            return (
                <ScoreChartView
                    key={filterSelectedKey.toString()}
                    nftLists={tranformNeftDataList(getFilteredItems())}
                />
            );
        }
        return (
            <SelloutChartView
                key={filterSelectedKey.toString()}
                nftLists={tranformSellOutNeftDataList(getFilteredItems())}
            />
        );
    };

    const renderAdvanceFilter = () => {
        if (selectedTab === CALENDAR) {
            return (
                <CalendarAdvanceFilter
                    applyFilter={filterByCalendar}
                    mintPriceMinMax={calendarAdvanceFilterRanges.mintPriceMinMax}
                    supplyPriceMinMax={calendarAdvanceFilterRanges.supplyPriceMinMax}
                    twitterMinMax={calendarAdvanceFilterRanges.twitterMinMax}
                    discordMinMax={calendarAdvanceFilterRanges.discordMinMax}
                />
            );
        }
        return (
            <AdvanceFilter
                applyFilter={filterByRange}
                mintPriceMinMax={advanceFilterRanges.mintPriceMinMax}
                supplyPriceMinMax={advanceFilterRanges.supplyPriceMinMax}
            />
        );
    };

    const renderFilter = () => {
        if (selectedTab === CALENDAR) {
            return (
                <CalendarFilter
                    onFilterClick={onFilterClick}
                    searchText={searchItem}
                    isFilterDisabled={!isPaidUser && selectedTab !== CALENDAR}
                    selectedFilter={selectedFilter}
                    setSearchText={(text) => {
                        setFilterSelctedKey(Math.random());
                        setSearchItem(text);
                    }}
                />
            );
        }
        return (
            <UpcomingNftFilter
                onFilterClick={onFilterClick}
                searchText={searchItem}
                isFilterDisabled={!isPaidUser && selectedTab !== CALENDAR}
                selectedFilter={selectedFilter}
                setSearchText={(text) => {
                    setFilterSelctedKey(Math.random());
                    setSearchItem(text);
                }}
            />
        );
    };

    return (
        <DataGridContainer>
            <Grid container justifyContent={"center"}>
                <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 11}>
                    <UpcomingNftTabs
                        selectedTab={selectedTab}
                        setSelectedTab={(tab) => {
                            history.push(`/calendar/${tab}`);
                        }}
                    />
                    {renderFilter()}
                    <FilterButton
                        selected={showAdvanceFilter ? 1 : 0}
                        onClick={() => setShowAdvanceFilter((prev) => !prev)}
                    >
                        Advanced Filters
                    </FilterButton>
                    {showAdvanceFilter && renderAdvanceFilter()}
                    <Typography color={messageTextColor} style={{ fontStyle: "italic" }}>
                        Submit projects{" "}
                        <a target={"_blank"} href={submitProject} style={{ color: clickHereTextColor }}>
                            here
                        </a>
                    </Typography>
                    <Typography color={messageTextColor} style={{ marginBottom: 5, fontStyle: "italic", fontSize: 12 }}>
                        We don't guarantee or endorse any links, or projects. This is not financial advice.
                    </Typography>

                    <LoadingComponent isLoading={isLoading}>{renderSelectedView()}</LoadingComponent>
                </Grid>
            </Grid>
        </DataGridContainer>
    );
};

export default UpcomingNftView;
