import { Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { messageTextColor } from "../../constants/ColorConstants";
import { AuthContext } from "../../context/AuthenticationProvider";
import { DataGridContainer } from "../neftdata/style";
import { getColumns, getTableHeight } from "./hooks";
import { ScoreVsSoldOutDataGridWrapper } from "./styled";
import { ScoreRowType } from "./types";
import { getNftScoreResultData } from "./utils";

const ScoreResultsView: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [scoreGridData, setScoreGridData] = useState<ScoreRowType[]>([]);
    const [selloutGridData, setSelloutGridData] = useState<ScoreRowType[]>([]);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));

    useEffect(() => {
        setIsLoading(true);
        getNftScoreResultData().then((data) => {
            setScoreGridData(data.scoreVsActualGridData);
            setSelloutGridData(data.selloutPercentVsActualGridData);
            setIsLoading(false);
        });
    }, []);

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <DataGridContainer>
                    <LoadingComponent isLoading={isLoading}>
                        <Grid container justifyContent={"center"}>
                            <Typography color={messageTextColor} style={{ fontStyle: "italic" }}>
                                A summary of our pre launch scores, compared to actual results
                            </Typography>
                            <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                                <ScoreVsSoldOutDataGridWrapper
                                    rowHeight={65}
                                    tableHeight={getTableHeight(scoreGridData.length)}
                                    columns={getColumns(mobileView)}
                                    rows={scoreGridData}
                                />
                            </Grid>
                        </Grid>
                    </LoadingComponent>
                </DataGridContainer>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <DataGridContainer>
                    <LoadingComponent isLoading={isLoading}>
                        <Grid container justifyContent={"center"}>
                            <Typography
                                color={messageTextColor}
                                style={{ marginTop: 15, marginBottom: 5, fontStyle: "italic" }}
                            >
                                A summary of our pre launch sell out %, compared to actual results
                            </Typography>
                            <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                                <ScoreVsSoldOutDataGridWrapper
                                    rowHeight={65}
                                    tableHeight={getTableHeight(selloutGridData.length)}
                                    columns={getColumns(mobileView)}
                                    rows={selloutGridData}
                                />
                            </Grid>
                        </Grid>
                    </LoadingComponent>
                </DataGridContainer>
            </Grid>
        </Grid>
    );
};

export default ScoreResultsView;
