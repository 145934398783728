import { Client } from "../../commoncomponents/Base/api";
import { getAllLiveMintDataApiUrl, getLiveMintDataApiUrl } from "../../constants/apiConstants";
import { LivemintType } from "./type";

const trasformData = (data: any): LivemintType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        avgSale: item.avgSale || "",
        avgTokens: parseFloat(item.avgTokens) || "",
        whalesMint: item?.whalesMint.toString() || "",
        whalesMintCount: item?.whalesMintCount,
        percUnique: parseInt((item.percUnique * 100).toString()) || "",
        collectionName: item.collectionName || "",
        contractId: item.contractId || "",
        createdDate: item.createdDate || "",
        etherscanUrl: item.etherscanUrl || "",
        maxSale: parseFloat(item.maxSale) || "",
        medianSale: parseFloat(item.medianSale) || "",
        minSale: parseFloat(item.minSale) || "",
        numTrans: parseInt(item.numTrans) || "",
        payoutAddress: item.payoutAddress || "",
        schemaName: item.schemaName || "",
        totVol: parseFloat(item.totVol) || "",
        socialLinks: {
            imageUrl: item.imageUrl || "",
            twitterUrl: item.twitterUrl,
            websiteUrl: item.websiteUrl,
            discordUrl: item.discordUrl,
            marketAffUrl: item.marketAffUrl || "",
            openseaSlug: item.openseaSlug || "",
            etherscanUrl: item.etherscanUrl || ""
        },
        twitterUsername: item.twitterUsername || ""
    }));
};

export const getLiveMintData = (timeFilter: [number, string] | undefined): Promise<LivemintType[]> =>
    Client.getInstance()
        .getData(timeFilter ? getLiveMintDataApiUrl(timeFilter[0], timeFilter[1]) : getAllLiveMintDataApiUrl(), true)
        .then((res) => Promise.resolve(trasformData(res.data)))
        .catch((err) => Promise.reject(err));
