import React, { useEffect, useRef, useState } from "react";

const UseIntervalHook = (callback: (...args: any[]) => void, delay: number, maxCount: number) => {
    const savedCallback = useRef<(...args: any[]) => void>();
    const [count, setCount] = useState(0);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = setInterval(() => {
            if (delay > 0 && count < maxCount) {
                savedCallback.current && savedCallback.current();
                setCount(count + 1);
            }
            clearInterval(tick);
        }, delay);
        return () => clearInterval(tick);
    }, [count, delay]);
};

export default UseIntervalHook;
