import { Client } from "../../commoncomponents/Base/api";
import { getNftsLaunchedApiUrl } from "../../constants/apiConstants";
import { MintedNft } from "./types";

const transformData = (data: any): MintedNft[] => {
    if (!data) {
        return [];
    }
    return data.map((nft: any) => ({
        id: nft.nftId,
        nftName: nft.nftName || "",
        score: parseInt(nft.score) || 0,
        soldOut: nft.soldOut,
        blockchain: nft.blockchain || "",
        postsaleNotes: nft.postsaleNotes || "",
        selloutMins: nft.selloutMins || "",
        soldoutTime: parseInt(nft.soldoutTime) || "",
        launchDate: nft.launchDate || "",
        soldLaunch: nft.soldLaunch || "",
        presaleDate: nft.presaleDate || "",
        mintPrice: parseFloat(nft.mintPriceCurr) || 0,
        mintSupply: nft.mintSupply || 0,
        mintCurrency: nft.mintCurrency || "",
        refered: nft.referringUser || "",
        socialLinks: {
            twitterUrl: nft.twitterUrl,
            websiteUrl: nft.websiteUrl,
            discordUrl: nft.discordUrl,
            marketAffUrl: nft.marketAffUrl || "",
            imageUrl: nft.thumbnailUrl || ""
        }
    }));
};

export const getNeftLaunchedData = () =>
    Client.getInstance()
        .getData(getNftsLaunchedApiUrl())
        .then(({ data }) => {
            return Promise.resolve(transformData(data));
        });

export const mintedNftFilters = {
    only_eth: "only_eth",
    only_sol: "only_sol",
    other_chains: "other_chains",
    score_500: "score_500",
    sold_out: "sold_out"
};

export const mintedNftFiltersData = [
    {
        value: mintedNftFilters.only_eth,
        label: "ETH Only"
    },
    {
        value: mintedNftFilters.only_sol,
        label: "SOL Only"
    },
    {
        value: mintedNftFilters.other_chains,
        label: "Other chains"
    },
    {
        value: mintedNftFilters.score_500,
        label: "Score 500+"
    },
    {
        value: mintedNftFilters.sold_out,
        label: "Sold out"
    }
];

export const mintedNftNonSoldFiltersData = [
    {
        value: mintedNftFilters.only_eth,
        label: "ETH Only"
    },
    {
        value: mintedNftFilters.only_sol,
        label: "SOL Only"
    },
    {
        value: mintedNftFilters.other_chains,
        label: "Other chains"
    },
    {
        value: mintedNftFilters.score_500,
        label: "Score 500+"
    }
];

export const includeInFilteredItems = (searchText: string, item: MintedNft): boolean =>
    item.nftName.toLowerCase().includes(searchText);

export const getFilter = (filter: string, rows: MintedNft[]) => {
    if (filter === mintedNftFilters.only_eth) {
        return rows.filter((nft) => nft.blockchain === "ETH");
    }
    if (filter === mintedNftFilters.only_sol) {
        return rows.filter((nft) => nft.blockchain === "SOL");
    }
    if (filter === mintedNftFilters.other_chains) {
        return rows.filter((nft) => nft.blockchain !== "SOL" && nft.blockchain !== "ETH");
    }
    if (filter === mintedNftFilters.sold_out) {
        return rows.filter((nft) => nft.soldOut);
    }
    return rows.filter((nft) => nft.score > 500);
};

export const onFilter = (filters: string[], rows: MintedNft[]) => {
    filters.map((filter) => {
        rows = getFilter(filter, rows);
    });
    return rows;
};
