import { Grid, Tabs } from "@mui/material";
import React, { FC } from "react";
import { MenuButton } from "../Header/headerStyled";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { NEWS_FEED, TAB_CHART } from "../../constants/appConstants";
import ShowChartIcon from "@mui/icons-material/ShowChart";

type CollectionTabProps = {
    selectedTab: string;
    setSelectedTab: (tab: string) => void;
    hideNewsFeed: boolean;
};

const CollectionTab: FC<CollectionTabProps> = ({ selectedTab, setSelectedTab, hideNewsFeed }) => {
    const handleChange = (_: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    return (
        <Grid container style={{ marginBottom: 30 }}>
            <Tabs style={{ height: 60 }} value={selectedTab} indicatorColor="primary" onChange={handleChange}>
                <MenuButton
                    iconPosition={"start"}
                    icon={<ShowChartIcon fontSize={"small"} />}
                    value={TAB_CHART}
                    label="Charts"
                />
                {hideNewsFeed && (
                    <MenuButton
                        iconPosition={"start"}
                        icon={<NewspaperIcon fontSize={"small"} />}
                        value={NEWS_FEED}
                        label="News Feed"
                    />
                )}
            </Tabs>
        </Grid>
    );
};

export default CollectionTab;
