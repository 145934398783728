import { Button, IconButton, Typography } from "@mui/material";
import Styled from "@emotion/styled";
import DataGrid from "react-data-grid";
import {
    blackColor,
    captionColor,
    cellBorderColor,
    filterButtonInactiveColor,
    headerDarkModeColor,
    headerLightModeColor,
    headerTitleColor,
    primaryButtonColor,
    primaryColor,
    primaryLightBackgroundColor,
    scrollBarColor,
    secondaryDarkBackgroundColor,
    tableRowLightColor,
    whiteColor,
    headerFooterDarkModeColor,
    gridRowsDarkModeColor,
    hignScoreGreenColor,
    datagridHoverColor
} from "../../constants/ColorConstants";

type DataGridContainerProps = {
    dynamicheight?: string;
};

export const DataGridContainer = Styled.div<DataGridContainerProps>`
   height: ${(props) => props.dynamicheight};
    padding: 0px 20px;
    @media (max-width: 600px) {
        padding:20px 0px;
  }
`;

type DataColumnTitle = {
    isdarkmode?: number;
    tableHeight?: number;
    isPaddingRequired?: number;
};

export const DataGridWrapper = Styled(DataGrid)<DataColumnTitle>`
        border-radius: 10px;
        width: 100%;
        height: ${(props) => `${props.tableHeight}px`}!important;
        box-shadow:${(props) => (props.isdarkmode ? `1px 1px 10px ${headerDarkModeColor}` : "none")};
       & .react-grid-Main{
          height: 100%;
        }
& .react-grid-Container{
height: 100%;
}
& .react-grid-Grid{
min-height: 100% !important;
}
& .react-grid-Canvas{
height: 100% !important;
}
& .rdg-row:hover {
  background-color: ${datagridHoverColor} !important;
  cursor: pointer;
}
    & .rdg-header-row {
      & .rdg-cell {
        padding-left: 10px;
        text-align: center;
      }
        letter-spacing: .05em;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px;
        text-align: left;
        background-color:${(props) => (props.isdarkmode ? headerFooterDarkModeColor : headerLightModeColor)};
        color: ${(props) => (props.isdarkmode ? whiteColor : blackColor)};
    }
    & .rdg-cell {
        border-right: 0px;
        border-bottom: 1px solid ${cellBorderColor};
        white-space: break-spaces;
        box-shadow: none;
        text-align: center;
        padding: ${(props) => (props.isPaddingRequired !== 1 ? "0px 8px" : "0px")};
    }   
    & .rdg-cell[aria-selected=true]  {
    }   
    ${(props) =>
        props.isdarkmode
            ? `& .rdg-row {
        background-color: ${gridRowsDarkModeColor};
    }`
            : ` & .rdg-row {
        background-color: ${tableRowLightColor};
    }`}
    height: 100%;
    &::-webkit-scrollbar {
      width: 0px;
      height: 10px;
      display: none;
      background-color: ${(props) => (props.isdarkmode ? secondaryDarkBackgroundColor : primaryLightBackgroundColor)}
  }
  &::-webkit-scrollbar-thumb {
    background: ${scrollBarColor};
    border-radius: 10px;
    display: none;
  }
`;

export const DataColumnTitle = Styled(Typography)<DataColumnTitle>`
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    text-transform: capitalize;
    letter-spacing: -0.01em;
    padding: 0;
    margin: 0;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    color: ${(props) => (props.isdarkmode ? whiteColor : primaryColor)};
    @media (max-width: 600px) {
    max-width: 100%;
    font-size: 0.85rem;
    white-space: break-spaces;
    overflow: visible;
  }
`;

export const DataColumnPrimaryTitle = Styled(Typography)<DataColumnTitle>`
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: -0.01em;
  padding-top: 14px;
  margin: 0;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.isdarkmode ? whiteColor : primaryColor)};
  @media (max-width: 600px) {
  max-width: 100%;
  font-size: 0.85rem;
  white-space: break-spaces;
  overflow: visible;
  }
`;
export const DataColumnSecondaryTitle = Styled(Typography)<DataColumnTitle>`
    font-style: normal;
    font-weight: 600;
    font-size:  ${(props) => (props.fontSize ? props.fontSize + "px" : ".875rem")};
    line-height: 1.25rem;
    padding-top: 14px;
    color: ${(props) => (props.isdarkmode ? whiteColor : headerTitleColor)};
`;

export const WhaleDuoColumnTitle = Styled(Typography)<DataColumnTitle>`
    font-style: normal;
    font-weight: 600;
    font-size:  ${(props) => (props.fontSize ? props.fontSize + "px" : ".875rem")};
    line-height: 1.25rem;
    padding-top: 7px;
    color: ${(props) => (props.isdarkmode ? whiteColor : headerTitleColor)};
`;

export const DataColumnCaptionTitle = Styled(Typography)<DataColumnTitle>`
    font-style: normal;
    font-weight: 400;
    font-size: .800rem;
    color: ${(props) => (props.isdarkmode ? whiteColor : captionColor)};
`;

type TableDataContainerProps = {
    ismobileview?: boolean;
};
export const TableDataContainer = Styled.div<TableDataContainerProps>`
display: flex;
align-items: flex-start;
gap: 20px;
margin-left: ${(props) => (props.ismobileview ? 0 : 10)}px;
margin-top: 8px;
width: 100%; 
height:100%;
@media (max-width: 799px) {
    flex-direction:column;
    gap: 0px;
  }
`;

export const TableIconsContainer = Styled.div`
display: flex;
align-items: center;
@media (max-width: 600px) {
    margin-top: -30px;
  }
`;

type FilterButtonProps = {
    selected?: number;
    isdarkmode?: number;
    isdisabled?: number;
};

export const FilterButton = Styled(Button)<FilterButtonProps>`
    border-radius: 24px;
    align-items: flex-start;
    background-color: ${(props) => (props.selected ? primaryButtonColor : filterButtonInactiveColor)};
    color: ${(props) => props.isdisabled === 1 && "rgba(255, 255, 255, 0.3)"} !important;
    font-weight: 600;
    font-size: 12px;
    box-shadow: none;
    text-transform: capitalize;
    padding: 8px 16px;
    gap: 5px;
    color: ${whiteColor};
    &:hover {
        color: ${whiteColor};
        box-shadow: none;
        background-color: ${(props) => (props.selected === 1 ? primaryButtonColor : filterButtonInactiveColor)};
    }
`;

export const IconContainer = Styled(IconButton)`
  padding: 2px;
  margin-bottom: 5px;
  &:hover {
      background-color: transparent;
    }
    @media (max-width: 600px) {
    margin-top: 35px;
  }
`;
export const SocialIconContainer = Styled(IconButton)`
padding: 2px;
&:hover {
        background-color: transparent;
    }
        @media (max-width: 600px) {
margin-top: 35px;
  }
`;

export const AppFooter = Styled.div`
display: flex;
width: 100%;
justify-content: flex-end;
margin-top: 60px;
padding-right:20px;
font-size: 12px;
@media (max-width: 600px) {
margin-top: 120px;
padding-right:5px;
  }
  @media (max-width: 500px) {
margin-top: 170px;
  }
`;
type HighScoreTextProp = {
    selected: boolean;
};
export const HighScoreText = Styled(Typography)<HighScoreTextProp>`
margin-top:15px;
padding:0px 10px;
color: ${whiteColor};
background-color: ${(props) => (props.selected ? hignScoreGreenColor : "transparent")};
border-radius:10px;
max-width:50px;
text-align:center;
font-style: normal;
font-weight: 600;
font-size: .875rem;
line-height: 1.25rem;
`;

export const ProjectLaunchDateContainer = Styled.div`
display: flex;
align-items: flex-end;
`;

export const SoldOutContainer = Styled.div`
display: flex;
align-items: flex-end;
`;

export const MobileCollectionGrid = Styled.div`
  margin-top: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
