import { Tooltip } from "@mui/material";
import React, { FC } from "react";
import { BarClipper, BarOne, BarThree, BarTwo, BarWrapper } from "./LinearBarStyle";

type LinearBarProps = {
    firstValue: number;
    secondValue: number;
};
const LinearBar: FC<LinearBarProps> = ({ firstValue, secondValue }) => {
    return (
        <BarClipper>
            <BarWrapper>
                <Tooltip title={`${firstValue.toFixed(2)}%`}>
                    <BarOne perwidth={firstValue} />
                </Tooltip>
                <Tooltip title={`${secondValue.toFixed(2)}%`}>
                    <BarTwo perwidth={secondValue} />
                </Tooltip>
                <Tooltip title={`${(100 - (firstValue + secondValue)).toFixed(2)}%`}>
                    <BarThree perwidth={100 - (firstValue + secondValue)} />
                </Tooltip>
            </BarWrapper>
        </BarClipper>
    );
};

export default LinearBar;
