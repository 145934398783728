import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import ListComponent from "../../commoncomponents/ListComponent";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { MAX_POLLING_COUNT, WHALE_DUO_FEED_POLLING_DELAY } from "../../constants/appConstants";
import { messageTextColor } from "../../constants/ColorConstants";
import { filterByLiveDuoFeedOptions, getFilterByTimeOptionById } from "../../constants/filterOptions";
import UseIntervalHook from "../payment/UseIntervalHook";
import { renderLiveWhaleDuoColumns } from "./hooks";
import LiveWhaleFilter from "./LiveWhaleFilter";
import { LiveWhaleDuoFeedType } from "./type";
import { embedTwitter, getLiveWhaleDuoFeedData, onFilterLiveDuoFeed } from "./util";

const LiveDuoFeed = () => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));

    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string>("30m");
    const [liveWhaleRows, setLiveWhaleRows] = useState<LiveWhaleDuoFeedType[]>([]);
    const [selectedAdditionalFilter, setSelectedAdditionalFilter] = useState<string[]>([]);
    const [filteredRows, setFilteredRows] = useState<LiveWhaleDuoFeedType[]>([]);

    useEffect(() => {
        fetchLiveWhaleDuo(true, selectedFilter, selectedAdditionalFilter);
    }, [selectedFilter]);

    useEffect(() => {
        !mobileView && embedTwitter();
    }, []);

    UseIntervalHook(
        () => {
            fetchLiveWhaleDuo(false, selectedFilter, selectedAdditionalFilter);
        },
        WHALE_DUO_FEED_POLLING_DELAY,
        MAX_POLLING_COUNT
    );

    const fetchLiveWhaleDuo = useCallback((showLoadIndicator, selectedFilter, selectedAdditionalFilter) => {
        showLoadIndicator && setIsLoading(true);
        getLiveWhaleDuoFeedData(getFilterByTimeOptionById(filterByLiveDuoFeedOptions, selectedFilter))
            .then((res) => {
                setLiveWhaleRows(res);
                setFilteredRows(onFilterLiveDuoFeed(selectedAdditionalFilter, res));
            })
            .catch(() => setLiveWhaleRows([]))
            .finally(() => setIsLoading(false));
    }, []);

    const onFilterClick = (value: string) => {
        let filteredValues = [];
        if (selectedAdditionalFilter.includes(value)) {
            filteredValues = selectedAdditionalFilter.filter((item) => item !== value);
            setSelectedAdditionalFilter(filteredValues);
            setFilteredRows(onFilterLiveDuoFeed(filteredValues, liveWhaleRows));
            return;
        }
        setSelectedAdditionalFilter((prev) => [...prev, value]);
        setFilteredRows(onFilterLiveDuoFeed([...selectedAdditionalFilter, value], liveWhaleRows));
    };

    return (
        <Grid container spacing={4}>
            <Grid style={{ paddingTop: 0 }} item xs={12} md={8}>
                <LiveWhaleFilter
                    selectedFilter={selectedAdditionalFilter}
                    onFilterClick={onFilterClick}
                    selectedTimeFilter={selectedFilter}
                    setSelectedTimeFilter={(selectedOption: string) => setSelectedFilter(selectedOption)}
                />
                <Typography color={messageTextColor} style={{ marginBottom: 5, fontStyle: "italic", fontSize: 12 }}>
                    We don't guarantee or endorse any links, or projects. This is not financial advice.
                </Typography>
                <LoadingComponent isLoading={isLoading}>
                    <ListComponent
                        columns={renderLiveWhaleDuoColumns(true, mobileView)}
                        isDarkMode={true}
                        compact={false}
                        initialSortKey="timeStamp"
                        items={filteredRows}
                        initialSortDirection={"DESC"}
                    />
                </LoadingComponent>
            </Grid>
            {!mobileView && (
                <Grid style={{ paddingTop: 0, marginTop: 78 }} item xs={4}>
                    <Typography color={messageTextColor} style={{ marginBottom: 5, fontStyle: "italic", fontSize: 12 }}>
                        We do not endorse any views below
                    </Typography>
                    <div className="twitter-embed">
                        <a
                            className="twitter-timeline"
                            data-width="100%"
                            data-height="1200"
                            data-theme="dark"
                            href="https://twitter.com/cryptoscoresio/lists/1484525851005923330?ref_src=twsrc%5Etfw"
                        >
                            A Twitter List by cryptoscoresio
                        </a>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export default LiveDuoFeed;
