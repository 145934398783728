import { Grid, Tabs } from "@mui/material";
import React, { FC } from "react";
import { MenuButton } from "../Header/headerStyled";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { NEWS_FEED, TAB_CHART, TEAM_FEED, WHALE_FEED, WHALE_TWITTER_FEED } from "../../constants/appConstants";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import GroupsIcon from "@mui/icons-material/Groups";
import whaleIcon from "../../images/whale.svg";

type CalendarPageTabsProps = {
    selectedTab: string;
    setSelectedTab: (tab: string) => void;
    showTeamFeed: boolean;
};

const CalendarPageTabs: FC<CalendarPageTabsProps> = ({ selectedTab, setSelectedTab, showTeamFeed }) => {
    const handleChange = (_: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    return (
        <Grid container style={{ marginBottom: 30 }}>
            <Tabs
                variant="scrollable"
                allowScrollButtonsMobile
                style={{ height: 60 }}
                value={selectedTab}
                indicatorColor="primary"
                onChange={handleChange}
            >
                <MenuButton
                    iconPosition={"start"}
                    icon={<ShowChartIcon fontSize={"small"} />}
                    value={TAB_CHART}
                    label="Charts"
                />
                <MenuButton
                    iconPosition={"start"}
                    icon={<NewspaperIcon fontSize={"small"} />}
                    value={NEWS_FEED}
                    label="News Feed"
                />
                {showTeamFeed && (
                    <MenuButton
                        iconPosition={"start"}
                        icon={<GroupsIcon fontSize={"small"} />}
                        value={TEAM_FEED}
                        label="Team Feed"
                    />
                )}
                <MenuButton
                    iconPosition={"start"}
                    icon={<img src={whaleIcon} height={25} width={25} />}
                    value={WHALE_FEED}
                    label="Whales"
                />
                <MenuButton iconPosition={"start"} value={WHALE_TWITTER_FEED} label="Whales Feed" />
            </Tabs>
        </Grid>
    );
};

export default CalendarPageTabs;
