import { LOCKED } from "../../constants/appConstants";
import { bannerBackgroundColor, LiveWhaleTxTypeColor } from "../../constants/ColorConstants";
import { getIcon, iconMap } from "../../utils/appIcons";
import { timeConverts } from "../../utils/getUtcTime";
import { renderLockedColumn, renderValue } from "../common/grid/Utils";
import ProjectNameView from "../neftdata/ProjectNameView";
import { DataColumnSecondaryTitle, SocialIconContainer } from "../neftdata/style";
import { TxTimeStamp } from "../walletfeed/style";
import { TxScoreText } from "./style";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HeaderTooltipView from "../../commoncomponents/HeaderTooltip";
import LinearBar from "../../commoncomponents/LinearBar";
import { HeaderRendererProps } from "react-data-grid";

const renderBuyRatioValue = (txBuys: number, txMints: number, isDarkMode: boolean, fontSize?: number) => (
    <DataColumnSecondaryTitle style={{ width: "80%" }} isdarkmode={isDarkMode ? 1 : 0} fontSize={fontSize}>
        <LinearBar firstValue={txBuys} secondValue={txMints} />
    </DataColumnSecondaryTitle>
);

export const renderLiveWhaleColumns = (isDarkMode: boolean, isMobileView: boolean) => [
    {
        key: "collectionName",
        name: "Collection Name",
        frozen: true,
        width: isMobileView ? "50%" : "25%",
        formatter: (rows: any) => (
            <ProjectNameView
                openWebsiteOnClick
                isDarkMode={isDarkMode}
                projectName={rows.row.collectionName}
                socialLinks={rows.row.socialLinks}
                collectId={rows.row.contractId}
            />
        ),
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Collection Name"
                isMobileView={isMobileView}
                headerProps={props}
                hideTooltip
                isNotCenter
            />
        ),
        sortable: true
    },
    {
        key: "buyRatio",
        name: "Buy Ratio",
        frozen: isMobileView ? false : true,
        width: "15%",
        sortable: true,
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Buy Ratio"
                description="Green - Number of Buy transactions as a % of Total. Blue - Number of Mint transactions as % of Total. Red - Number of Sales transaction as % of Total."
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) =>
            renderBuyRatioValue(
                (rows.row.txBuys * 100) / rows.row.txTotal,
                (rows.row.txMint * 100) / rows.row.txTotal,
                isDarkMode
            )
    },
    {
        key: "txBuys",
        name: "Buys #",
        width: "10%",
        sortable: true,
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Buys #"
                description="Number of Buy transactions in the secondary market"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => renderValue(rows.row.txBuys, isDarkMode)
    },
    {
        key: "totvolBuys",
        name: "Buy ETH",
        sortable: true,
        sortDescendingFirst: true,
        width: "10%",
        formatter: (rows: any) => renderValue(`${rows.row.totvolBuys}${rows.row.totvolBuys ? " Ξ" : ""}`, isDarkMode)
    },
    {
        key: "txSales",
        name: "Sales #",
        width: "10%",
        sortable: true,
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Sales #"
                description="Number of Sales transactions in the secondary market"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => renderValue(rows.row.txSales, isDarkMode)
    },
    {
        key: "totvolSales",
        name: "Sales ETH",
        sortable: true,
        sortDescendingFirst: true,
        width: "10%",
        formatter: (rows: any) => renderValue(`${rows.row.totvolSales}${rows.row.totvolSales ? " Ξ" : ""}`, isDarkMode)
    },
    {
        key: "txTotal",
        sortable: true,
        sortDescendingFirst: true,
        width: "10%",
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Total #"
                description="Total number of transactions. This includes Mints, and Secondary Market Buys and Sales. We exclude airdrops, and free transfers."
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => renderValue(rows.row.txTotal, isDarkMode)
    },
    {
        key: "totvol",
        name: "Total ETH",
        sortable: true,
        sortDescendingFirst: true,
        width: "10%",
        formatter: (rows: any) => renderValue(`${rows.row.totvol}${rows.row.totvol ? " Ξ" : ""}`, isDarkMode)
    }
];

const getTxTypeColor = (txType: string) => {
    if (txType === "Mint") {
        return LiveWhaleTxTypeColor.mint;
    }
    if (txType === "Buy") {
        return LiveWhaleTxTypeColor.buy;
    }
    if (txType === "Sale") {
        return LiveWhaleTxTypeColor.sale;
    }

    return "inherit";
};

const renderTxValue = (value: string) => {
    if (["Mint", "Buy", "Sale"].includes(value)) {
        return <TxScoreText backgroundcolor={getTxTypeColor(value)}>{value}</TxScoreText>;
    }
    if (["Received", "Sent"].includes(value)) {
        return <TxScoreText style={{ padding: 0 }}>{value}</TxScoreText>;
    }
    if (value === "Air Drop") {
        return (
            <TxScoreText style={{ display: "flex", padding: 0 }}>
                <img src={getIcon(iconMap.alarm)} height={15} width={15} />
                Airdrop
                <img src={getIcon(iconMap.alarm)} height={15} width={15} />
            </TxScoreText>
        );
    }
    return renderLockedColumn(LOCKED);
};

export const renderLiveWhaleDuoColumns = (isDarkMode: boolean, isMobileView: boolean) => [
    {
        key: "collectionName",
        name: "Collection Name",
        frozen: true,
        width: isMobileView ? "50%" : "35%",
        formatter: (rows: any) => (
            <ProjectNameView
                openWebsiteOnClick
                isDarkMode={isDarkMode}
                projectName={rows.row.collectionName}
                socialLinks={rows.row.socialLinks}
                collectId={rows.row.contractId}
            />
        ),
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Collection Name"
                isMobileView={isMobileView}
                headerProps={props}
                hideTooltip
                isNotCenter
            />
        ),
        sortable: true
    },
    {
        key: "whalename",
        name: "Whale",
        frozen: isMobileView ? false : true,
        width: "29.5%",
        sortable: true,
        formatter: (rows: any) => {
            return (
                <div
                    style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                >
                    <a href={`/wallet/${rows.row.walletId}`} target={"_blank"}>
                        {renderValue(rows.row.whalename, isDarkMode)}
                    </a>
                    <div style={{ lineHeight: "15px", marginTop: "5px", display: "flex" }}>
                        {rows.row.socialLinks.whaleTwitterUrl && (
                            <SocialIconContainer>
                                <a href={rows.row.socialLinks.whaleTwitterUrl} target={"_blank"}>
                                    <img src={getIcon(iconMap.twitter)} height={20} width={20} />
                                </a>
                            </SocialIconContainer>
                        )}
                        {rows.row.socialLinks.whaleMarketAffUrl && (
                            <SocialIconContainer>
                                <a href={rows.row.socialLinks.whaleMarketAffUrl} target={"_blank"}>
                                    <img src={getIcon(iconMap.opensea)} height={20} width={20} />
                                </a>
                            </SocialIconContainer>
                        )}
                        {rows.row.walletId && (
                            <a href={`/wallet/${rows.row.walletId}`} target={"_blank"}>
                                <SocialIconContainer>
                                    <AccountBalanceWalletIcon style={{ fontSize: 24, color: bannerBackgroundColor }} />
                                </SocialIconContainer>
                            </a>
                        )}
                    </div>
                </div>
            );
        }
    },
    {
        key: "timeStamp",
        name: "Tx",
        width: "20%",
        sortable: true,
        formatter: (rows: any) => (
            <>
                <div style={{ display: "flex", justifyContent: "center" }}>{renderTxValue(rows.row.txType)}</div>
                <TxTimeStamp>{timeConverts(rows.row.timeStamp)}</TxTimeStamp>
            </>
        )
    },
    {
        key: "value",
        name: "Value",
        width: "15%",
        sortable: true,
        sortDescendingFirst: true,
        formatter: (rows: any) => (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {renderValue(`${parseFloat(rows.row.value).toFixed(3)} Ξ`, isDarkMode)}
                {rows.row.socialLinks.tokenMarketAffUrl && (
                    <div style={{ lineHeight: "15px", marginTop: "5px", display: "flex" }}>
                        <SocialIconContainer>
                            <a href={rows.row.socialLinks.tokenMarketAffUrl} target={"_blank"}>
                                <img src={getIcon(iconMap.opensea)} height={20} width={20} />
                            </a>
                        </SocialIconContainer>
                    </div>
                )}
            </div>
        )
    }
];
