import { Grid, useMediaQuery, useTheme } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { DataGridContainer } from "../neftdata/style";
import CalendarPageInfo from "./CalendarPageInfo";
import CalendarProfile from "./CalendarProfile";
import CalendarStats from "./CalendarStats";
import { CalendarPageListType } from "./Type";
import { getCalendarCollectionList } from "./Util";
import Highcharts from "highcharts";
import {
    useDiscordEngagedChartOptions,
    useDiscordMemberChartOptions,
    usetwiiterEngagedChartOptions,
    usetwitterFollowerChartOptions
} from "./Hooks";
import { AuthContext } from "../../context/AuthenticationProvider";
import { LockScreenContainer, ChartWrapper, LockIcon } from "./Style";
import { walletConnect } from "../../utils/connector";
import CalendarPageTabs from "./CalendarPageTabs";
import { TAB_CHART, TEAM_FEED, WHALE_FEED, WHALE_TWITTER_FEED } from "../../constants/appConstants";
import CalendarPageNewFeed from "./CalendarPageNewFeed";
import CalendarInfo from "./CalendarInfo";
import CalendarPageTeamFeed from "./CalendarPageTeamFeed";
import CalendarWhaleFeed from "./CalendarWhaleFeed";
import CalendarWhaleTwitterListFeed from "./CalendarWhaleTwitterTimeline";

const CalendarPageView = () => {
    const [collectionList, setCollectionList] = useState<CalendarPageListType[]>([]);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const { nftid } = useParams<{
        nftid: string;
    }>();
    const { isPaidUser } = useContext(AuthContext);
    const [selectedTab, setSelectedTab] = useState(TAB_CHART);

    useEffect(() => {
        getCalendarCollectionList(nftid)
            .then((res) => {
                setCollectionList(res);
            })
            .catch(() => {
                setCollectionList([]);
            });
    }, []);

    const discordMemberoption = useDiscordMemberChartOptions(collectionList);
    const discordEngagedoption = useDiscordEngagedChartOptions(collectionList);
    const twitterFollowersoption = usetwitterFollowerChartOptions(collectionList);
    const twitterEngagedoption = usetwiiterEngagedChartOptions(collectionList);

    if (collectionList.length === 0) {
        return <></>;
    }

    const renderView = () => {
        if (selectedTab === TAB_CHART) {
            return (
                <Grid container marginTop={"20px"} spacing={"20px"}>
                    <ChartWrapper item xs={12} md={6}>
                        <LockScreenContainer ispaid>
                            <HighchartsReact
                                key={collectionList.length}
                                highcharts={Highcharts}
                                options={twitterFollowersoption}
                            />
                        </LockScreenContainer>
                    </ChartWrapper>
                    <ChartWrapper item xs={12} md={6}>
                        {!isPaidUser && <LockIcon onClick={() => walletConnect()} />}
                        <LockScreenContainer ispaid={isPaidUser}>
                            <HighchartsReact
                                key={collectionList.length}
                                highcharts={Highcharts}
                                options={twitterEngagedoption}
                            />
                        </LockScreenContainer>
                    </ChartWrapper>
                    <ChartWrapper item xs={12} md={6}>
                        <LockScreenContainer ispaid>
                            <HighchartsReact
                                key={collectionList.length}
                                highcharts={Highcharts}
                                options={discordMemberoption}
                            />
                        </LockScreenContainer>
                    </ChartWrapper>
                    <ChartWrapper item xs={12} md={6}>
                        {!isPaidUser && <LockIcon onClick={() => walletConnect()} />}
                        <LockScreenContainer ispaid={isPaidUser}>
                            <HighchartsReact
                                key={collectionList.length}
                                highcharts={Highcharts}
                                options={discordEngagedoption}
                            />
                        </LockScreenContainer>
                    </ChartWrapper>
                </Grid>
            );
        }
        if (selectedTab === TEAM_FEED) {
            return (
                <CalendarPageTeamFeed collectionName={collectionList[0].nftName} teamUrl={collectionList[0].team1} />
            );
        }
        if (selectedTab === WHALE_FEED) {
            return <CalendarWhaleFeed />;
        }
        if (selectedTab === WHALE_TWITTER_FEED) {
            return <CalendarWhaleTwitterListFeed />;
        }
        return (
            <CalendarPageNewFeed
                twitterUrl={collectionList[0].socialLinks.twitterUrl}
                collectionName={collectionList[0].nftName}
            />
        );
    };

    return (
        <DataGridContainer style={{ marginTop: 10 }}>
            <Grid container justifyContent="center">
                <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                    <Grid container spacing={"10px"}>
                        <Grid item xs={12} md={12} lg={3}>
                            <CalendarPageInfo collectionDescription={collectionList[0]} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <CalendarProfile collectionDescription={collectionList[0]} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <CalendarInfo collectionDescription={collectionList[0]} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <CalendarStats collectionDescription={collectionList[0]} />
                        </Grid>
                    </Grid>
                    <CalendarPageTabs
                        selectedTab={selectedTab}
                        setSelectedTab={(tab) => {
                            setSelectedTab(tab);
                        }}
                        showTeamFeed={!!collectionList[0].team1}
                    />
                    {renderView()}
                </Grid>
            </Grid>
        </DataGridContainer>
    );
};

export default CalendarPageView;
