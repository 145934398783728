import { Client } from "../../commoncomponents/Base/api";
import { getAllSignalListApiUrl, getSignalListApiUrl } from "../../constants/apiConstants";
import { IndexSignalType } from "./Type";

const tarnsformData = (data: any): IndexSignalType[] => {
    if (!data) {
        return [];
    }

    return data.map((item: any) => ({
        id: item.id || "",
        contractId: item.contractId || "",
        medianSale1d: item.medianSale1d || "",
        medianSaleDel2d: item.medianSaleDel2d,
        medianSaleDel3d: item.medianSaleDel3d,
        medianSaleDel4d: item.medianSaleDel4d,
        medianSaleDel5d: item.medianSaleDel5d,
        medSaleScore2d: item.medSaleScore2d,
        medSaleScore3d: item.medSaleScore3d,
        medSaleScore4d: item.medSaleScore4d,
        medSaleScore5d: item.medSaleScore5d,
        numTrans1d: item.numTrans1d || "",
        totVol1d: item.totVol1d || "",
        medsaleSignalScore: item.medsaleSignalScore ? item.medsaleSignalScore : 0,
        collectionName: item.collectionName || "",
        totalSupply: item.totalSupply || "",
        schemaName: item.schemaName || "",
        floorsale1D: item.floorSale1d || "",
        payoutAddress: item.payoutAddress,
        socialLinks: {
            imageUrl: item.imageUrl,
            marketAffUrl: item.marketAffUrl,
            openseaSlug: item.openseaSlug,
            websiteUrl: item.websiteUrl,
            twitterUrl: item.twitterUrl,
            discordUrl: item.discordUrl,
            etherscanUrl: item.etherscanUrl
        }
    }));
};

export const getSignalData = (): Promise<IndexSignalType[]> =>
    Client.getInstance()
        .getData(getAllSignalListApiUrl())
        .then((res) => Promise.resolve(tarnsformData(res.data)))
        .catch((err) => Promise.reject(err));
