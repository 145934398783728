import { InjectedConnector } from "@web3-react/injected-connector";

export const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 42]
});

export const walletConnect = () => {
    const button: any = document.getElementById("wallet-connect-button");
    button && button.click();
};
