import { WhaleDuoColumnTitle } from "../../neftdata/style";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LOCKED } from "../../../constants/appConstants";
import { walletConnect } from "../../../utils/connector";

export const renderValue = (value: string, isDarkMode: boolean, fontSize?: number) => {
    if (value === LOCKED) {
        return <LockOutlinedIcon onClick={() => walletConnect()} style={{ color: "#fff", cursor: "pointer" }} />;
    }
    return (
        <div style={{ lineHeight: "15px" }}>
            <WhaleDuoColumnTitle isdarkmode={isDarkMode ? 1 : 0} fontSize={fontSize}>
                {value}
            </WhaleDuoColumnTitle>
        </div>
    );
};

export const renderLockedColumn = (value: string) => {
    if (value === LOCKED) {
        return <LockOutlinedIcon onClick={() => walletConnect()} style={{ color: "#fff", cursor: "pointer" }} />;
    }
    return value;
};
