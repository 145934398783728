import { HeaderRendererProps } from "react-data-grid";
import HeaderTooltipView from "../../commoncomponents/HeaderTooltip";
import { getIcon, iconMap } from "../../utils/appIcons";
import { dateConverts } from "../../utils/getUtcTime";
import ProjectNameView from "../neftdata/ProjectNameView";
import {
    DataColumnSecondaryTitle,
    HighScoreText,
    DataColumnCaptionTitle,
    ProjectLaunchDateContainer,
    SoldOutContainer
} from "../neftdata/style";

const renderValue = (value: string, isDarkMode: boolean, fontSize?: number) => {
    return (
        <div>
            <DataColumnSecondaryTitle isdarkmode={isDarkMode ? 1 : 0} fontSize={fontSize}>
                {value}
            </DataColumnSecondaryTitle>
        </div>
    );
};

export const getColumns = (isDarkMode: boolean, isMobileView: boolean) => [
    {
        key: "project",
        name: "Collection",
        frozen: true,
        width: isMobileView ? "50%" : "25%",
        formatter: (rows: any) => (
            <ProjectNameView
                openWebsiteOnClick
                isDarkMode={isDarkMode}
                projectName={rows.row.nftName}
                socialLinks={rows.row.socialLinks}
                notes={rows.row.postsaleNotes}
                mintCurrency={rows.row.mintCurrency}
            />
        ),
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Collection"
                isMobileView={isMobileView}
                headerProps={props}
                hideTooltip
                isNotCenter
            />
        ),
        sortable: true,
        sortDescendingFirst: true
    },
    {
        key: "score",
        name: "Score",
        frozen: isMobileView ? false : true,
        width: "10%",
        formatter: (rows: any) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <HighScoreText selected={rows.row.score > 500}>{rows.row.score ? rows.row.score : ""}</HighScoreText>
            </div>
        ),
        sortable: true
    },
    {
        key: "soldOut",
        name: "SoldOut",
        width: "10%",
        sortable: false,
        formatter: (rows: any) => {
            if (rows.row.soldOut !== true && rows.row.soldOut !== false) {
                return null;
            }

            return (
                <SoldOutContainer style={{ display: "flex", justifyContent: "center" }}>
                    {<HighScoreText selected={rows.row.soldOut}>{rows.row.soldOut ? "Yes" : "No"}</HighScoreText>}
                    {rows.row.postsaleNotes && (
                        <img
                            src={getIcon(iconMap.alarm)}
                            title={rows.row.postsaleNotes}
                            height={15}
                            width={15}
                            alt="alarmIcon"
                            style={{ marginLeft: 2, marginBottom: 2 }}
                        />
                    )}
                </SoldOutContainer>
            );
        }
    },
    {
        key: "soldoutTime",
        name: "Sold Out Time",
        width: "10%",
        sortable: true,
        formatter: (rows: any) => renderValue(rows.row.soldoutTime, isDarkMode)
    },
    {
        key: "launchDate",
        name: "Launch (PreSale)",
        width: "15%",
        sortable: true,
        formatter: (rows: any) => {
            return (
                <>
                    <ProjectLaunchDateContainer style={{ display: "flex", justifyContent: "center" }}>
                        {renderValue(dateConverts(rows.row.launchDate), isDarkMode)}
                    </ProjectLaunchDateContainer>
                    {rows.row.presaleDate && (
                        <DataColumnCaptionTitle>{dateConverts(rows.row.presaleDate)}</DataColumnCaptionTitle>
                    )}
                </>
            );
        }
    },
    {
        key: "mintPrice",
        name: "Mint price (Supply)",
        width: "10%",
        formatter: (rows: any) => {
            return (
                <>
                    {renderValue(
                        rows.row.mintPrice ? `${rows.row.mintPrice} ${rows.row.mintCurrency}` : "",
                        isDarkMode
                    )}
                    <DataColumnCaptionTitle>
                        {rows.row.mintSupply ? `(${rows.row.mintSupply.toLocaleString()})` : ""}
                    </DataColumnCaptionTitle>
                </>
            );
        },
        sortable: true
    },
    {
        key: "refered",
        name: "Hunter",
        sortable: true,
        formatter: (rows: any) => renderValue(rows.row.refered, isDarkMode, 12)
    }
];

export const getNonSoldColumns = (isDarkMode: boolean, isMobileView: boolean) => [
    {
        key: "project",
        name: "Collection",
        frozen: true,
        width: isMobileView ? "50%" : "25%",
        formatter: (rows: any) => (
            <ProjectNameView
                openWebsiteOnClick
                isDarkMode={isDarkMode}
                projectName={rows.row.nftName}
                socialLinks={rows.row.socialLinks}
                notes={rows.row.postsaleNotes}
                mintCurrency={rows.row.mintCurrency}
            />
        ),
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Collection"
                isMobileView={isMobileView}
                headerProps={props}
                hideTooltip
                isNotCenter
            />
        ),
        sortable: true,
        sortDescendingFirst: true
    },
    {
        key: "score",
        name: "Score",
        frozen: isMobileView ? false : true,
        width: "10%",
        formatter: (rows: any) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <HighScoreText selected={rows.row.score > 500}>{rows.row.score ? rows.row.score : ""}</HighScoreText>
            </div>
        ),
        sortable: true
    },
    {
        key: "soldLaunch",
        name: "Sold %",
        width: "10%",
        sortable: false,
        formatter: (rows: any) =>
            renderValue(`${((rows.row.soldLaunch / rows.row.mintSupply) * 100).toFixed(2)} %`, isDarkMode)
    },
    {
        key: "postsaleNotes",
        name: "PostSaleNotes",
        sortable: false,
        width: isMobileView ? "50%" : "",
        formatter: (rows: any) => renderValue(rows.row.postsaleNotes, isDarkMode)
    }
];
