import Styled from "@emotion/styled";

type ColorTextProps = {
    color: string;
};
export const ColorText = Styled.p`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    p {
        color: ${(props: ColorTextProps) => props.color};
    }
    svg {
        color: ${(props: ColorTextProps) => props.color};
        font-size: 20px;
    }
`;
