import { useMemo } from "react";
import {
    graphHighLineColor,
    graphLowLineColor,
    graphMediumLineColor,
    graphVeryLowLineColor
} from "../../constants/ColorConstants";
import { DataColumnPrimaryTitle, DataColumnSecondaryTitle, DataColumnTitle } from "../neftdata/style";

type ColorMap = {
    minValue: number;
    maxValue: number;
    color: string;
};

const nftsHuntedColorMap: ColorMap[] = [
    {
        minValue: 0,
        maxValue: 1,
        color: "#3A3E47"
    },
    {
        minValue: 1,
        maxValue: 2,
        color: "#384B51"
    },
    {
        minValue: 2,
        maxValue: 3,
        color: "#374F54"
    },
    {
        minValue: 3,
        maxValue: 4,
        color: "#355C5D"
    },
    {
        minValue: 4,
        maxValue: 5,
        color: "#336766"
    },
    {
        minValue: 5,
        maxValue: 6,
        color: "#317470"
    },
    {
        minValue: 6,
        maxValue: 7,
        color: "#307974"
    },
    {
        minValue: 7,
        maxValue: 8,
        color: "#2D8C82"
    },
    {
        minValue: 8,
        maxValue: 9,
        color: "#2D8C82"
    },
    {
        minValue: 9,
        maxValue: 10000,
        color: "#2A9D8F"
    }
];

const averageScoreColorMap: ColorMap[] = [
    {
        minValue: 0,
        maxValue: 200,
        color: graphVeryLowLineColor
    },
    {
        minValue: 200,
        maxValue: 300,
        color: graphLowLineColor
    },
    {
        minValue: 300,
        maxValue: 500,
        color: graphMediumLineColor
    },
    {
        minValue: 500,
        maxValue: 10000,
        color: graphHighLineColor
    }
];

const getBackgroundColor = (colorMap: ColorMap[], value: number) =>
    colorMap.find(({ minValue, maxValue }) => value >= minValue && value < maxValue)?.color || "";

const renderNFTsHunted = (value: number) => (
    <div style={{ textAlign: "center", backgroundColor: getBackgroundColor(nftsHuntedColorMap, value) }}>{value}</div>
);

const renderCountSoldOut = (value: number) => (
    <div style={{ textAlign: "center", backgroundColor: getBackgroundColor(nftsHuntedColorMap, value) }}>{value}</div>
);

const renderAverageScore = (value: number) => (
    <div style={{ textAlign: "center", backgroundColor: getBackgroundColor(averageScoreColorMap, value) }}>{value}</div>
);

const renderValue = (value: string, isDarkMode: boolean, fontSize?: number) => (
    <div>
        <DataColumnSecondaryTitle isdarkmode={isDarkMode ? 1 : 0} fontSize={fontSize}>
            {value}
        </DataColumnSecondaryTitle>
    </div>
);

export const getColumns = (isDarkMode: boolean, isMobileView: boolean) =>
    useMemo(
        () => [
            {
                key: "referringUser",
                name: "Hunter",
                sortable: true,
                formatter: (rows: any) => (
                    <div style={{ marginLeft: 10 }}>
                        <DataColumnPrimaryTitle isdarkmode={isDarkMode ? 1 : 0} style={{ paddingTop: 7 }}>
                            {rows.row.referringUser}
                        </DataColumnPrimaryTitle>
                    </div>
                )
            },
            {
                key: "nftsHunted",
                name: "NFTs Hunted",
                sortable: true,
                formatter: (rows: any) => renderNFTsHunted(rows.row.nftsHunted)
            },
            {
                key: "averageScore",
                name: "Average Score",
                sortable: true,
                formatter: (rows: any) => renderAverageScore(rows.row.averageScore)
            },
            {
                key: "countSoldOut",
                name: "Count of Soldout",
                sortable: true,
                formatter: (rows: any) => renderCountSoldOut(rows.row.countSoldOut)
            }
        ],
        [isDarkMode, isMobileView]
    );
