export const discordUrl = "https://discord.com/invite/VnGeKyptSA";
export const twitterUrl = "https://twitter.com/cryptoscoresio";
export const guideUrl = "https://guide.cryptoscores.io/";
export const nftCheatSheetUrl =
    "https://docs.google.com/spreadsheets/d/1L6n5AwR2Rw0v9YyZRH9cCnhF8PAnroYpI3gKT_4uLnE/edit";
export const methodForScoring = "https://medium.com/@cryptoscores/nft-a-method-to-the-madness-8312893b325f";
export const resultsToDate = "https://twitter.com/cryptoscoresio/status/1450805136926908437";
export const mediumUrl = "https://medium.com/@cryptoscores";
export const submitProject =
    "https://docs.google.com/forms/d/e/1FAIpQLSfiPVGYKv8qL3D2GEodtIQZVVApCndeVg5HHP1vfTz_PmjP4A/viewform";
export const oldWebsiteChartUrl = "https://old.cryptoscores.io/";
