import React, { FC } from "react";
import { Grid, Toolbar, useMediaQuery, useTheme, Menu, MenuItem } from "@mui/material";
import {
    CustomAppBar,
    CustomToolbar,
    HeaderContainer,
    HeaderMenuContainer,
    LogoImage,
    MenuLinks,
    BannerCloseIcon,
    BannerContainer,
    BannerText,
    BannerCampaignIcon,
    BookIcon
} from "./headerStyled";
import { bannerReadMoreUrl } from "../../constants/appConstants";

type MainBannerProps = {
    onClose: () => void
};
const MainBanner: FC<MainBannerProps> = ({ onClose }) => (
    <BannerContainer container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item sm={12} md={"auto"}>
            <BannerText>
                <BannerCampaignIcon />
                Big news! We're soon launching cryptoscores Founding Memberships.&nbsp;
                <a
                    href={bannerReadMoreUrl}
                    target={"_blank"}
                    style={{
                        color: "white",
                        textDecoration: "underline",
                        cursor: "ponter"
                    }}>
                    Read More
                </a>
            </BannerText>
        </Grid>
        <Grid item sm={12} md={"auto"}>
            <Grid container alignItems={"center"} gap={4}>
                <Grid item>
                    <div data-vl-widget="popupTrigger"></div>
                </Grid>
                <Grid item>
                    <BannerCloseIcon onClick={onClose} />
                </Grid>
            </Grid>
        </Grid>
    </BannerContainer>
);

export default MainBanner;
