import { useMemo } from "react";
import { dateConverts } from "../../utils/getUtcTime";
import { getIcon, getTeamIcon, iconMap } from "../../utils/appIcons";
import { renderLockedColumn, renderValue } from "../common/grid/Utils";
import HeaderTooltipView from "../../commoncomponents/HeaderTooltip";
import { HeaderRendererProps } from "react-data-grid";
import ProjectNameView from "../neftdata/ProjectNameView";
import { HighScoreText, DataColumnCaptionTitle, ProjectLaunchDateContainer, IconContainer } from "../neftdata/style";
import { formatNumber, moveToCalendar } from "../../utils/siteUtil";
import { LOCKED } from "../../constants/appConstants";
import whaleIcon from "../../images/whale.svg";
import { IconText } from "../../commoncomponents/CommonStyle";
import { Tooltip } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

export const getCalendarColumns = (isDarkMode: boolean, isMobileView: boolean, isPaid: boolean) =>
    useMemo(
        () => [
            {
                key: "project",
                name: "Collection",
                frozen: true,
                width: isMobileView ? "50%" : "25%",
                formatter: (rows: any) => (
                    <ProjectNameView
                        openWebsiteOnClick={false}
                        isDarkMode={isDarkMode}
                        projectName={rows.row.project}
                        socialLinks={rows.row.socialLinks}
                        notes={rows.row.notes}
                        mintCurrency={rows.row.mintCurrency}
                        openColumnClick={() => moveToCalendar(rows.row.project, rows.row.id)}
                    />
                ),
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Collection"
                        isMobileView={isMobileView}
                        headerProps={props}
                        hideTooltip
                        isNotCenter
                    />
                ),
                sortable: true
            },
            {
                key: "launchDate",
                name: "Launch",
                width: "10%",
                sortable: true,
                sortDescendingFirst: true,
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Launch"
                        description="Launch Date (PreSale Date). Magnifying glass indicates date to be confirmed"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) => {
                    return (
                        <span onClick={() => !isPaid && moveToCalendar(rows.row.project, rows.row.id)}>
                            <ProjectLaunchDateContainer style={{ display: "flex", justifyContent: "center" }}>
                                {renderValue(dateConverts(rows.row.launchDate), isDarkMode)}
                                {!rows.row.launchConfirmed && (
                                    <IconContainer>
                                        <img
                                            src={getIcon(iconMap.tbc)}
                                            title={"To be confirmed"}
                                            height={15}
                                            width={15}
                                            alt="TBC"
                                            style={{ marginBottom: -5 }}
                                        />
                                    </IconContainer>
                                )}
                                {rows.row.calendarLink && (
                                    <IconContainer onClick={() => window.open(rows.row.calendarLink, "_blank")}>
                                        <img
                                            src={getIcon(iconMap.calendarAdd)}
                                            height={20}
                                            width={20}
                                            alt="calendar"
                                            style={{ marginBottom: -8, marginLeft: 5 }}
                                        />
                                    </IconContainer>
                                )}
                            </ProjectLaunchDateContainer>
                            {rows.row.preSaleDate && (
                                <DataColumnCaptionTitle>{dateConverts(rows.row.preSaleDate)}</DataColumnCaptionTitle>
                            )}
                        </span>
                    );
                }
            },
            {
                key: "mintPrice",
                name: "Mint",
                width: "8%",
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Mint"
                        description="Mint Price (Supply of NFTs)"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),

                formatter: (rows: any) => {
                    return (
                        <span onClick={() => !isPaid && moveToCalendar(rows.row.project, rows.row.id)}>
                            {renderValue(
                                rows.row.mintPrice ? `${rows.row.mintPrice} ${rows.row.mintCurrency}` : "",
                                isDarkMode
                            )}
                            <DataColumnCaptionTitle>
                                {rows.row.mintSupply ? `(${rows.row.mintSupply.toLocaleString()})` : ""}
                            </DataColumnCaptionTitle>
                        </span>
                    );
                },
                sortable: true,
                sortDescendingFirst: true
            },
            {
                key: "score",
                name: "Score",
                width: "4%",
                formatter: (rows: any) => (
                    <HighScoreText selected={rows.row.score > 500}>
                        {renderLockedColumn(rows.row.score ? rows.row.score : "")}
                    </HighScoreText>
                ),
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Score"
                        description="A score based on quantitative inputs. Please read our Guide"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                sortable: true,
                sortDescendingFirst: true
            },
            {
                key: "selloutChance",
                name: "SO%",
                width: "4%",
                sortable: true,
                sortDescendingFirst: true,
                formatter: (rows: any) => (
                    <>
                        {isPaid ? (
                            <>
                                <HighScoreText selected={rows.row.selloutChance > 70}>
                                    {renderLockedColumn(rows.row.selloutChance ? rows.row.selloutChance + "%" : "")}
                                </HighScoreText>
                            </>
                        ) : (
                            <>
                                <HighScoreText selected={rows.row.selloutChance > 70}>
                                    {renderLockedColumn(LOCKED)}
                                </HighScoreText>
                            </>
                        )}
                    </>
                ),
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="SO%"
                        description="Our beta AI model estimating the probability to sell out at launch."
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                )
            },
            {
                key: "whaleFollows",
                name: "Whales",
                width: "5%",
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Whales"
                        description="Whales following the project. It does not guarantee they will mint or trade."
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) => {
                    return (
                        <HighScoreText
                            style={{ maxWidth: "fit-content" }}
                            display={"flex"}
                            alignItems={"center"}
                            gap={"10px"}
                            selected={rows.row.whaleFollows >= 2}
                        >
                            {isPaid && <img src={whaleIcon} height={21} width={20} />}
                            {renderLockedColumn(!isPaid ? LOCKED : `${rows.row.whaleFollows.toLocaleString()}`)}
                        </HighScoreText>
                    );
                },
                sortable: true,
                sortDescendingFirst: true
            },
            {
                key: "activeScore",
                name: "Audience",
                sortable: true,
                sortDescendingFirst: true,
                width: "7%",
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Audience"
                        description="Audience Score is a community score we give from 0-100 of the 'active' audience in the project."
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),

                formatter: (rows: any) => {
                    return (
                        <HighScoreText selected={rows.row.activeScore >= 50}>
                            {renderLockedColumn(!isPaid ? LOCKED : `${rows.row.activeScore.toLocaleString()}`)}
                        </HighScoreText>
                    );
                }
            },
            {
                key: "engageMentScore",
                name: "GLUE",
                sortable: true,
                sortDescendingFirst: true,
                width: "6%",
                formatter: (rows: any) => {
                    return (
                        <HighScoreText selected={rows.row.engageMentScore >= 50}>
                            {renderLockedColumn(!isPaid ? LOCKED : `${rows.row.engageMentScore.toLocaleString()}`)}
                        </HighScoreText>
                    );
                },
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="GLUE"
                        description="GLUE is another community score we give from 0-100 on how 'engaged' the audience is, relative to the size of the audience. Projects with high engagement will get a high score, and vice versa."
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                )
            },
            {
                key: "twitterFollowers",
                name: "Twitter",
                width: "4%",
                formatter: (rows: any) => {
                    return (
                        <IconText onClick={() => !isPaid && moveToCalendar(rows.row.project, rows.row.id)}>
                            <img src={getIcon(iconMap.twitter)} height={21} width={21} />
                            {renderValue(`${formatNumber(rows.row.twitterFollowers)}`, isDarkMode)}
                        </IconText>
                    );
                },
                sortable: true,
                sortDescendingFirst: true
            },
            // {
            //     key: "twitterAvgLikes",
            //     name: "Twitter Active",
            //     width: "10%",
            //     formatter: (rows: any) => {
            //         return (
            //             <>{renderValue(!isPaid ? LOCKED : `${formatNumber(rows.row.twitterAvgLikes)}`, isDarkMode)}</>
            //         );
            //     },
            //     sortable: true,
            //     sortDescendingFirst: true
            // },
            {
                key: "discordMembers",
                name: "Discord",
                width: "4%",
                formatter: (rows: any) => {
                    return (
                        <IconText onClick={() => !isPaid && moveToCalendar(rows.row.project, rows.row.id)}>
                            <img src={getIcon(iconMap.discord)} height={21} width={21} />
                            {renderValue(`${formatNumber(rows.row.discordMembers)}`, isDarkMode)}
                        </IconText>
                    );
                },
                sortable: true,
                sortDescendingFirst: true
            },
            {
                key: "team",
                name: "Team",
                width: "8%",
                formatter: (rows: any) => (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {rows.row.team1 && (
                            <Tooltip title={rows.row.team1Role}>
                                <span
                                    onClick={() => window.open(rows.row.team1, "_blank")}
                                    style={{ marginRight: "2px", marginTop: -7 }}
                                >
                                    {getTeamIcon[rows.row.team1Role.toLowerCase()]}
                                </span>
                            </Tooltip>
                        )}
                        {rows.row.team2 && (
                            <Tooltip title={rows.row.team2Role}>
                                <span
                                    onClick={() => window.open(rows.row.team2, "_blank")}
                                    style={{ marginRight: "2px", marginTop: -7 }}
                                >
                                    {getTeamIcon[rows.row.team2Role.toLowerCase()]}
                                </span>
                            </Tooltip>
                        )}
                        {rows.row.team3 && (
                            <Tooltip title={rows.row.team3Role}>
                                <span
                                    onClick={() => window.open(rows.row.team3, "_blank")}
                                    style={{ marginRight: "2px", marginTop: -7 }}
                                >
                                    {getTeamIcon[rows.row.team3Role.toLowerCase()]}
                                </span>
                            </Tooltip>
                        )}
                    </div>
                ),
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Team"
                        description="Links to team members. Superhero is a Founder. Paint palette is an Artist, or Creative Director. Building is a Company. Person on a laptop is community or marketing person"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                sortable: false
            },
            // {
            //     key: "discordActiveFollowers",
            //     name: "Discord Active",
            //     width: "10%",
            //     formatter: (rows: any) => {
            //         return (
            //             <>
            //                 {renderValue(
            //                     !isPaid ? LOCKED : `${formatNumber(rows.row.discordActiveFollowers)}`,
            //                     isDarkMode
            //                 )}
            //             </>
            //         );
            //     },
            //     sortable: true,
            //     sortDescendingFirst: true
            // },
            {
                key: "refered",
                name: "Hunter",
                sortable: true,
                sortDescendingFirst: true,
                width: "11.5%",
                formatter: (rows: any) => (
                    <span onClick={() => !isPaid && moveToCalendar(rows.row.project, rows.row.id)}>
                        {renderValue(rows.row.refered, isDarkMode, 12)}
                    </span>
                ),

                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Hunter"
                        description="Members of our community who submitted this project to our calendar"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                )
            }
        ],
        [isDarkMode, isMobileView]
    );
