import { Grid, Typography } from "@mui/material";
import React from "react";
import { messageTextColor } from "../constants/ColorConstants";

export const NotFinancialAdviceMessage = () => (
    <Grid item>
        <Typography color={messageTextColor} style={{ marginBottom: 5, fontStyle: "italic", fontSize: 12 }}>
            We don't guarantee or endorse any links, or projects. This is not financial advice.
        </Typography>
    </Grid>
);
