import { Client } from "../../commoncomponents/Base/api";
import { getWalletToPay, reportPaymentPaid, verifyPaidWalletApiUrl } from "../../constants/apiConstants";

export const getTargetWalletAddress = () =>
    Client.getInstance()
        .getData(getWalletToPay())
        .then(({ data }) =>
            Promise.resolve({
                securedWalletAddress: data.securedWalletAddress,
                paymentValue: data.paymentValue.toString()
            })
        );

export const verifyWalletAddress = (walletAddress: string) =>
    Client.getInstance()
        .getData(verifyPaidWalletApiUrl(walletAddress), true)
        .then(({ data }) =>
            Promise.resolve({
                status: data.status
            })
        );

export const reportPayment = (txHash: string, from: string, to: string) =>
    Client.getInstance().createData(reportPaymentPaid(txHash, from, to), {
        tx_hash: txHash,
        pending_from: from,
        pendint_to: to
    });
