import styled from "@emotion/styled";
import { AppBar, Button, Grid, Tab, Toolbar, Typography } from "@mui/material";
import {
    menuLinkBgColor,
    menuLinkColor,
    whiteColor,
    menuLinkColorInactive,
    headerFooterDarkModeColor,
    bannerBackgroundColor,
    guideIconColor
} from "../../constants/ColorConstants";
import CampaignIcon from "@mui/icons-material/Campaign";
import CloseIcon from "@mui/icons-material/Close";

export type ThemeProps = {
    darkmode?: string;
    margintop?: string;
};

export const CustomAppBar = styled(AppBar)<ThemeProps>`
    background-color: ${(props) => (props.darkmode === "true" ? headerFooterDarkModeColor : whiteColor)};
    box-shadow: 2px 1px 49px -25px rgba(0, 0, 0, 0.61);
    background-image: none;
    margin-top: ${(props) => props.margintop};
`;

export const CustomToolbar = styled(Toolbar)`
    padding-left: 2% !important;
    padding-right: 2% !important;
`;

type MenuLinksProps = {
    selected?: boolean;
    darkmode?: string;
};

export const MenuLinks = styled(Typography)<MenuLinksProps>`
    color: ${(props) =>
        props.selected ? menuLinkColor : props.darkmode === "true" ? whiteColor : menuLinkColorInactive};
    padding: 5px;
    background-color: transparent;
    border-radius: 6px;
    font-weight: bold;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    :hover {
        color: ${menuLinkColor};
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const HeaderMenuContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;

export const MobileMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 6px;
`;

export const LogoImage = styled.img`
    height: 45px;
    border-radius: 5px;
`;

export const ConnectWalletButton = styled(Button)`
    border-radius: 6px;
    align-items: flex-start;
    font-weight: 600;
    font-size: 12px;
    box-shadow: none;
    text-transform: capitalize;
    padding: 8px 16px;
    gap: 5px;
    color: ${whiteColor};
    &:hover {
        color: ${whiteColor};
        box-shadow: none;
    }
`;

export const HeaderButton = styled(Button)`
    background: transparent;
    text-transform: none;
    color: ${whiteColor};
    &:hover {
        background-color: transparent;
    }
`;

export const MenuButton = styled(Tab)`
    font-size: 13px;
    font-weight: bold;
    text-transform: none;
    padding-top: 0px !important;
    color: ${whiteColor};
`;

export const BannerText = styled(Typography)`
    font-weight: 600;
    color: ${whiteColor};
    display: flex;
    align-items: center;
`;

export const BannerCloseIcon = styled(CloseIcon)`
    color: ${whiteColor};
    font-size: 30px;
    margin-top: 8px;
    cursor: pointer;
`;

export const BannerCampaignIcon = styled(CampaignIcon)`
    color: ${whiteColor};
    font-size: 30px;
    margin-right: 5px;
`;

export const BannerContainer = styled(Grid)`
    background-color: ${bannerBackgroundColor};
    padding: 10px 20px;
`;

export const BookIcon = styled.span`
    color: ${guideIconColor};
    background: ${guideIconColor};
    border-bottom-left-radius: 4px;
    border-bottom: 2px white solid;
    border-top-left-radius: 3px;
`;
