import Styled from "@emotion/styled";
import DataGrid from "react-data-grid";
import {
    cellBorderColor,
    gridRowsDarkModeColor,
    headerFooterDarkModeColor,
    whiteColor
} from "../../constants/ColorConstants";

type DataColumnTitle = {
    tableHeight?: number;
};

export const ScoreVsSoldOutDataGridWrapper = Styled(DataGrid)<DataColumnTitle>`
    border-radius: 10px;
    width: 100%;
    height: ${(props) => `${props.tableHeight}px`}!important;
    overflow-y: hidden;
    & .react-grid-Main{
          height: 100%;
    }
    & .react-grid-Grid{
        min-height: 100% !important;
    }
    & .react-grid-Canvas{
        height: 100% !important;
    }
    & .rdg-header-row {
        letter-spacing: .05em;
        text-transform: uppercase;
        font-weight: bold;
        font-size: .75rem;
        text-align: left;
        background-color:${headerFooterDarkModeColor};
        color: ${whiteColor};
    }
    & .rdg-cell {
        border-right: 0px;
        border-bottom: 1px solid ${cellBorderColor};
        white-space: break-spaces;
        box-shadow: none;
        font-weight: bold;
        text-align: center;
        color: ${whiteColor};
        padding-right: 0px;
        padding-left: 10px;
    }
    & .rdg-row {
        background-color: ${gridRowsDarkModeColor};
    }
`;
