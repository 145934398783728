import React, { FC, useEffect } from "react";
import { embedLink } from "./Util";

type TimeLineCardProps = {
    twitterName: string;
};

const TimeLineCard: FC<TimeLineCardProps> = ({ twitterName }) => {
    useEffect(() => {
        embedLink("twitter-timeline");
    }, []);

    return (
        <div>
            <a
                className="twitter-timeline"
                data-width="100%"
                data-height="100%"
                data-theme="dark"
                href={`https://twitter.com/${twitterName}?ref_src=twsrc%5Etfw`}
            >
                Tweets by {twitterName}
            </a>
        </div>
    );
};

export default TimeLineCard;
