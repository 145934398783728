import { Grid, Paper, Typography } from "@mui/material";
import { SummaryContainer } from "../collection/Styled";
import { CalendarPageListType } from "./Type";
import { FC, useContext } from "react";
import { formatNumber } from "../../utils/siteUtil";
import { getIcon, iconMap } from "../../utils/appIcons";
import { AuthContext } from "../../context/AuthenticationProvider";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { walletConnect } from "../../utils/connector";
import { LOCKED } from "../../constants/appConstants";
import { HeaderTooltip } from "../mintradar/Style";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type CalendarStatsProps = {
    collectionDescription: CalendarPageListType;
};

const CalendarStats: FC<CalendarStatsProps> = ({ collectionDescription }) => {
    const { isPaidUser } = useContext(AuthContext);

    return (
        <Grid container height={"100%"} justifyContent="center">
            <Grid item style={{ borderRadius: 5 }} xs={12}>
                <Paper style={{ padding: 10, height: "100%" }} elevation={2}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography fontSize={14} display={"flex"} alignItems={"center"} style={{ color: "grey" }}>
                                Whale
                                <HeaderTooltip
                                    title={
                                        "Whales following the project. It does not guarantee they will mint or trade."
                                    }
                                >
                                    <InfoOutlinedIcon style={{ width: 14 }} />
                                </HeaderTooltip>
                            </Typography>
                            <SummaryContainer>
                                {isPaidUser ? (
                                    <Typography
                                        display={"flex"}
                                        alignItems={"center"}
                                        variant={"body1"}
                                        style={{ fontSize: 18 }}
                                    >
                                        {collectionDescription.whaleFollows &&
                                        collectionDescription.whaleFollows !== LOCKED ? (
                                            <>
                                                <img src={getIcon(iconMap.whale)} height={20} width={20} />
                                                {formatNumber(parseInt(collectionDescription.whaleFollows))}
                                            </>
                                        ) : (
                                            "-"
                                        )}
                                    </Typography>
                                ) : (
                                    <LockOutlinedIcon
                                        onClick={() => walletConnect()}
                                        style={{ color: "#fff", cursor: "pointer" }}
                                    />
                                )}
                            </SummaryContainer>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography display={"flex"} alignItems={"center"} style={{ fontSize: 14, color: "grey" }}>
                                Hunter
                                <HeaderTooltip
                                    title={"Members of our community who submitted this project to our calendar"}
                                >
                                    <InfoOutlinedIcon style={{ width: 14 }} />
                                </HeaderTooltip>
                            </Typography>
                            <SummaryContainer>
                                <Typography variant={"body1"} style={{ fontSize: 18 }}>
                                    {collectionDescription.hunter || "-"}
                                </Typography>
                            </SummaryContainer>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 10 }}>
                        <Grid item xs={6}>
                            <Typography style={{ fontSize: 14, color: "grey" }}>Twitter</Typography>
                            <SummaryContainer>
                                <Typography
                                    display={"flex"}
                                    alignItems={"center"}
                                    variant={"body1"}
                                    style={{ fontSize: 18 }}
                                >
                                    {collectionDescription.twitterFollowers &&
                                    collectionDescription.twitterFollowers !== LOCKED ? (
                                        <>
                                            <img src={getIcon(iconMap.twitter)} height={20} width={20} />
                                            {formatNumber(parseInt(collectionDescription.twitterFollowers))}
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </Typography>
                            </SummaryContainer>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{ fontSize: 14, color: "grey" }}>Discord</Typography>
                            <SummaryContainer>
                                <Typography
                                    display={"flex"}
                                    alignItems={"center"}
                                    variant={"body1"}
                                    style={{ fontSize: 18 }}
                                >
                                    {collectionDescription.discordMembers &&
                                    collectionDescription.discordMembers !== LOCKED ? (
                                        <>
                                            <img src={getIcon(iconMap.discord)} height={20} width={20} />
                                            {formatNumber(parseInt(collectionDescription.discordMembers))}
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </Typography>
                            </SummaryContainer>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CalendarStats;
