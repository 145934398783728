export type FilterByTimeOption = {
    id: string;
    value: [number, string];
    label: string;
    onlyForPaidUser: boolean;
};

export const filterByTimeOptions: FilterByTimeOption[] = [
    {
        id: "1m",
        value: [1, "minutes"],
        label: "1m",
        onlyForPaidUser: true
    },
    {
        id: "5m",
        value: [5, "minutes"],
        label: "5m",
        onlyForPaidUser: true
    },
    {
        id: "30m",
        value: [30, "minutes"],
        label: "30m",
        onlyForPaidUser: true
    },
    {
        id: "1h",
        value: [1, "hours"],
        label: "1h",
        onlyForPaidUser: false
    },
    {
        id: "3h",
        value: [3, "hours"],
        label: "3h",
        onlyForPaidUser: false
    },
    {
        id: "12h",
        value: [12, "hours"],
        label: "12h",
        onlyForPaidUser: false
    },
    {
        id: "1d",
        value: [1, "days"],
        label: "1d",
        onlyForPaidUser: false
    },
    {
        id: "3d",
        value: [3, "days"],
        label: "3d",
        onlyForPaidUser: false
    }
];

export const filterByTimeMintRadarMintsOptions: FilterByTimeOption[] = [
    {
        id: "5m",
        value: [5, "minutes"],
        label: "5m",
        onlyForPaidUser: true
    },
    {
        id: "30m",
        value: [30, "minutes"],
        label: "30m",
        onlyForPaidUser: true
    },
    {
        id: "1h",
        value: [60, "minutes"],
        label: "1h",
        onlyForPaidUser: false
    },
    {
        id: "3h",
        value: [180, "minutes"],
        label: "3h",
        onlyForPaidUser: false
    },
    {
        id: "12h",
        value: [12 * 60, "minutes"],
        label: "12h",
        onlyForPaidUser: false
    },
    {
        id: "1D",
        value: [24 * 60, "minutes"],
        label: "1d",
        onlyForPaidUser: false
    }
];

export const filterByTimeMintRadarSalesOptions: FilterByTimeOption[] = [
    {
        id: "5m",
        value: [5, "minutes"],
        label: "5m",
        onlyForPaidUser: true
    },
    {
        id: "30m",
        value: [30, "minutes"],
        label: "30m",
        onlyForPaidUser: true
    },
    {
        id: "1h",
        value: [60, "minutes"],
        label: "1h",
        onlyForPaidUser: false
    },
    {
        id: "3h",
        value: [3 * 60, "minutes"],
        label: "3h",
        onlyForPaidUser: false
    }
];
export const filterByLiveDuoFeedOptions: FilterByTimeOption[] = [
    {
        id: "5m",
        value: [5, "minutes"],
        label: "5m",
        onlyForPaidUser: false
    },
    {
        id: "30m",
        value: [30, "minutes"],
        label: "30m",
        onlyForPaidUser: false
    },
    {
        id: "1h",
        value: [60, "minutes"],
        label: "1h",
        onlyForPaidUser: false
    }
];

export const filterByWalletFeedOptions: FilterByTimeOption[] = [
    {
        id: "5m",
        value: [5, "minutes"],
        label: "5m",
        onlyForPaidUser: true
    },
    {
        id: "30m",
        value: [30, "minutes"],
        label: "30m",
        onlyForPaidUser: true
    },
    {
        id: "1h",
        value: [60, "minutes"],
        label: "1h",
        onlyForPaidUser: false
    },
    {
        id: "3h",
        value: [180, "minutes"],
        label: "3h",
        onlyForPaidUser: false
    },
    {
        id: "12h",
        value: [720, "minutes"],
        label: "12h",
        onlyForPaidUser: false
    }
];

export const getFilterByTimeOptionById = (options: FilterByTimeOption[], id: string): [number, string] | undefined => {
    const filterByTimeOption = options.find((option: FilterByTimeOption) => option.id === id);

    if (!filterByTimeOption) {
        return undefined;
    }

    return filterByTimeOption.value;
};

export const filterByIndexOptions: FilterByTimeOption[] = [
    {
        id: "12h",
        value: [12, "hours"],
        label: "12h",
        onlyForPaidUser: false
    },
    // {
    //     id: "1d",
    //     value: [1, "days"],
    //     label: "1d",
    //     onlyForPaidUser: false
    // }
];
