import { useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DurationFilterView from "../../commoncomponents/durationfilter/DurationFilterView";
import ListComponent from "../../commoncomponents/ListComponent";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { NotFinancialAdviceMessage } from "../../commoncomponents/NotFinancialAdviceMessage";
import { LIVE_WHALE_POLLING_DELAY, MAX_POLLING_COUNT } from "../../constants/appConstants";
import { filterByTimeOptions, getFilterByTimeOptionById } from "../../constants/filterOptions";
import { moveToCollectionId } from "../../utils/siteUtil";
import UseIntervalHook from "../payment/UseIntervalHook";
import { renderLiveWhaleColumns } from "./hooks";
import { LiveWhaleType } from "./type";
import { getLiveWhaleData } from "./util";

const LiveWhaleBuySell = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string>("1h");
    const [liveWhaleRows, setLiveWhaleRows] = useState<LiveWhaleType[]>([]);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));

    useEffect(() => {
        fetchLiveWhale(true);
    }, [selectedFilter]);

    UseIntervalHook(
        () => {
            fetchLiveWhale(false);
        },
        ["5m", "30m"].includes(selectedFilter) ? LIVE_WHALE_POLLING_DELAY : 0,
        MAX_POLLING_COUNT
    );

    const fetchLiveWhale = useCallback(
        (showLoadIndicator) => {
            showLoadIndicator && setIsLoading(true);
            getLiveWhaleData(getFilterByTimeOptionById(filterByTimeOptions, selectedFilter))
                .then((data) => {
                    setLiveWhaleRows(data);
                })
                .catch(() => setLiveWhaleRows([]))
                .finally(() => setIsLoading(false));
        },
        [selectedFilter]
    );

    return (
        <>
            <DurationFilterView
                filterOptions={filterByTimeOptions}
                selectedFilter={selectedFilter}
                setSelectedFilter={(selectedOption: string) => setSelectedFilter(selectedOption)}
            />
            <NotFinancialAdviceMessage />
            <LoadingComponent isLoading={isLoading}>
                <ListComponent
                    columns={renderLiveWhaleColumns(true, mobileView)}
                    isDarkMode={true}
                    compact={false}
                    items={liveWhaleRows}
                    initialSortDirection={"DESC"}
                    onRowClick={(rows, columnName) => {
                        if (columnName !== "collectionName") {
                            moveToCollectionId(rows.contractId);
                        }
                    }}
                />
            </LoadingComponent>
        </>
    );
};

export default LiveWhaleBuySell;
