import Styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { blinkDotColor } from "../../constants/ColorConstants";

export const RingContainer = Styled.div`
    position: relative;
    margin-right: 4px;
`;

export const Circle = Styled.div`
    width: 10px;
    height: 10px;
    background-color: ${blinkDotColor};
    border-radius: 50%;
    position: absolute;
    top: -3.5px;
    left: -1.5px;
`;

const pulsate = keyframes`
 0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;
}`;

export const Ringering = Styled.div`
    border: 2px solid ${blinkDotColor};
    -webkit-border-radius: 30px;
    height: 25px;
    width: 25px;
    position: absolute;
    -webkit-animation: ${pulsate} 2s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0.0;
    top: -11px;
    left: -9px;
`;
