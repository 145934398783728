/* eslint-disable no-console */
import { Client } from "../../commoncomponents/Base/api";
import {
    getCollectionDataApiUrl,
    getCollectionDescriptionApiUrl,
    getCollectionSummaryListApiUrl
} from "../../constants/apiConstants";
import { ScatterChartData, CollectionChartData, CollectionDescriptionType } from "./Types";
import { subMinutes, addMinutes } from "date-fns";
import { average, avgLogValue, logValue, standardDeviation, stdDeviationOfLogValues } from "../../utils/MathUtils";

const transformData = (data: any): CollectionChartData[] =>
    data.map((item: any) => ({
        id: item.id,
        value: item.value,
        timeStampValue: item.timeStampValue,
        whaleYn: item.whaleYn,
        txType: item.txType
    }));

export const convertCollectionDataToScatterChartData = (
    collectionData: CollectionChartData[],
    spanInMins: number,
    showOutliners: boolean
) => {
    const data: ScatterChartData = {
        all: [],
        regular: [],
        buy: [],
        sell: [],
        sale: [],
        mint: []
    };

    let count = 0;
    collectionData.forEach((item: CollectionChartData) => {
        const timeStamp = new Date(item.timeStampValue + "Z");
        const cutOffTimestamp = subMinutes(new Date(), spanInMins);
        if (cutOffTimestamp > timeStamp) {
            count++;
            return true;
        }
        const timeX = timeStamp.getTime() - new Date().getTimezoneOffset() * 60 * 1000;
        data.all.push([timeX, item.value]);
        if (item.txType === "Sale") {
            data.sale.push([timeX, item.value]);
        }
        if (item.txType === "Mint") {
            data.mint.push([timeX, item.value]);
        }
        if (item.whaleYn === "Regular") {
            data.regular.push([timeX, item.value]);
        }
        if (item.whaleYn === "Whale Buy") {
            data.buy.push([timeX, item.value]);
        }
        if (item.whaleYn === "Whale Sale") {
            data.sell.push([timeX, item.value]);
        }
    });

    const scatterDataOutlined = {
        all: data.all,
        regular: getOutlinedData(data.all, data.regular, showOutliners, "regular"),
        buy: getOutlinedData(data.all, data.buy, showOutliners, "buy"),
        sell: getOutlinedData(data.all, data.sell, showOutliners, "sell"),
        sale: getOutlinedData(data.all, data.sale, showOutliners, "sale"),
        mint: getOutlinedData(data.all, data.mint, showOutliners, "mint")
    };
    return scatterDataOutlined;
};

const getOutlinedData = (allData: number[][], data: number[][], showOutliners: boolean, dataType: string) => {
    if (showOutliners) {
        return data;
    }
    const valuesArray = allData.map((ad) => ad[1]);
    const logStdDeviation = stdDeviationOfLogValues(valuesArray);
    const logAvg = avgLogValue(valuesArray);
    const stdDeviationValue = standardDeviation(valuesArray);
    const avgValue = average(valuesArray);
    // remove outliers
    return data.filter((value) => {
        const test1 = Math.abs(logValue(value[1]) - logAvg) < 2 * logStdDeviation;
        const test2 = Math.abs(value[1]) - avgValue < 2 * stdDeviationValue;
        return test1 && test2;
    });
};

const filterOutlinerForTradeChart = (
    data: CollectionChartData,
    logAvg: number,
    logStdValue: number,
    avgValue: number,
    StdValue: number,
    showOutliners: boolean
): boolean => {
    if (showOutliners) {
        return true;
    }
    const test1 = Math.abs(logValue(data.value) - logAvg) < 2 * logStdValue;
    const test2 = Math.abs(data.value - avgValue) < 2 * StdValue;
    return test1 && test2;
};

export const convertCollectionDataToTradeChartData = (
    collectionData: CollectionChartData[],
    interalInMins: number,
    spanInMins: number,
    showOutliners: boolean
) => {
    const buckets: any[] = [];
    const initalTimeStamp = subMinutes(new Date(), spanInMins);
    for (let i = 0; i < Math.floor(spanInMins / interalInMins); i++) {
        buckets.push({
            bucketId: i,
            minTimeStampValue: addMinutes(initalTimeStamp, i * interalInMins),
            maxTimeStampValue: addMinutes(initalTimeStamp, (i + 1) * interalInMins),
            value: []
        });
    }
    const cutOffTimestamp = subMinutes(new Date(), spanInMins);
    const spanData = collectionData.filter(
        (item: CollectionChartData) => cutOffTimestamp < new Date(item.timeStampValue + "Z")
    );

    const spanDataValues = spanData.map(({ value }) => value).filter((value) => value > 0);
    const logAvg = avgLogValue(spanDataValues);
    const avgValue = average(spanDataValues);
    const logStdValue = stdDeviationOfLogValues(spanDataValues);
    const StdValue = standardDeviation(spanDataValues);

    spanData
        .filter((data) => filterOutlinerForTradeChart(data, logAvg, logStdValue, avgValue, StdValue, showOutliners))
        .map((item: CollectionChartData) => {
            const timeStamp = new Date(item.timeStampValue + "Z");
            const cutOffTimestamp = subMinutes(new Date(), spanInMins);
            if (cutOffTimestamp > timeStamp) {
                return true;
            }

            const bucketId = Math.floor((timeStamp.getTime() - initalTimeStamp.getTime()) / (interalInMins * 60000));
            if (buckets[bucketId]) {
                buckets[bucketId].value.push(item.value);
            }
        });

    const chartData = buckets
        .filter(({ value }) => value && value.length > 0)
        .map(({ minTimeStampValue, value }) => [
            minTimeStampValue.getTime() - new Date().getTimezoneOffset() * 60 * 1000,
            Math.min(...value),
            parseFloat(average(value).toFixed(3))
        ]);

    return { data: chartData };
};

export const convertCollectionDataToBarChartData = (
    collectionData: CollectionChartData[],
    interalInMins: number,
    spanInMins: number
) => {
    const buckets: any[] = [];
    const initalTimeStamp = subMinutes(new Date(), spanInMins);
    for (let i = 0; i < Math.floor(spanInMins / interalInMins); i++) {
        buckets.push({
            bucketId: i,
            minTimeStampValue: addMinutes(initalTimeStamp, i * interalInMins),
            maxTimeStampValue: addMinutes(initalTimeStamp, (i + 1) * interalInMins),
            value: 0
        });
    }

    collectionData.map((item: CollectionChartData) => {
        const timeStamp = new Date(item.timeStampValue + "Z");
        const cutOffTimestamp = subMinutes(new Date(), spanInMins);
        if (cutOffTimestamp > timeStamp) {
            return true;
        }

        const bucketId = Math.floor((timeStamp.getTime() - initalTimeStamp.getTime()) / (interalInMins * 60000));
        if (buckets[bucketId]) {
            buckets[bucketId].value += item.value;
        }
    });
    const chartData = buckets
        .filter(({ value }) => value > 0)
        .map(({ minTimeStampValue, value }) => [
            minTimeStampValue.getTime() - new Date().getTimezoneOffset() * 60 * 1000,
            parseFloat(value.toFixed(2))
        ]);

    return chartData;
};

export const getCollectionData = (collectionId: string, intervalMins: number): Promise<any> =>
    Client.getInstance()
        .getData(getCollectionDataApiUrl(collectionId, intervalMins), true)
        .then((res) => Promise.resolve(transformData(res.data)))
        .catch((err) => Promise.reject(err));

const transformDescription = (data: any): CollectionDescriptionType => {
    return {
        rank: data?.[0]?.rank || "",
        contractId: data?.[0]?.contractId || "",
        collectionName: data?.[0]?.collectionName || "",
        createdDate: data?.[0]?.createdDate || "",
        totalSupply: data?.[0]?.totalSupply || "",
        schemaName: data?.[0]?.schemaName || "",
        imageUrl: data?.[0]?.imageUrl || "",
        largeImageUrl: data?.[0]?.largeImageUrl || "",
        osVerified: data?.[0]?.osVerified || "",
        payoutAddress: data?.[0]?.payoutAddress || "",
        marketAffUrl: data?.[0]?.marketAffUrl || "",
        looksUrl: data?.[0]?.looksUrl || "",
        royaltyDev: data?.[0]?.royaltyDev || "",
        description: data?.[0]?.description || "",
        socialLinks: {
            websiteUrl: data?.[0]?.websiteUrl || "",
            discordUrl: data?.[0]?.discordUrl || "",
            twitterUrl: data?.[0]?.twitterUrl || "",
            openseaSlug: data?.[0]?.openseaSlug || "",
            etherscanUrl: data?.[0]?.etherscanUrl || ""
        }
    };
};

export const getCollectionDescription = (collectionId: string) =>
    Client.getInstance()
        .getData(getCollectionDescriptionApiUrl(collectionId))
        .then((res) => Promise.resolve(transformDescription(res.data.length === 0 ? null : res.data)))
        .catch((err) => Promise.reject(err));

export const getCollectionSummary = async (collectionId: string) =>
    Client.getInstance()
        .getData(getCollectionSummaryListApiUrl())
        .then((res) => {
            if (!res.data) {
                return Promise.resolve(null);
            }
            return Promise.resolve(res.data.find((item: any) => item.contract === collectionId));
        })
        .catch((err) => Promise.reject(err));
