import { Grid, Avatar, Typography } from "@mui/material";
import { FC } from "react";
import { bannerBackgroundColor } from "../../constants/ColorConstants";
import { getIcon, iconMap } from "../../utils/appIcons";
import { CalendarSocialIconContainer } from "./Style";
import { CalendarPageListType } from "./Type";
import noImage from "../../images/broken.jpg";
import WebAssetIcon from "@mui/icons-material/WebAsset";

type CalendarPageInfoType = {
    collectionDescription: CalendarPageListType;
};
const CalendarPageInfo: FC<CalendarPageInfoType> = ({ collectionDescription }) => {
    return (
        <Grid container spacing={"50px"} alignItems={"center"}>
            <Grid item xs={4} lg={4} md={"auto"}>
                <Avatar
                    style={{ height: 98, width: 98 }}
                    sizes="large"
                    src={collectionDescription.thumbnailUrl || noImage}
                />
            </Grid>
            <Grid item xs={8} lg={8} md={"auto"}>
                <div>
                    <Typography style={{ marginRight: 10, fontWeight: 500, fontSize: 24, lineHeight: 1 }}>
                        {collectionDescription.nftName}
                    </Typography>
                    <div>
                        <div style={{ display: "flex", gap: 2, alignItems: "center" }}>
                            {collectionDescription.socialLinks?.websiteUrl && (
                                <CalendarSocialIconContainer>
                                    <a href={collectionDescription.socialLinks.websiteUrl} target={"_blank"}>
                                        <WebAssetIcon style={{ fontSize: 25, color: bannerBackgroundColor }} />
                                    </a>
                                </CalendarSocialIconContainer>
                            )}
                            {collectionDescription?.socialLinks?.twitterUrl && (
                                <CalendarSocialIconContainer>
                                    <a href={collectionDescription.socialLinks.twitterUrl} target={"_blank"}>
                                        <img src={getIcon(iconMap.twitter)} height={25} width={25} />
                                    </a>
                                </CalendarSocialIconContainer>
                            )}
                            {collectionDescription?.socialLinks?.discordUrl && (
                                <CalendarSocialIconContainer>
                                    <a href={collectionDescription.socialLinks.discordUrl} target={"_blank"}>
                                        <img src={getIcon(iconMap.discord)} height={21} width={21} />
                                    </a>
                                </CalendarSocialIconContainer>
                            )}
                        </div>
                        {collectionDescription?.calendarLink && (
                            <div style={{ display: "flex", gap: 2, alignItems: "center" }}>
                                <Typography style={{ fontWeight: 500, fontSize: 14, lineHeight: 1 }}>
                                    Add to Calendar
                                </Typography>
                                <CalendarSocialIconContainer>
                                    <a href={collectionDescription.calendarLink} target={"_blank"}>
                                        <img src={getIcon(iconMap.calendar)} height={21} width={21} />
                                    </a>
                                </CalendarSocialIconContainer>
                            </div>
                        )}
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default CalendarPageInfo;
