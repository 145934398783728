import { ButtonGroup, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from "@mui/material";
import React, { FC, useState, useRef } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { OptionType } from "./CommonType";
import { StyledSplitButton } from "./CommonStyle";

type SplitButtonWrapperProps = {
    options: OptionType[];
    selectedOption: OptionType;
    setOptionChange: (option: OptionType) => void;
};

const SplitButtonWrapper: FC<SplitButtonWrapperProps> = ({ setOptionChange, options, selectedOption }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleMenuItemClick = (option: OptionType) => {
        setOptionChange(option);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <StyledSplitButton onClick={handleToggle} buttonposition="right">
                    {selectedOption.label}
                </StyledSplitButton>
                <StyledSplitButton size="small" onClick={handleToggle} buttonposition="left">
                    <ArrowDropDownIcon />
                </StyledSplitButton>
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                style={{ zIndex: 1000 }}
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            selected={option.value === selectedOption.value}
                                            onClick={() => handleMenuItemClick(option)}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default SplitButtonWrapper;
