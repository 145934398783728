import {
    filterButtonInactiveColor,
    primaryButtonColor,
    selectedOptionColor,
    SplitButtonColor,
    whiteColor
} from "../constants/ColorConstants";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import Styled from "@emotion/styled";

export const gridStyleOptions = (title: string): any => ({
    indexAxis: "y",
    elements: {
        bar: {
            borderWidth: 3,
            borderRadius: 5
        }
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
        x: {
            grid: {
                display: true,
                color: whiteColor
            },
            ticks: {
                color: whiteColor,
                font: {
                    weight: "bold"
                }
            }
        },
        y: {
            grid: {
                display: false
            },
            ticks: {
                color: whiteColor,
                font: {
                    weight: "bold"
                }
            }
        }
    },
    plugins: {
        legend: {
            position: "right",
            display: false
        },
        title: {
            display: true,
            text: title,
            color: "white",
            font: {
                size: 28
            }
        },
        datalabels: {
            align: "end",
            anchor: "end",
            padding: -2,
            color: whiteColor,
            font: {
                size: 10,
                weight: "bold"
            }
        }
    }
});

export const gridSelloutStyleOptions = (title: string): any => ({
    ...gridStyleOptions(title),
    scales: {
        ...gridStyleOptions(title).scales,
        x: {
            ...gridStyleOptions(title).scales.x,
            ticks: {
                ...gridStyleOptions(title).scales.x.ticks,
                callback: (value: string) => {
                    return `${value}%`;
                }
            }
        }
    },
    plugins: {
        ...gridStyleOptions(title).plugins,
        datalabels: {
            ...gridStyleOptions(title).plugins.datalabels,
            formatter: (value: number) => {
                return `${value}%`;
            }
        }
    }
});

export const ComponentContainer = styled.div`
    width: 100%;
`;

type FilterOptionTypes = {
    isdisabled: number;
    isselected?: number;
};

export const FilterOption = styled(Typography)<FilterOptionTypes>`
    padding: 5px 10px;
    border-radius: 5px;
    pointer-events: ${(props: FilterOptionTypes) => (props.isdisabled ? "none" : "auto")};
    background-color: ${(props) =>
        props.isselected ? selectedOptionColor : props.isdisabled ? "grey" : primaryButtonColor};
    border: 1px solid #ccc;
    cursor: pointer;
    font-size: 14px;
`;

type DurationFilterButtonProps = {
    isselected?: string;
    isdisabled?: string;
};

export const DurationFilterButton = styled(Button)<DurationFilterButtonProps>`
    border-radius: 5px;
    align-items: flex-start;
    background-color: ${(props) => (props.isselected === "true" ? primaryButtonColor : filterButtonInactiveColor)};
    font-weight: 600;
    font-size: 12px;
    box-shadow: none;
    text-transform: none;
    padding: 4px 2px;
    margin-right: 5px;
    min-width: 30px;
    color: ${whiteColor};
    color: ${(props) => props.isdisabled === "true" && "rgba(255, 255, 255, 0.3)"} !important;
    &:hover {
        color: ${whiteColor};
        box-shadow: none;
        background-color: ${(props) => (props.isselected ? primaryButtonColor : filterButtonInactiveColor)};
    }
`;

type StyledSplitButtonProps = {
    buttonposition: "right" | "left";
};
export const StyledSplitButton = styled(Button)<StyledSplitButtonProps>`
    background: inherit;
    border: 1px #8965d3 solid !important;
    color: white !important;
    border-left: ${(props) => (props.buttonposition === "right" ? 1 : 0)} !important;
    border-right: ${(props) => (props.buttonposition === "left" ? 1 : 0)} !important;

    &:hover {
        background: inherit;
    }
`;

export const GroupButtonTitle = styled(Typography)`
    margin: 0px 8px 0px 0px;
    font-weight: 400;
    line-height: 27px;
    color: ${SplitButtonColor};
    font-size: 13px;
`;

export const HighChartTheme = {
    chart: {
        backgroundColor: {
            linearGradient: { x1: 1, y1: 1, x2: 1, y2: 1 },
            stops: [
                [0, "#15202B"],
                [1, "#15202B"]
            ]
        },
        style: {
            fontFamily: "'Unica One', sans-serif"
        },
        plotBorderColor: "#606063"
    },
    title: {
        style: {
            color: "#E0E0E3",
            fontSize: "20px",
            fontWeight: 700
        }
    },
    subtitle: {
        style: {
            color: "#E0E0E3",
            textTransform: "uppercase"
        }
    },
    xAxis: {
        gridLineColor: "#ffff",
        labels: {
            style: {
                color: "#E0E0E3"
            }
        },
        lineColor: "#707073",
        minorGridLineColor: "#505053",
        tickColor: "#707073",
        title: {
            style: {
                color: "#A0A0A3"
            }
        }
    },
    yAxis: {
        gridLineColor: "#707073",
        labels: {
            style: {
                color: "#E0E0E3"
            }
        },
        lineColor: "#707073",
        minorGridLineColor: "#505053",
        tickColor: "#707073",
        tickWidth: 1,
        title: {
            style: {
                color: "#A0A0A3"
            }
        }
    },
    tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.85)",
        style: {
            color: "#F0F0F0"
        }
    },
    plotOptions: {
        series: {
            dataLabels: {
                color: "#F0F0F3",
                style: {
                    fontSize: "13px"
                }
            },
            marker: {
                lineColor: "#333"
            }
        },
        boxplot: {
            fillColor: "#505053"
        },
        candlestick: {
            lineColor: "white"
        },
        errorbar: {
            color: "white"
        }
    },
    legend: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        itemStyle: {
            color: "#E0E0E3"
        },
        itemHoverStyle: {
            color: "#FFF"
        },
        itemHiddenStyle: {
            color: "#606063"
        },
        title: {
            style: {
                color: "#C0C0C0"
            }
        }
    },
    credits: {
        style: {
            color: "#ffff"
        }
    },
    labels: {
        style: {
            color: "red"
        }
    },
    drilldown: {
        activeAxisLabelStyle: {
            color: "#F0F0F3"
        },
        activeDataLabelStyle: {
            color: "#F0F0F3"
        }
    }
};

type GridColumnTextProps = {
    color?: string;
};
export const GridColumnText = Styled(Typography)<GridColumnTextProps>`
    font-style: normal;
    font-weight: 600;
    font-size:  ${(props) => (props.fontSize ? props.fontSize + "px" : ".875rem")};
    line-height: 1.25rem;
    padding-top: 7px;
    color: ${(props) => props.color || whiteColor};
`;

export const IconText = styled.p`
    display: flex;
    align-items: center;
    gap: 2px;
    p {
        padding-top: 0px;
    }
`;