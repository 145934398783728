export const getBarHeight = (length: number, mobileView: boolean) => {
    if (length === 1) {
        return `${mobileView ? 120 : 40}px`;
    }
    if (length < 5) {
        return `${length * (mobileView ? 60 : 18)}px`;
    }

    return `${length * (mobileView ? 40 : 10)}px`;
};
