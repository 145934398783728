import { Button, Grid, Typography } from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import { pollingDelays, maxPollingAttempts } from "../../constants/appConstants";
import MakePaymentView from "../payment/MakePaymentView";
import { TargetPaymentInfo } from "../payment/Types";
import UseIntervalHook from "../payment/UseIntervalHook";
import { getTargetWalletAddress, reportPayment, verifyWalletAddress } from "../payment/Utils";

declare global {
    interface Window {
        ethereum: any;
    }
}

window.ethereum = window.ethereum || {};

const MetaMaskExperiment: FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [pollingDelay, setPollingDelay] = useState(0);
    const [walletAddress, setWalletAddress] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isUserVerified, setIsUserVerified] = useState(false);
    const [targetPaymentInfo, setTargetPaymentInfo] = useState<TargetPaymentInfo>();

    const connectWalletHandler = useCallback(() => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            setIsLoading(true);
            window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then((accounts: any) => {
                    setIsUserVerified(false);
                    if (!accounts || !accounts[0]) {
                        setWalletAddress(undefined);
                        return;
                    }
                    setWalletAddress(accounts[0]);
                })
                .catch((error: any) => {
                    setErrorMessage(error.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setErrorMessage("Please install MetaMask browser extension to interact");
        }
    }, []);

    useEffect(() => {
        connectWalletHandler();
        getTargetWalletAddress().then((data) => {
            setTargetPaymentInfo(data);
        });
    }, []);

    useEffect(() => {
        if (walletAddress) {
            verifyIfPaidUser(walletAddress);
        }
    }, [walletAddress]);

    const verifyIfPaidUser = (walletAddress: string) => {
        setIsLoading(true);
        verifyWalletAddress(walletAddress).then((statusObj) => {
            setIsLoading(false);
            setIsUserVerified(statusObj.status === "confirmed");
        });
    };

    const reportPaymentMade = (txHash: string) => {
        if (!targetPaymentInfo || !walletAddress) {
            return;
        }
        reportPayment(txHash, walletAddress, targetPaymentInfo.securedWalletAddress)
            .then(() => {
                setPollingDelay(pollingDelays);
            })
            .catch(() => {
                //todo handle error
                setPollingDelay(pollingDelays);
                setErrorMessage("Error while reporting payment");
            });
    };

    UseIntervalHook(
        () => {
            walletAddress && verifyIfPaidUser(walletAddress);
        },
        pollingDelay,
        maxPollingAttempts
    );

    // listen for account changes
    window.ethereum.on("accountsChanged", (accounts: string[]) => {
        setIsUserVerified(false);
        if (!accounts || !accounts[0]) {
            setWalletAddress(undefined);
            return;
        }
        setWalletAddress(accounts[0]);
    });
    window.ethereum.on("chainChanged", () => {
        window.location.reload();
    });

    if (!window.ethereum) {
        return <>MetaMask is not available</>;
    }
    if (!walletAddress) {
        return <>Waiting to connect to Metamask...</>;
    }
    if (isLoading) {
        return <>Loading...</>;
    }
    return (
        <Grid container spacing={3} style={{ marginTop: 150 }}>
            {!walletAddress && !isLoading && (
                <Grid item xs={12}>
                    <Button onClick={connectWalletHandler}>Login</Button>
                </Grid>
            )}
            {!isLoading && (
                <Grid item xs={12}>
                    {!isUserVerified && targetPaymentInfo && (
                        <MakePaymentView targetPaymentInfo={targetPaymentInfo} reportPaymentMade={reportPaymentMade} />
                    )}
                    {isUserVerified && <div>Paid user</div>}
                </Grid>
            )}
            <Grid item xs={12} style={{ display: "flex" }}>
                <Typography color="error" style={{ margin: "auto" }}>
                    {errorMessage}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default MetaMaskExperiment;

// const getAccountBalance = (walletAddress: string) => {
//     window.ethereum
//         .request({ method: "eth_getBalance", params: [walletAddress, "latest"] })
//         .then((balance: any) => {
//             setUserBalance(ethers.utils.formatEther(balance));
//         })
//         .catch((error: any) => {
//             setErrorMessage(error.message);
//         });
// };

// const chainChangedHandler = () => {
//     window.location.reload();
// };
