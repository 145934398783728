import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import DurationFilterView from "../../commoncomponents/durationfilter/DurationFilterView";
import { NotFinancialAdviceMessage } from "../../commoncomponents/NotFinancialAdviceMessage";
import ListComponent from "../../commoncomponents/ListComponent";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { filterByTimeOptions, getFilterByTimeOptionById } from "../../constants/filterOptions";
import { DataGridContainer } from "../neftdata/style";
import { getLiveMintColumns } from "./hooks";
import { LivemintType } from "./type";
import { getLiveMintData } from "./util";
import UseIntervalHook from "../payment/UseIntervalHook";
import { LIVE_MINT_POLLING_DELAY, MAX_POLLING_COUNT } from "../../constants/appConstants";
import { moveToCollectionId } from "../../utils/siteUtil";

const LiveMintView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [liveMintRows, setLiveMintRows] = useState<LivemintType[]>([]);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const [selectedFilter, setSelectedFilter] = useState<string>("1h");

    useEffect(() => {
        selectedFilter !== "" && fetchLiveMint(true);
    }, [selectedFilter]);

    UseIntervalHook(
        () => {
            fetchLiveMint(false);
        },
        ["30s", "5m", "30m"].includes(selectedFilter) ? LIVE_MINT_POLLING_DELAY : 0,
        MAX_POLLING_COUNT
    );

    const fetchLiveMint = useCallback(
        (showLoadIndicator) => {
            showLoadIndicator && setIsLoading(true);
            getLiveMintData(getFilterByTimeOptionById(filterByTimeOptions, selectedFilter))
                .then((data) => {
                    setLiveMintRows(data);
                })
                .catch(() => setLiveMintRows([]))
                .finally(() => setIsLoading(false));
        },
        [selectedFilter]
    );

    return (
        <DataGridContainer style={{ marginTop: 30 }}>
            <Grid container justifyContent="center">
                <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                    <DurationFilterView
                        selectedFilter={selectedFilter}
                        filterOptions={filterByTimeOptions}
                        setSelectedFilter={(selectedOption: string) => setSelectedFilter(selectedOption)}
                    />
                    <NotFinancialAdviceMessage />
                    <LoadingComponent isLoading={isLoading}>
                        <ListComponent
                            columns={getLiveMintColumns(true, mobileView)}
                            isDarkMode={true}
                            compact={false}
                            items={liveMintRows}
                            initialSortKey={"numTrans"}
                            initialSortDirection={"DESC"}
                            onRowClick={(rows, columnName) => {
                                if (columnName !== "collectionName") {
                                    moveToCollectionId(rows.contractId);
                                }
                            }}
                        />
                    </LoadingComponent>
                </Grid>
            </Grid>
        </DataGridContainer>
    );
};

export default LiveMintView;
