import { useMemo } from "react";
import { HighChartTheme } from "../../commoncomponents/CommonStyle";
import {
    CollectionBarColor,
    CollectionScatterBuyColor,
    CollectionScatterColor,
    CollectionScatterMintColor,
    CollectionScatterSellColor
} from "../../constants/ColorConstants";
import { ScatterChartData, TradeChartData } from "./Types";

export const useTradeChartOptions = (
    tradeChartData: TradeChartData | undefined,
    intervalInMins: any,
    spanInMins: any
) =>
    useMemo(() => {
        if (!tradeChartData) {
            return;
        }
        return {
            chart: {
                type: "arearange",
                zoomType: "x",
                ...HighChartTheme.chart
            },
            title: {
                text: "Collection Floor and Average Price Chart",
                ...HighChartTheme.title
            },
            xAxis: {
                ...HighChartTheme.xAxis,
                type: "datetime"
            },
            legend: {
                enabled: false
            },
            yAxis: {
                ...HighChartTheme.yAxis
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    data: tradeChartData.data
                }
            ]
        };
    }, [tradeChartData, intervalInMins, spanInMins]);

export const useChartOptions = (
    scatterChartData: ScatterChartData | undefined,
    barChartData: any,
    intervalInMins: any,
    spanInMins: any
) =>
    useMemo(() => {
        if (!scatterChartData || !barChartData) {
            return null;
        }
        return {
            chart: {
                type: "scatter",
                ...HighChartTheme.chart
            },
            title: {
                text: "",
                ...HighChartTheme.title
            },
            legend: {
                ...HighChartTheme.legend
            },
            credits: {
                enabled: false
            },
            yAxis: [
                {
                    ...HighChartTheme.yAxis,
                    title: {
                        enabled: true,
                        text: "PRICE IN ETH"
                    }
                },
                {
                    ...HighChartTheme.yAxis,
                    title: {
                        enabled: true,
                        text: "Volume in ETH"
                    },
                    opposite: true
                }
            ],
            xAxis: {
                ...HighChartTheme.xAxis,
                type: "datetime",
                dateTimeLabelFormats: {
                    month: "%e. %b",
                    year: "%b"
                }
            },
            plotOptions: {
                ...HighChartTheme.plotOptions,
                scatter: {
                    tooltip: {
                        headerFormat: "<b>{series.name}</b><br>",
                        pointFormat: `Time=<b>{point.x:%d-%m-%Y, %H:%M}</b><br/>ETH=<b>{point.y}</b><br/>`
                    }
                },
                series: {
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    }
                }
            },
            series: [
                {
                    type: "column",
                    name: "Volume",
                    yAxis: 1,
                    color: CollectionBarColor,
                    borderWidth: 0,
                    showInLegend: true,
                    legendColor: "#ffff",
                    data: barChartData
                },
                {
                    name: "Sales",
                    yAxis: 0,
                    color: CollectionScatterColor,
                    showInLegend: true,
                    data: scatterChartData.sale
                },
                {
                    name: "Mints",
                    yAxis: 0,
                    color: CollectionScatterMintColor,
                    showInLegend: true,
                    data: scatterChartData.mint
                },
                {
                    name: "Whale Buys",
                    yAxis: 0,
                    color: CollectionScatterBuyColor,
                    showInLegend: true,
                    data: scatterChartData.buy
                },
                {
                    name: "Whale Sells",
                    yAxis: 0,
                    color: CollectionScatterSellColor,
                    showInLegend: true,
                    data: scatterChartData.sell
                }
            ]
        };
    }, [scatterChartData, barChartData, intervalInMins, spanInMins]);
