import { methodForScoring, nftCheatSheetUrl, resultsToDate, discordUrl, mediumUrl, twitterUrl } from "./urlConstants";

export const menuData = [
    {
        name: "wen mint",
        url: "/upcoming"
    },
    {
        name: "Launched",
        url: "/launched"
    },
    {
        name: "NFT Cheat Sheet",
        url: "/nftcheatsheet"
    },
    {
        name: "Notifications",
        url: "/notifications"
    },
    {
        name: "Market",
        url: "/market"
    },
    {
        name: "Analysis",
        url: "/analysis"
    },
    {
        name: "Submit Project",
        url: "/submitproject"
    }
];

export type footerMenuDataType = {
    name: string;
    url: string;
};
export const footerMenuData: footerMenuDataType[] = [
    {
        name: "NFT Cheat Sheet",
        url: nftCheatSheetUrl
    },
    {
        name: "How We Score",
        url: methodForScoring
    }
];

export type footerSocialMediaDataType = {
    url: string;
    imageUrl: string;
    alt: string;
    height: number;
};
export const footerSocialMediaData: footerSocialMediaDataType[] = [
    {
        url: twitterUrl,
        imageUrl: "/icons/twitter.svg",
        alt: "twitter",
        height: 27
    },
    {
        url: discordUrl,
        imageUrl: "/icons/discord.svg",
        alt: "discord",
        height: 28
    },
    {
        url: mediumUrl,
        imageUrl: "/icons/medium4x.png",
        alt: "medium icon",
        height: 20
    }
];

export const footerMenuListData = {
    product: {
        title: "Product",
        subItem: [
            {
                title: "Calendar",
                url: "https://guide.cryptoscores.io/wen-mint/calendar"
            },
            {
                title: "Live Mint",
                url: "https://guide.cryptoscores.io/live-blockchain-insights/live-mint"
            },
            {
                title: "Whales",
                url: "https://guide.cryptoscores.io/live-blockchain-insights/whales"
            },
            {
                title: "Live Sale",
                url: "https://guide.cryptoscores.io/live-blockchain-insights/live-sale"
            }
        ]
    },
    resources: {
        title: "Resources",
        subItem: [
            {
                title: "Subscribe",
                url: "https://guide.cryptoscores.io/subscribe"
            },
            {
                title: "How We Score",
                url: "https://guide.cryptoscores.io/wen-mint/score"
            },
            {
                title: "Scores Results",
                url: "https://www.cryptoscores.io/scoreresults"
            },
            {
                title: "Score Results DB",
                url: "https://www.cryptoscores.io/minted"
            },
            {
                title: "Partnerships",
                url: "https://guide.cryptoscores.io/collab/partnerships"
            }
        ]
    },
    community: {
        title: "Community",
        subItem: [
            {
                title: "Hunters",
                url: "https://www.cryptoscores.io/hunters"
            },
            {
                title: "Submit Project",
                url: "https://docs.google.com/forms/d/e/1FAIpQLSfiPVGYKv8qL3D2GEodtIQZVVApCndeVg5HHP1vfTz_PmjP4A/viewform"
            },
            {
                title: "NFT Cheat Sheet",
                url: "https://guide.cryptoscores.io/cheat-sheet/nft-cheat-sheet"
            }
        ]
    }
};
