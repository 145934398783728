import { useMemo } from "react";
import { HeaderRendererProps } from "react-data-grid";
import HeaderTooltipView from "../../commoncomponents/HeaderTooltip";
import { renderValue } from "../common/grid/Utils";
import { renderColumn } from "../dashboard/hooks";
import ProjectNameView from "../neftdata/ProjectNameView";

export const getLiveMintColumns = (isDarkMode: boolean, isMobileView: boolean) =>
    useMemo(
        () => [
            {
                key: "collectionName",
                name: "Collection",
                frozen: true,
                width: isMobileView ? "50%" : "25%",
                formatter: (rows: any) => (
                    <ProjectNameView
                        openWebsiteOnClick
                        isDarkMode={isDarkMode}
                        projectName={rows.row.collectionName}
                        socialLinks={rows.row.socialLinks}
                        collectId={rows.row.contractId}
                    />
                ),
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Collection"
                        isMobileView={isMobileView}
                        headerProps={props}
                        hideTooltip
                        isNotCenter
                    />
                ),
                sortable: true
            },
            {
                key: "minSale",
                name: "MIN Sale",
                frozen: isMobileView ? false : true,
                width: "13%",
                sortable: true,
                sortDescendingFirst: true,
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Mint Price"
                        description="Our estimated Mint Price"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) => renderValue(`${rows.row.minSale}${rows.row.minSale ? " Ξ" : ""}`, isDarkMode)
            },
            {
                key: "avgTokens",
                name: "Avg Mints",
                width: "13%",
                sortable: true,
                sortDescendingFirst: true,
                formatter: (rows: any) => renderValue(rows.row.avgTokens, isDarkMode),
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Avg Mints"
                        description="Average number of NFTs minted per transaction"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                )
            },
            {
                key: "whalesMint",
                name: "Whales",
                width: "10%",
                sortable: true,
                formatter: (rows: any) => renderColumn(rows.row.whalesMint, rows.row.whalesMint > 0),
                sortDescendingFirst: true,
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Whales"
                        description="Number of Whales Minting this NFT"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                )
            },
            {
                key: "whalesMintCount",
                name: "Whales Mints",
                width: "12%",
                sortable: true,
                formatter: (rows: any) => renderColumn(rows.row.whalesMintCount, rows.row.whalesMintCount > 0),
                sortDescendingFirst: true,
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Whales Mints"
                        description="Total number of NFTs minted by Whales"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                )
            },
            {
                key: "percUnique",
                name: "Unique %",
                width: "10%",
                sortable: true,
                sortDescendingFirst: true,
                formatter: (rows: any) => renderValue(`${rows.row.percUnique}%`, isDarkMode),
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Unique %"
                        description="Unique wallets that minted an NFT"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                )
            },
            {
                key: "totVol",
                name: "Volume",
                sortable: true,
                sortDescendingFirst: true,
                width: "10%",
                formatter: (rows: any) =>
                    renderValue(
                        `${rows.row.totVol ? `${rows.row.totVol.toFixed(rows.row.totVol >= 1 ? 0 : 2)} Ξ` : ""}`,
                        isDarkMode
                    )
            },
            {
                key: "numTrans",
                name: "Sales",
                sortable: true,
                sortDescendingFirst: true,
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Mints"
                        description="Total NFTs minted during the interval"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) => renderValue(rows.row.numTrans, isDarkMode)
            }
        ],
        [isDarkMode, isMobileView]
    );
