export const moveToCollectionId = (collectId: string) => {
    window.open(`${window.location.origin}/collection/${collectId}`, "_blank");
};

export const moveToCalendar = (nftName: string, nftId: string) => {
    window.open(`${window.location.origin}/calendar/calendar/${nftName.split(" ").join("")}/${nftId}`, "_blank");
};

export const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
