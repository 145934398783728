import { useMemo } from "react";
import { HeaderRendererProps } from "react-data-grid";
import { GridColumnText } from "../../commoncomponents/CommonStyle";
import HeaderTooltipView from "../../commoncomponents/HeaderTooltip";
import ProjectNameView from "../neftdata/ProjectNameView";
import { successColor, errorColor } from "../../constants/ColorConstants";
import { renderValue } from "../common/grid/Utils";

export const renderColumn = (text: string, isGreat: boolean) => {
    if (text !== "") {
        return <GridColumnText color={isGreat ? successColor : errorColor}>{text}</GridColumnText>;
    }
    return "";
};

export const getDashboardColumns = (isDarkMode: boolean, isMobileView: boolean) =>
    useMemo(
        () => [
            {
                key: "rank",
                name: "Rank",
                width: 1,
                sortable: true,
                frozen: true,
                formatter: (rows: any) => (
                    <div style={{ textAlign: "center" }}>{renderValue(`${rows.row.rank}`, isDarkMode)}</div>
                )
            },
            {
                key: "collectionName",
                name: "Collection",
                frozen: true,
                width: isMobileView ? "50%" : "30%",
                formatter: (rows: any) => (
                    <ProjectNameView
                        openWebsiteOnClick
                        isDarkMode={isDarkMode}
                        projectName={rows.row.collectionName}
                        socialLinks={rows.row.socialLinks}
                        collectId={rows.row.contract}
                    />
                ),
                sortable: true,
                sortDescendingFirst: true
            },
            {
                key: "totVolNow",
                name: "Volume",
                width: "10%",
                sortable: true,
                formatter: (rows: any) => renderValue(`${rows.row.totVolNow} Ξ`, isDarkMode)
            },
            {
                key: "totVolDelta",
                width: "10%",
                sortable: true,
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Volume Δ"
                        description="Change in Volume compared to previous interval."
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) =>
                    renderColumn(`${rows.row.totVolDelta.toString() ? `${rows.row.totVolDelta}%` : ""}`, rows.row.totVolDelta > 0)
            },
            {
                key: "medianSaleNow",
                width: "10%",
                sortable: true,
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Price"
                        description="We use a median price calculation to give a fairer calculation of the middle price sold for the collection during the interval."
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) => renderValue(`${rows.row.medianSaleNow} Ξ`, isDarkMode)
            },
            {
                key: "medianSaleDelta",
                width: "10%",
                sortable: true,
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Price Δ"
                        description="Change in price compared to previous interval."
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) =>
                    renderColumn(
                        `${rows.row.medianSaleDelta.toString() ? `${rows.row.medianSaleDelta}%` : ""}`,
                        rows.row.medianSaleDelta > 0
                    )
            },
            {
                key: "numTransNow",
                width: "10%",
                sortable: true,
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Sales"
                        description="The number of NFTs sold during the interval."
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) => renderValue(rows.row.numTransNow, isDarkMode)
            },
            {
                key: "numTransDelta",
                sortable: true,
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Sale Δ"
                        description="Change in number of NFTs sold compared to previous interval."
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) =>
                    renderColumn(
                        `${rows.row.numTransDelta}${rows.row.numTransDelta ? "%" : ""}`,
                        rows.row.numTransDelta > 0
                    )
            }
        ],
        [isDarkMode, isMobileView]
    );
