import { Client } from "../../commoncomponents/Base/api";
import { getAllLiveSaleDataApiUrl, getLiveSaleDataApiUrl } from "../../constants/apiConstants";
import { LiveSaleType } from "./type";

const trasformData = (data: any): LiveSaleType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        collectionName: item.collectionName || "",
        contractId: item.contractId || "",
        percUnique: (item.percUnique * 100).toFixed(0) || "",
        sweepNfts: parseInt(item.sweepNfts),
        whalesBuysCount: parseInt(item.whalesBuysCount),
        vwap: parseFloat(item?.vwap) || "",
        medianSale: parseFloat(item.medianSale) || "",
        minSale: parseFloat(item.minSale) || "",
        maxSale: parseFloat(item.maxSale) || "",
        avgSale: parseFloat(item.avgSale) || "",
        totVol: parseFloat(item.totVol) || "",
        numTrans: parseInt(item.numTrans) || "",
        createdDate: item.createdDate || "",
        totalSupply: item.totalSupply || "",
        schemaName: item.schemaName || "",
        twitterUsername: item.twitterUsername || "",
        payoutAddress: item.payoutAddress || "",
        etherscanUrl: item.etherscanUrl || "",
        socialLinks: {
            websiteUrl: item.websiteUrl || "",
            discordUrl: item.discordUrl || "",
            twitterUrl: item.twitterUrl || "",
            marketAffUrl: item.marketAffUrl || "",
            imageUrl: item.imageUrl || "",
            openseaSlug: item.openseaSlug || "",
            etherscanUrl: item.etherscanUrl || ""
        }
    }));
};

export const getLiveSaleData = (timeFilter: [number, string] | undefined): Promise<LiveSaleType[]> =>
    Client.getInstance()
        .getData(timeFilter ? getLiveSaleDataApiUrl(timeFilter[0], timeFilter[1]) : getAllLiveSaleDataApiUrl(), true)
        .then((res) => Promise.resolve(trasformData(res.data)))
        .catch((err) => Promise.reject(err));
