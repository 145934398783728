import { LiveWhaleTxTypeColor } from "../../constants/ColorConstants";
import { getIcon, iconMap } from "../../utils/appIcons";
import { dateConverts } from "../../utils/getUtcTime";
import { renderValue } from "../common/grid/Utils";
import ProjectNameView from "../neftdata/ProjectNameView";
import { SocialIconContainer } from "../neftdata/style";
import { TxTimeStamp } from "./style";
import { TxScoreText } from "../walletfeed/style";

const getTxTypeColor = (txType: string) => {
    if (txType === "Mint") {
        return LiveWhaleTxTypeColor.mint;
    }
    if (txType === "Buy") {
        return LiveWhaleTxTypeColor.buy;
    }
    if (txType === "Sale") {
        return LiveWhaleTxTypeColor.sale;
    }

    return "inherit";
};

const renderTxValue = (value: string) => {
    if (["Mint", "Buy", "Sale"].includes(value)) {
        return <TxScoreText backgroundcolor={getTxTypeColor(value)}>{value}</TxScoreText>;
    }
    if (value === "Air Drop") {
        return (
            <TxScoreText style={{ display: "flex", padding: 0 }}>
                <img src={getIcon(iconMap.alarm)} height={15} width={15} />
                Airdrop
                <img src={getIcon(iconMap.alarm)} height={15} width={15} />
            </TxScoreText>
        );
    }
    return <TxScoreText style={{ padding: 0 }}>{value}</TxScoreText>;
};

export const getWalletFeedColumns = (isDarkMode: boolean, isMobileView: boolean) => [
    {
        key: "collectionName",
        name: "Collection",
        frozen: true,
        width: isMobileView ? "50%" : "60%",
        formatter: (rows: any) => (
            <ProjectNameView
                openWebsiteOnClick
                isDarkMode={isDarkMode}
                projectName={rows.row.collectionName}
                socialLinks={rows.row.socialLinks}
                collectId={rows.row.contract}
            />
        )
    },
    {
        key: "txType",
        name: "Tx",
        width: "20%",
        formatter: (rows: any) => (
            <>
                {renderTxValue(rows.row.txType)}
                <TxTimeStamp>{dateConverts(rows.row.timeStamp)}</TxTimeStamp>
            </>
        ),
        sortable: true
    },
    {
        key: "value",
        name: "Value",
        sortable: true,
        sortDescendingFirst: true,
        formatter: (rows: any) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
                {renderValue(rows.row.value, isDarkMode)}
                {rows.row.socialLinks.tokenMarketAffUrl && (
                    <div style={{ lineHeight: "15px", marginTop: "5px", display: "flex" }}>
                        <SocialIconContainer>
                            <a href={rows.row.socialLinks.tokenMarketAffUrl} target={"_blank"}>
                                <img src={getIcon(iconMap.opensea)} height={20} width={20} />
                            </a>
                        </SocialIconContainer>
                    </div>
                )}
            </div>
        )
    }
];
