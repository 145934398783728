export const whiteColor = "#ffff";
export const blackColor = "#2a2a2a";
export const menuLinkColor = "rgba(37,99,235,1)";
export const menuLinkColorInactive = "rgb(125 127 130)";
export const menuLinkBgColor = "rgba(239,246,255,1)";
export const scrollBarColor = `#d2d1d1`;
export const cellBorderColor = `#E0E7F1`;
export const headerTitleColor = `#27303F`;
export const primaryColor = `rgba(17,24,39,1)`;
export const captionColor = `rgba(107,114,128,1)`;
export const headerFooterDarkModeColor = "#212121";
export const gridRowsDarkModeColor = "#192734";
export const hignScoreGreenColor = "#2A9D8F";
export const graphHighLineColor = "#2A9C8E";
export const graphMediumLineColor = "#7EC4BC";
export const graphLowLineColor = "#E8C36A";
export const graphVeryLowLineColor = "#E76F51";
export const selectedOptionColor = "#2B2015";
export const messageTextColor = "#9ca3af";
export const clickHereTextColor = "#3b82f6";
export const range010 = "#3A3E47";
export const range1020 = "#384B51";
export const range2030 = "#374F54";
export const range3040 = "#355C5D";
export const range4050 = "#336766";
export const range5060 = "#317470";
export const range6070 = "#307974";
export const range7080 = "#2D8C82";
export const range8090 = "#2D8C82";
export const range90100 = "#2A9D8F";
export const blinkDotColor = `#FF5251`;
export const bannerBackgroundColor = `#074678`;
export const guideIconColor = `#FFB900`;
export const CollectionBarColor = `#1e2e3e`;
export const CollectionScatterColor = `rgba(211, 174, 101, 0.2)`;
export const CollectionScatterMintColor = `rgba(97,188,209,0.5)`;
export const CollectionScatterBuyColor = `rgba(0, 255, 0, 0.2)`;
export const CollectionScatterSellColor = `rgba(255, 0, 0, 0.2)`;
export const barRestColor = `#00308F`;
export const datagridHoverColor = `#34455d`;

export const LiveWhaleTxTypeColor = {
    mint: `#118DF0`,
    buy: `#2A9D8F`,
    sale: `#E76F51`
};

export const globalFonts = [
    "Inter var",
    "ui-sans-serif",
    "system-ui",
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "Noto Sans",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji"
];
export const headerDarkModeColor = "#3D3D3D";
export const headerLightModeColor = "rgba(249,250,251,1)";
export const tableRowLightColor = "rgba(249,250,251,1)";
export const tableRowDarkColor = "#292626";
export const primaryButtonColor = "#118DF0";
export const primaryLightBackgroundColor = "#F9FAFB";
export const primaryDarkBackgroundColor = "#15202B";
export const secondaryDarkBackgroundColor = "#192734";
export const filterButtonColor = "rgba(37,36,93,1)";
export const filterButtonInactiveColor = "#074678";
export const successColor = "#67CB45";
export const errorColor = "#FF5251";
export const SplitButtonColor = "rgb(134, 134, 160)";
export const negativeTagColor = `#ff00001f`;
export const positiveTagColor = `#0ee10e5e`;

export const WalletTxTypeColor = {
    mint: `#118DF0`,
    buy: `#2A9D8F`,
    sale: `#E76F51`
};

export const pricesSignalColor = {
    red: "#DD2E44",
    green: "#78B159",
    yellow: "#FDCB58"
};
