import React, { FC } from "react";
import { ColumnHeader, HeaderTooltip } from "../components/mintradar/Style";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { HeaderRendererProps, SortableHeaderCell } from "react-data-grid";

type HeaderTooltipProps = {
    columnTitle: string;
    description?: string;
    isMobileView?: boolean;
    headerProps: HeaderRendererProps<any>;
    hideTooltip?: boolean;
    isNotCenter?: boolean;
};
const HeaderTooltipView: FC<HeaderTooltipProps> = ({
    columnTitle,
    description,
    isMobileView,
    headerProps,
    hideTooltip,
    isNotCenter = false
}) => (
    <SortableHeaderCell {...headerProps}>
        <ColumnHeader iscenter={!isNotCenter}>
            <span style={{ fontSize: 10 }}>{columnTitle}</span>
            {!isMobileView && !hideTooltip && (
                <HeaderTooltip title={description || ""}>
                    <InfoOutlinedIcon style={{ width: 14 }} />
                </HeaderTooltip>
            )}
        </ColumnHeader>
    </SortableHeaderCell>
);

export default HeaderTooltipView;
