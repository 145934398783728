import { Client } from "../../commoncomponents/Base/api";
import { getWalletSearchFeed } from "../../constants/apiConstants";
import { walletFilters } from "../../constants/appConstants";
import { WalletFeedType } from "./type";

const transformData = (data: any) => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        ...item,
        collectionName: item.collectionName || "",
        txType: item.txType || "",
        value: parseFloat(item.value).toFixed(3) || "",
        socialLinks: {
            imageUrl: item.imageUrl || "",
            websiteUrl: item.websiteUrl || "",
            discordUrl: item.discordUrl || "",
            twitterUrl: item.twitterUrl || "",
            marketAffUrl: item.marketAffUrl || "",
            tokenMarketAffUrl: item.tokenMarketAffUrl || "",
            openseaSlug: item.openseaSlug || "",
            etherscanUrl: item.etherscanUrl || ""
        }
    }));
};

export const getWalletFeedData = (
    walletAddress: string,
    timeFilter: [number, string] | undefined
): Promise<WalletFeedType[]> => {
    if (timeFilter) {
        return Client.getInstance()
            .getData(getWalletSearchFeed(walletAddress, timeFilter[0]), true)
            .then((res) => Promise.resolve(transformData(res.data)))
            .catch((err) => Promise.reject(err));
    }
    return Promise.reject([]);
};

export const onFilterWallet = (filters: string[], rows: WalletFeedType[]) => {
    if (filters.length === 0) {
        return rows;
    }
    return rows.filter((item) => filters.includes(item.txType.toLowerCase()));
};
