import { Grid, Tabs } from "@mui/material";
import React, { FC } from "react";
import { MINT_ALL, MINT_NOT_SOLD } from "../../constants/appConstants";
import { MenuButton } from "../Header/headerStyled";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import InsertChartOutlinedTwoToneIcon from "@mui/icons-material/InsertChartOutlinedTwoTone";

type MintTabProps = {
    selectedTab: string;
    setSelectedTab: (tab: string) => void;
};
const MintTab: FC<MintTabProps> = ({ selectedTab, setSelectedTab }) => {
    const handleChange = (_: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };
    return (
        <Grid container style={{ marginBottom: 30 }}>
            <Tabs style={{ height: 60 }} value={selectedTab} indicatorColor="primary" onChange={handleChange}>
                <MenuButton
                    icon={<CalendarTodayIcon fontSize={"small"} />}
                    iconPosition={"start"}
                    value={MINT_ALL}
                    label="All"
                />
                <MenuButton
                    iconPosition={"start"}
                    icon={<InsertChartOutlinedTwoToneIcon fontSize={"small"} />}
                    value={MINT_NOT_SOLD}
                    label="Not Sold Out"
                />
            </Tabs>
        </Grid>
    );
};

export default MintTab;
