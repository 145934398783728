import {
    graphHighLineColor,
    graphLowLineColor,
    graphMediumLineColor,
    graphVeryLowLineColor,
    whiteColor
} from "../../constants/ColorConstants";
import { neftDataGridType } from "../neftdata/type";

export type GraphNeftDataType = {
    labels: string[];
    datasets: graphDataType[];
};

type graphDataType = {
    label: string;
    data: string[];
    backgroundColor: string[];
    borderColor: string;
    color: string;
    barThickness: 20;
};

const getBarColor = (value: number) => {
    if (value >= 500 && value < 100000) {
        return graphHighLineColor;
    }
    if (value >= 300 && value < 500) {
        return graphMediumLineColor;
    }
    if (value >= 200 && value < 300) {
        return graphLowLineColor;
    }
    return graphVeryLowLineColor;
};

export const tranformNeftDataList = (res: neftDataGridType[]): GraphNeftDataType => {
    const label: string[] = [];
    const dataSet: any = {
        label: "",
        data: [],
        backgroundColor: [],
        borderColor: "transparent",
        color: whiteColor,
        barThickness: 20
    };
    const sortData = res.sort((a, b) => b.score - a.score);
    const filteredData = sortData.filter((item) => item.score);
    filteredData.map((item) => {
        label.push(`${item.project}(${item.blockChain})`);
        dataSet.data.push(item.score);
        dataSet.backgroundColor.push(getBarColor(item.score));
    });
    return {
        labels: label,
        datasets: [dataSet]
    };
};
