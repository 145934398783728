import React, { FC, useState, useEffect, useContext } from "react";
import { Client } from "../../commoncomponents/Base/api";
import { getSponsordBannerApiUrl } from "../../constants/apiConstants";
import { AuthContext } from "../../context/AuthenticationProvider";
import { BannerImage } from "./Styled";
import { SponsorBanner } from "./types";

const MainSponsorBanner: FC = () => {
    const { isPaidUser } = useContext(AuthContext);
    const [sponsorBannerData, setSponsorBannerData] = useState<SponsorBanner>();
    useEffect(() => {
        Client.getInstance()
            .getData(getSponsordBannerApiUrl())
            .then(({ data }) => {
                if (data[0]) {
                    setSponsorBannerData(data[0]);
                }
            });
    }, []);

    if (!sponsorBannerData || isPaidUser) {
        return null;
    }

    return (
        <a href={sponsorBannerData.linkUrl} target="_blank" style={{ textDecoration: "none" }}>
            <BannerImage src={sponsorBannerData.imageUrl} alt={sponsorBannerData.imageUrl} style={{ maxHeight: 50 }} />
        </a>
    );
};

export default MainSponsorBanner;
