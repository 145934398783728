import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { DataGridContainer } from "../neftdata/style";
import LiveWhaleUnPaid from "../../images/whales2_fade.jpg";
import NonPaidUserWrapper from "../common/NonPaidUserWrapper";
import { BUYSELL } from "../../constants/appConstants";
import LiveWhaleTabs from "./LiveWhaleTabs";
import LiveWhaleBuySell from "./LiveWhaleBuySell";
import LiveDuoFeed from "./LiveDuoFeed";
import { useHistory, useParams } from "react-router";

const LiveWhaleView = () => {
    const params: any = useParams();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const [selectedTab, setSelectedTab] = useState<string>(BUYSELL);
    const history = useHistory();

    useEffect(() => {
        setSelectedTab(params.tab);
    }, [params]);

    const renderSelectedView = useCallback(() => {
        if (selectedTab === BUYSELL) {
            return <LiveWhaleBuySell />;
        }
        return <LiveDuoFeed />;
    }, [selectedTab]);

    return (
        <DataGridContainer>
            <Grid container justifyContent="center">
                <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                    <LiveWhaleTabs
                        selectedTab={selectedTab}
                        setSelectedTab={(tab) => history.push(`/livewhale/${tab}`)}
                    />
                    <NonPaidUserWrapper imageSrc={LiveWhaleUnPaid}>{renderSelectedView()}</NonPaidUserWrapper>
                </Grid>
            </Grid>
        </DataGridContainer>
    );
};

export default LiveWhaleView;
