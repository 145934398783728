import { Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { FC, useContext, useState } from "react";
import ListComponent from "../../commoncomponents/ListComponent";
import { messageTextColor, clickHereTextColor } from "../../constants/ColorConstants";
import { submitProject } from "../../constants/urlConstants";
import { AuthContext } from "../../context/AuthenticationProvider";
import NonPaidUserWrapper from "../common/NonPaidUserWrapper";
import { FilterButton } from "../neftdata/style";
import { getColumns } from "./hooks";
import { MintedNft } from "./types";
import { includeInFilteredItems, mintedNftFilters, mintedNftFiltersData, onFilter } from "./utils";
import MintedUnPaid from "../../images/minted_fade.png";

type MintedNftAllListProps = {
    rows: MintedNft[];
};

const MintedNftAllList: FC<MintedNftAllListProps> = ({ rows }) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const [filteredRows, setFilteredRows] = useState<MintedNft[]>([]);
    const [searchItem, setSearchItem] = useState("");
    const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
    const { isPaidUser } = useContext(AuthContext);

    const getFilteredItems = (): any => {
        const data = selectedFilter.length === 0 ? rows : filteredRows;
        const filteredItems =
            searchItem === ""
                ? data
                : data.filter((item: MintedNft) => includeInFilteredItems(searchItem.toLowerCase(), item));
        return filteredItems;
    };

    const onFilterClick = (value: string) => {
        let filteredValues = [];
        if (
            value === mintedNftFilters.other_chains &&
            (selectedFilter.includes(mintedNftFilters.only_eth) || selectedFilter.includes(mintedNftFilters.only_sol))
        ) {
            filteredValues = selectedFilter.filter(
                (item) => item !== mintedNftFilters.only_eth && item !== mintedNftFilters.only_sol
            );
            setSelectedFilter([...filteredValues, value]);
            setFilteredRows(onFilter([...filteredValues, value], rows));
            return;
        }
        if (
            value === mintedNftFilters.only_eth &&
            (selectedFilter.includes(mintedNftFilters.only_sol) ||
                selectedFilter.includes(mintedNftFilters.other_chains))
        ) {
            filteredValues = selectedFilter.filter(
                (item) => item !== mintedNftFilters.only_sol && item !== mintedNftFilters.other_chains
            );
            setSelectedFilter([...filteredValues, value]);
            setFilteredRows(onFilter([...filteredValues, value], rows));
            return;
        }

        if (
            value === mintedNftFilters.only_sol &&
            (selectedFilter.includes(mintedNftFilters.only_eth) ||
                selectedFilter.includes(mintedNftFilters.other_chains))
        ) {
            filteredValues = selectedFilter.filter(
                (item) => item !== mintedNftFilters.only_eth && item !== mintedNftFilters.other_chains
            );
            setSelectedFilter([...filteredValues, value]);
            setFilteredRows(onFilter([...filteredValues, value], rows));
            return;
        }

        if (selectedFilter.includes(value)) {
            filteredValues = selectedFilter.filter((item) => item !== value);
            setSelectedFilter(filteredValues);
            setFilteredRows(onFilter(filteredValues, rows));
            return;
        }
        setSelectedFilter((prev) => [...prev, value]);
        setFilteredRows(onFilter([...selectedFilter, value], rows));
    };
    return (
        <>
            <Grid
                container
                gap={2}
                justifyContent={"flex-start"}
                alignItems={"center"}
                style={{ margin: "20px 0px", pointerEvents: !isPaidUser ? "none" : "all" }}
            >
                <Grid item>
                    <TextField
                        variant={"outlined"}
                        placeholder={"Search Collection"}
                        size={"small"}
                        value={searchItem}
                        onChange={(event) => setSearchItem(event.target.value)}
                        fullWidth={mobileView}
                    />
                </Grid>
                {mintedNftFiltersData.map((filter) => (
                    <Grid item key={filter.value}>
                        <FilterButton
                            selected={selectedFilter.includes(filter.value) ? 1 : 0}
                            onClick={() => onFilterClick(filter.value)}
                        >
                            {filter.label}
                        </FilterButton>
                    </Grid>
                ))}
            </Grid>

            <Typography color={messageTextColor} style={{ marginTop: -15, fontStyle: "italic" }}>
                Submit projects{" "}
                <a target={"_blank"} href={submitProject} style={{ color: clickHereTextColor }}>
                    here
                </a>
            </Typography>
            <Typography color={messageTextColor} style={{ marginBottom: 5, fontStyle: "italic", fontSize: 12 }}>
                We don't guarantee or endorse any links, or projects. This is not financial advice.
            </Typography>
            <NonPaidUserWrapper imageSrc={MintedUnPaid}>
                <ListComponent
                    columns={getColumns(true, mobileView)}
                    isDarkMode={true}
                    compact={false}
                    items={getFilteredItems()}
                    initialSortKey={"score"}
                    initialSortDirection={"DESC"}
                />
            </NonPaidUserWrapper>
        </>
    );
};

export default MintedNftAllList;
