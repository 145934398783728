import { Client } from "../../commoncomponents/Base/api";
import { getMintRadarDataApiUrl } from "../../constants/apiConstants";
import { MintRadarType } from "./types";

const trasformData = (data: any): MintRadarType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        ...item,
        collectionName: item.collectionName || "",
        heatScore: parseFloat(item.heatScore) || "",
        profitScore: parseFloat(`${item.profitScore}`),
        mintCount: parseFloat(item.mintCount) || "",
        mintPrice: parseFloat(item.mintPrice) || "",
        countMktSale: parseFloat(item.countMktSale) || "",
        mktMinSale: parseFloat(item.mktMinSale) || "",
        profitMin: Math.round(parseFloat(item.profitMin) * 100),
        socialLinks: {
            imageUrl: item.imageUrl || "",
            websiteUrl: item.websiteUrl || "",
            discordUrl: item.discordUrl || "",
            twitterUrl: item.twitterUrl || "",
            marketAffUrl: item.marketAffUrl || "",
            openseaSlug: item.openseaSlug || "",
            etherscanUrl: item.etherscanUrl || ""
        }
    }));
};

export const getMintRadarData = (
    mintfilter: [number, string] | undefined,
    saleFilter: [number, string] | undefined
): Promise<MintRadarType[]> => {
    if (mintfilter && saleFilter) {
        return Client.getInstance()
            .getData(getMintRadarDataApiUrl(mintfilter[0], mintfilter[1], saleFilter[0], saleFilter[1]), true)
            .then((res) => Promise.resolve(trasformData(res.data)))
            .catch((err) => Promise.reject(err));
    }
    return Promise.reject([]);
};
