import Styled from "@emotion/styled";
import { Tooltip } from "@mui/material";

type ColumnHeaderProps = {
    iscenter?: boolean;
};
export const ColumnHeader = Styled.span<ColumnHeaderProps>`
    display: flex;
    justify-content: ${(props) => (props.iscenter ? "center" : "flex-start")};
    align-items: center;
    cursor: pointer;
`;

export const HeaderTooltip = Styled(Tooltip)`
    font-size: 18px;
    margin-left: 3px;
`;
