import Styled from "@emotion/styled";
import { barRestColor, errorColor, successColor } from "../constants/ColorConstants";

export const BarClipper = Styled.div`
    width: 100%;
    height: 20px;
    overflow: hidden;
`;

export const BarWrapper = Styled.div`
    width: 100%;
    height: 20px;
    display: flex;
`;

type BarProps = {
    perwidth: number;
    showborder?: number;
};

export const BarOne = Styled.span<BarProps>`
    width: ${(props) => props.perwidth}%;
    background: ${successColor};
    border-radius: 10px 0px 0px 10px;
    height: 15px;
    border-radius: ${(props) => props.perwidth === 100 && "10px"};
`;

export const BarTwo = Styled.span<BarProps>`
    width: ${(props) => props.perwidth}%;
    background: ${barRestColor};
    height: 15px;
    border-radius: ${(props) => props.perwidth === 100 && "10px"}; 
`;

export const BarThree = Styled.span<BarProps>`
    width: ${(props) => props.perwidth}%;
    background: ${errorColor};
    border-radius: 0px 10px 10px 0px;
    height: 15px;
    border-radius: ${(props) => props.perwidth === 100 && "10px"};
`;
