import { CircularProgress } from "@mui/material";
import React, { Children, FC, useContext } from "react";
import { AuthContext } from "../../context/AuthenticationProvider";
import { walletConnect } from "../../utils/connector";

type NonPaidUserWrapperProps = {
    imageSrc: string;
};

const NonPaidUserWrapper: FC<NonPaidUserWrapperProps> = ({ imageSrc, children }) => {
    const { isPaidUser } = useContext(AuthContext);

    if (!isPaidUser) {
        return (
            <img
                onClick={walletConnect}
                src={imageSrc}
                style={{ width: "100%", cursor: "pointer" }}
                alt={"unpaidUser"}
            />
        );
    }

    return <>{children}</>;
};

export default NonPaidUserWrapper;
