const prodUrl = process.env.REACT_APP_API_PROD;
// const baseUrl2 = process.env.REACT_APP_API_BASE_2;
const baseUrl2 = prodUrl;
const baseUrl3 = "https://api-v2.cryptoscores.io/api/v2";
export const getNftsActiveUpcomingDataApiUrl = () => `${baseUrl2}/nfts/active?type=upcoming`;
export const getNftsUpcomingApiUrl = () => `${baseUrl2}/nfts/upcoming`;
export const getNftsLaunchedApiUrl = () => `${baseUrl2}/nfts/launched`;
export const getNftsScoreApiUrl = () => `${baseUrl2}/nfts/scores`;
export const getHuntersApiUrl = () => `${baseUrl2}/nfts/hunters`;
export const getUserInfoApiUrl = (publicAddress: string) => `${baseUrl2}/users?publicAddress=${publicAddress}`;
export const addNewUserApiUrl = () => `${baseUrl2}/users`;
export const authenticateUserApiUrl = () => `${baseUrl2}/authenticate`;
export const getSponsordBannerApiUrl = () => `${baseUrl2}/ads/homepage`;
export const getAllLiveMintDataApiUrl = () => `${prodUrl}/nfts/mints`;
export const getLiveMintDataApiUrl = (time: number, unit: string) =>
    `${prodUrl}/nfts/mints?intervalTime=${time}&unit=${unit}`;
export const getDashboardDataApiUrl = (mins: number) => `${prodUrl}/collectionSummary?intervalMinutes=${mins}`;
export const getAllLiveWhaleDataApiUrl = () => `${prodUrl}/nfts/whales`;
export const getLiveWhaleDataApiUrl = (time: number, unit: string) =>
    `${prodUrl}/nfts/whales?intervalTime=${time}&unit=${unit}`;
export const getLiveSaleDataApiUrl = (time: number, unit: string) =>
    `${prodUrl}/nfts/sales?intervalTime=${time}&unit=${unit}`;
export const getAllLiveSaleDataApiUrl = () => `${prodUrl}/nfts/sales`;
export const getWalletToPay = () => `${baseUrl2}/wallet`;
export const verifyPaidWalletApiUrl = (walletAddress: string) => `${baseUrl2}/subscription/${walletAddress}/verify`;
export const reportPaymentPaid = (txHash: string, from: string, to: string) =>
    `${baseUrl2}/payment/paid?tx_hash=${txHash}&pending_from=${from}&pending_to=${to}`;
export const getMintRadarDataApiUrl = (
    time: number,
    unit: string,
    intervalSalesTime: number,
    intervalSalesUnit: string
) =>
    `${prodUrl}/nfts/mints/radardata?intervalMintTime=${time}&unit=${unit}&intervalSalesTime=${intervalSalesTime}&salesUnit=${intervalSalesUnit}`;

export const getLiveWhaleDuoFeed = (time: number, unit: string) =>
    `${prodUrl}/nfts/feed/whales?intervalTime=${time}&unit=${unit}`;
export const getAllLiveWhaleDuoFeed = () => `${prodUrl}/nfts/feed/whales`;
export const getWalletSearchFeed = (walletId: string, intervalMinutes: number) =>
    `${prodUrl}/nfts/wallet/${walletId}?intervalMinutes=${intervalMinutes}`;
export const getCollectionDataApiUrl = (collectionId: string, intervalMinutes: number) =>
    `${prodUrl}/nfts/collection/${collectionId}?intervalMinutes=${intervalMinutes}`;
export const getCollectionDescriptionApiUrl = (contractParam: string) =>
    `${prodUrl}/collectionProfile?contractParam=${contractParam}`;
export const getCollectionSummaryListApiUrl = () => `${prodUrl}/collectionSummary?intervalMinutes=${60}`;
export const getCalendarListApiUrl = (id: string) => `${prodUrl}/nfts/${id}/upcoming-collection`;
export const getSignalListApiUrl = (time: number, unit: string) =>
    `${prodUrl}/nfts/signalsdata?intervalTime=${time}&unit=${unit}`;
export const getAllSignalListApiUrl = () => `${baseUrl3}/nfts/signalsdata`;
export const getWhaleApiUrl = (id: string) => `${baseUrl2}/nfts/${id}/whales-follows`;
