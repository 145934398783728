import { CircularProgress, Grid } from "@mui/material";
import React, { FC } from "react";

type LoadingComponentProps = {
    isLoading: boolean;
};
const LoadingComponent: FC<LoadingComponentProps> = ({ isLoading, children }) => {
    if (isLoading) {
        return (
            <Grid style={{ height: "100vh" }} container justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Grid>
        );
    }
    return <>{children}</>;
};

export default LoadingComponent;
