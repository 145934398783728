import {
    graphHighLineColor,
    graphLowLineColor,
    graphMediumLineColor,
    graphVeryLowLineColor,
    whiteColor
} from "../../constants/ColorConstants";
import { neftDataGridType } from "../neftdata/type";
import { GraphNeftDataType } from "../scorechart/util";

const getBarColor = (score: number): string => {
    if (score >= 0 && score < 0.3) {
        return graphVeryLowLineColor;
    }
    if (score >= 0.3 && score < 0.5) {
        return graphLowLineColor;
    }
    if (score >= 0.5 && score < 0.7) {
        return graphMediumLineColor;
    }
    if (score >= 0.7) {
        return graphHighLineColor;
    }
    return "";
};

export const tranformSellOutNeftDataList = (res: neftDataGridType[]): GraphNeftDataType => {
    const label: string[] = [];
    const dataSet: any = {
        label: "",
        data: [],
        backgroundColor: [],
        borderColor: "transparent",
        color: whiteColor,
        barThickness: 20
    };
    const sortData = res.sort((a, b) => b.selloutChance - a.selloutChance);
    const filteredData = sortData.filter((item) => item.selloutChance);
    filteredData.map((item) => {
        label.push(item.project + " (" + item.blockChain + ")");
        dataSet.data.push(item.selloutChance);
        dataSet.backgroundColor.push(getBarColor(item.selloutChance / 100));
    });
    return {
        labels: label,
        datasets: [dataSet]
    };
};
