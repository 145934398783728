import { Grid, Tabs } from "@mui/material";
import React, { FC } from "react";
import { MenuButton } from "../Header/headerStyled";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import InsertChartOutlinedTwoToneIcon from "@mui/icons-material/InsertChartOutlinedTwoTone";
import { CALENDAR, SCORE, SELLOUT } from "../../constants/appConstants";

type UpcomingNftTabsProps = {
    selectedTab: string;
    setSelectedTab: (tab: string) => void;
};

const UpcomingNftTabs: FC<UpcomingNftTabsProps> = ({ selectedTab, setSelectedTab }) => {
    const handleChange = (_: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    return (
        <Grid container style={{ marginBottom: 30 }}>
            <Tabs style={{ height: 60 }} value={selectedTab} indicatorColor="primary" onChange={handleChange}>
                <MenuButton
                    iconPosition={"start"}
                    icon={<CalendarTodayIcon fontSize={"small"} />}
                    value={CALENDAR}
                    label="Calendar"
                />
                <MenuButton
                    iconPosition={"start"}
                    icon={<InsertChartOutlinedTwoToneIcon fontSize={"small"} />}
                    value={SCORE}
                    label="Score chart"
                />
                <MenuButton
                    iconPosition={"start"}
                    icon={<InsertChartOutlinedTwoToneIcon fontSize={"small"} />}
                    value={SELLOUT}
                    label="Sell out chart"
                />
            </Tabs>
        </Grid>
    );
};

export default UpcomingNftTabs;
