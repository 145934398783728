import { Button, Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { DataGridContainer } from "../neftdata/style";

const WalletSearchFeedView = () => {
    const history = useHistory();
    const [walletAddress, setWalletAddress] = useState("");
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));

    return (
        <DataGridContainer style={{ marginTop: 30 }}>
            <Grid container justifyContent="center">
                <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                    <Typography variant="body1">Enter Wallet you want to track</Typography>
                    <Grid container style={{ marginTop: 5 }} spacing={2}>
                        <Grid item md={11} xs={9}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter wallet address"
                                value={walletAddress}
                                InputProps={{
                                    style: {
                                        fontSize: 20
                                    }
                                }}
                                onChange={({ target }) => setWalletAddress(target.value)}
                            />
                        </Grid>
                        <Grid item md={1} xs={2}>
                            <Button
                                onClick={() => history.push(`/wallet/${walletAddress}`)}
                                disabled={walletAddress === ""}
                                style={{ height: "100%", fontSize: 25 }}
                                variant="contained"
                                color="primary"
                                fullWidth>
                                👀
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DataGridContainer>
    );
};

export default WalletSearchFeedView;
