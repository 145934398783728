/* eslint-disable no-console */
import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { DataGridContainer } from "../neftdata/style";
import { StyledTags, SummaryContainer } from "./Styled";
import { CollectionSummaryType } from "./Types";
import { getCollectionSummary } from "./Util";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const CollectionSummaryView = () => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const [collectionSummary, setCollectionSummary] = useState<CollectionSummaryType | undefined>(undefined);
    const { collectionId } = useParams<{
        collectionId: string;
    }>();

    useEffect(() => {
        getCollectionSummary(collectionId).then((res) => {
            setCollectionSummary(res);
        });
    }, []);

    const isDeltaNegative = useMemo(() => {
        if (collectionSummary) {
            return collectionSummary.totVolDelta * 100 < 0;
        }
        return false;
    }, [collectionSummary]);

    const isSalesNegative = useMemo(() => {
        if (collectionSummary) {
            return collectionSummary.numTransDelta * 100 < 0;
        }
        return false;
    }, [collectionSummary]);

    if (!collectionSummary) {
        return null;
    }

    return (
        <DataGridContainer style={{ marginTop: 10 }}>
            <Grid container justifyContent="center">
                <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                    <Grid container spacing={2} alignItems={"stretch"}>
                        <Grid item style={{ borderRadius: 5 }} xs={12} md={4}>
                            <Paper style={{ padding: 10 }} elevation={2}>
                                <Typography style={{ fontSize: 16 }}>Volume 1H</Typography>
                                <SummaryContainer>
                                    <Typography variant={"body1"} style={{ fontSize: 16 }}>
                                        {collectionSummary.totVolNow.toFixed(0)} Ξ{" "}
                                        <span style={{ fontSize: 12, color: "grey" }}>
                                            from {collectionSummary.totVolPre.toFixed(0)} Ξ
                                        </span>
                                    </Typography>
                                    <StyledTags
                                        display={"flex"}
                                        alignItems={"center"}
                                        tagcolor={isDeltaNegative ? "negative" : "positive"}>
                                        {collectionSummary.totVolDelta < 0 ? (
                                            <ArrowDownwardIcon style={{ fontSize: 16 }} />
                                        ) : (
                                            <ArrowUpwardIcon style={{ fontSize: 16 }} />
                                        )}
                                        <Typography variant={"body1"} style={{ fontSize: 14 }}>
                                            {Math.round(collectionSummary.totVolDelta * 100)} %
                                        </Typography>
                                    </StyledTags>
                                </SummaryContainer>
                            </Paper>
                        </Grid>
                        <Grid item style={{ borderRadius: 5 }} xs={12} md={4}>
                            <Paper elevation={2} style={{ padding: 10 }}>
                                <Typography style={{ fontSize: 16 }}>Median Price 1H</Typography>
                                <SummaryContainer>
                                    <Typography variant={"body1"} style={{ fontSize: 16 }}>
                                        {collectionSummary.medianSaleNow.toFixed(2)} Ξ{" "}
                                        <span style={{ fontSize: 12, color: "grey" }}>
                                            from {collectionSummary.medianSalePre.toFixed(2)} Ξ{" "}
                                        </span>
                                    </Typography>
                                    <StyledTags
                                        display={"flex"}
                                        alignItems={"center"}
                                        tagcolor={collectionSummary.medianSaleDelta < 0 ? "negative" : "positive"}>
                                        {collectionSummary.medianSaleDelta < 0 ? (
                                            <ArrowDownwardIcon style={{ fontSize: 16 }} />
                                        ) : (
                                            <ArrowUpwardIcon style={{ fontSize: 16 }} />
                                        )}
                                        <Typography variant={"body1"} style={{ fontSize: 14 }}>
                                            {Math.round(collectionSummary.medianSaleDelta * 100)} %
                                        </Typography>
                                    </StyledTags>
                                </SummaryContainer>
                            </Paper>
                        </Grid>
                        <Grid item style={{ borderRadius: 5 }} xs={12} md={4}>
                            <Paper elevation={2} style={{ padding: 10 }}>
                                <Typography style={{ fontSize: 16 }}>Sales 1H</Typography>
                                <SummaryContainer>
                                    <Typography variant={"body1"} style={{ fontSize: 16 }}>
                                        {collectionSummary.numTransNow.toFixed(0)}{" "}
                                        <span style={{ fontSize: 12, color: "grey" }}>
                                            from {collectionSummary.numTransPre}
                                        </span>
                                    </Typography>
                                    <StyledTags
                                        display={"flex"}
                                        alignItems={"center"}
                                        tagcolor={isSalesNegative ? "negative" : "positive"}>
                                        {isSalesNegative ? (
                                            <ArrowDownwardIcon style={{ fontSize: 16 }} />
                                        ) : (
                                            <ArrowUpwardIcon style={{ fontSize: 16 }} />
                                        )}
                                        <Typography variant={"body1"} style={{ fontSize: 14 }}>
                                            {Math.round(collectionSummary.numTransDelta * 100)} %
                                        </Typography>
                                    </StyledTags>
                                </SummaryContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DataGridContainer>
    );
};

export default CollectionSummaryView;
