export const nftFilters = {
    only_eth: "only_eth",
    only_sol: "only_sol",
    score_500: "score_500",
    next_2h: "next_2h",
    next_24h: "next_24h",
    early_wl: "early_wl",
    other_chains: "other_chains",
    added_recently: "added_recently",
    whale: "whale",
    team: "team"
};

export const walletFilters = {
    buy: "buy",
    sale: "sale",
    mint: "mint"
};

export const nftCurrencies = {
    eth: "ETH",
    sol: "SOL"
};
export const buildVersion = "v0.1";

export const calendarFilterData = [
    {
        value: nftFilters.only_eth,
        label: "ETH",
        isPaidDisabled: false
    },
    {
        value: nftFilters.only_sol,
        label: "SOL",
        isPaidDisabled: false
    },
    {
        value: nftFilters.other_chains,
        label: "Other chains",
        isPaidDisabled: false
    },
    {
        value: nftFilters.score_500,
        label: "Score 500+",
        isPaidDisabled: true
    },
    {
        value: nftFilters.team,
        label: "Team",
        isPaidDisabled: false
    },
    {
        value: nftFilters.whale,
        label: "Whales",
        isPaidDisabled: true
    },
    {
        value: nftFilters.next_2h,
        label: "Next 2H",
        isPaidDisabled: false
    },
    {
        value: nftFilters.next_24h,
        label: "Next 24H",
        isPaidDisabled: false
    },
    {
        value: nftFilters.early_wl,
        label: "Early WL",
        isPaidDisabled: false
    },
    {
        value: nftFilters.added_recently,
        label: "Added Recently",
        isPaidDisabled: false
    }
];

export const nftFilterData = [
    {
        value: nftFilters.only_eth,
        label: "ETH",
        isPaidDisabled: false
    },
    {
        value: nftFilters.only_sol,
        label: "SOL",
        isPaidDisabled: false
    },
    {
        value: nftFilters.other_chains,
        label: "Other chains",
        isPaidDisabled: false
    },
    {
        value: nftFilters.score_500,
        label: "Score 500+",
        isPaidDisabled: true
    },
    {
        value: nftFilters.next_2h,
        label: "Next 2 Hours",
        isPaidDisabled: false
    },
    {
        value: nftFilters.next_24h,
        label: "Next 24 Hours",
        isPaidDisabled: false
    },
    {
        value: nftFilters.early_wl,
        label: "Early WL",
        isPaidDisabled: false
    },
    {
        value: nftFilters.added_recently,
        label: "Added Recently",
        isPaidDisabled: false
    }
];

export const walletFeedFilterOptions = [
    {
        value: "mint",
        label: "Mint"
    },
    {
        value: "buy",
        label: "Buy"
    },
    {
        value: "sale",
        label: "Sale"
    },
    {
        value: "air drop",
        label: "Air Drops"
    },
    {
        value: "received",
        label: "Received"
    },
    {
        value: "sent",
        label: "Sent"
    }
];

export const liveWhaleFeedFilterOptions = [
    {
        value: "mint",
        label: "Mint"
    },
    {
        value: "buy",
        label: "Buy"
    },
    {
        value: "sale",
        label: "Sale"
    },
    {
        value: "air drop",
        label: "Air Drops"
    },
    {
        value: "received",
        label: "Received"
    },
    {
        value: "sent",
        label: "Sent"
    }
];

export const CollectionIntervalOptions = [
    {
        value: 1,
        label: "1M"
    },
    {
        value: 5,
        label: "5M"
    },
    {
        value: 30,
        label: "30M"
    },
    {
        value: 60,
        label: "1H"
    },
    {
        value: 240,
        label: "4H"
    },
    {
        value: 24 * 60,
        label: "1D"
    }
];

export const CollectionSpanOptions = [
    {
        value: 5,
        label: "5M"
    },
    {
        value: 15,
        label: "15M"
    },
    {
        value: 60,
        label: "1H"
    },
    {
        value: 240,
        label: "4H"
    },
    {
        value: 720,
        label: "12H"
    },
    {
        value: 24 * 60,
        label: "1D"
    },
    {
        value: 24 * 3 * 60,
        label: "3D"
    },
    {
        value: 24 * 7 * 60,
        label: "7D"
    }
    // {
    //     value: 24 * 14 * 60,
    //     label: "14D"
    // },
    // {
    //     value: 24 * 30 * 60,
    //     label: "30D"
    // }
];

export const oldWebsiteUrl =
    "https://app.powerbi.com/view?r=eyJrIjoiZWRjMzk5MjctNDgxZi00NWIzLWFkMTItMTY0ZTQ0YzRhMTYzIiwidCI6IjhhYTg5OTIzLTMzMmItNGUwNS1hZTU5LWUwNDY0MzdmZjQyOCIsImMiOjl9&pageName=ReportSectiond54319f0106d7c0e4225";

export const bannerReadMoreUrl =
    "https://medium.com/@cryptoscores/become-a-founding-member-of-cryptoscores-911c56da22ea";

export const CALENDAR = "calendar";
export const CALENDAR_BETA = "calendarbeta";
export const SCORE = "scorechart";
export const SELLOUT = "selloutchart";
export const pollingDelays = 1000;
export const verifyPollingDelay = 10 * 1000;
export const maxPollingAttempts = 60;
export const LOCKED = "**locked**";
export const LIVE_MINT_POLLING_DELAY = 5 * 1000;
export const LIVE_WHALE_POLLING_DELAY = 5 * 1000;
export const MINT_RADAR_POLLING_DELAY = 5 * 1000;
export const LIVE_SALE_POLLING_DELAY = 60 * 1000;
export const MAX_POLLING_COUNT = 1000;
export const COLLECTION_CHART_POLLING_DELAY = 5 * 1000;
export const BUYSELL = "buysell";
export const DUOFEED = "duofeed";
export const WHALE_FEED_POLLING_DELAY = 5 * 1000;
export const WHALE_DUO_FEED_POLLING_DELAY = 10 * 1000;
export const MINT_ALL = "all";
export const MINT_NOT_SOLD = "not_sold";
export const TAB_CHART = "tabchart";
export const NEWS_FEED = "newsfeed";
export const TEAM_FEED = "teamfeed";
export const WHALE_FEED = "whalefeed";
export const WHALE_TWITTER_FEED = "whaletwitterfeed";
