import { Grid } from "@mui/material";
import React, { FC } from "react";
import DurationFilterView from "../../commoncomponents/durationfilter/DurationFilterView";
import { liveWhaleFeedFilterOptions } from "../../constants/appConstants";
import { filterByLiveDuoFeedOptions, filterByWalletFeedOptions } from "../../constants/filterOptions";
import { FilterButton } from "../neftdata/style";

type LiveWhaleFilterProps = {
    selectedFilter: string[];
    onFilterClick: (filter: string) => void;
    selectedTimeFilter: string;
    setSelectedTimeFilter: (selectedFilter: string) => void;
};
const LiveWhaleFilter: FC<LiveWhaleFilterProps> = ({
    selectedFilter,
    onFilterClick,
    selectedTimeFilter,
    setSelectedTimeFilter
}) => {
    return (
        <Grid container gap={2} justifyContent={"space-between"} alignItems={"center"} style={{ margin: "20px 0px" }}>
            <Grid item>
                <Grid container gap={2}>
                    {liveWhaleFeedFilterOptions.map((filter) => (
                        <Grid item key={filter.value}>
                            <FilterButton
                                selected={selectedFilter.includes(filter.value) ? 1 : 0}
                                onClick={() => {
                                    onFilterClick(filter.value);
                                }}
                            >
                                {filter.label}
                            </FilterButton>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item>
                <DurationFilterView
                    filterOptions={filterByLiveDuoFeedOptions}
                    selectedFilter={selectedTimeFilter}
                    setSelectedFilter={(selectedOption: string) => setSelectedTimeFilter(selectedOption)}
                />
            </Grid>
        </Grid>
    );
};

export default LiveWhaleFilter;
