import { Grid } from "@mui/material";
import { FC, useEffect } from "react";
import { embedTwitter } from "../livewhale/util";

type CalendarPageTeamFeedProps = {
    teamUrl: string;
    collectionName: string;
};

const CalendarPageTeamFeed: FC<CalendarPageTeamFeedProps> = ({ teamUrl, collectionName }) => {
    useEffect(() => {
        embedTwitter();
    }, []);

    return (
        <Grid container justifyContent={"center"} className="twitter-embed">
            <a className="twitter-timeline" data-theme="dark" data-width={500} href={`${teamUrl}?ref_src=twsrc%5Etfw`}>
                Tweets by {collectionName}
            </a>
        </Grid>
    );
};

export default CalendarPageTeamFeed;
