import { Drawer, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import { ThemeContext } from "../../context/ThemeProvider";
import { HeaderMenuContainer, MenuLinks, MobileMenuContainer } from "./headerStyled";
import MenuIcon from "@mui/icons-material/Menu";
import { guideUrl, methodForScoring, nftCheatSheetUrl, submitProject } from "../../constants/urlConstants";
import { useHistory } from "react-router";
import {
    alerts,
    calenderRoute,
    huntersRoute,
    launchedNFTRoute,
    livemint,
    livesale,
    livewhale,
    mintradar,
    signal,
    walletfeed
} from "../../constants/routeConstants";
import { footerSocialMediaData, footerSocialMediaDataType } from "../../constants/menuData";
import { BUYSELL } from "../../constants/appConstants";

const MobileHeader = (): JSX.Element => {
    const [drawer, setDrawer] = useState<boolean>(false);
    const themeContext = useContext(ThemeContext);
    const { darkMode } = themeContext;
    const history = useHistory();

    const handleNavigate = (url: string) => {
        history.push(url);
        setDrawer(false);
    };

    return (
        <div>
            <IconButton onClick={() => setDrawer(true)}>
                <MenuIcon />
            </IconButton>
            <Drawer anchor={"top"} open={drawer} onClose={() => setDrawer(false)}>
                <MobileMenuContainer>
                    <MenuLinks onClick={() => handleNavigate(calenderRoute)} darkmode={darkMode.toString()}>
                        wen mint
                    </MenuLinks>
                    <MenuLinks onClick={() => handleNavigate(livemint)} darkmode={darkMode.toString()}>
                        Live Mint
                    </MenuLinks>
                    <MenuLinks onClick={() => handleNavigate(signal)} darkmode={darkMode.toString()}>
                        Signals
                    </MenuLinks>
                    <MenuLinks onClick={() => handleNavigate(mintradar)} darkmode={darkMode.toString()}>
                        Mint Radar
                    </MenuLinks>
                    <MenuLinks onClick={() => handleNavigate(`/livewhale/${BUYSELL}`)} darkmode={darkMode.toString()}>
                        Whales
                    </MenuLinks>
                    <MenuLinks onClick={() => handleNavigate(livesale)} darkmode={darkMode.toString()}>
                        Live Sale
                    </MenuLinks>
                    <MenuLinks onClick={() => handleNavigate(launchedNFTRoute)} darkmode={darkMode.toString()}>
                        Minted
                    </MenuLinks>
                    <MenuLinks onClick={() => handleNavigate(huntersRoute)} darkmode={darkMode.toString()}>
                        Hunters
                    </MenuLinks>
                    <MenuLinks onClick={() => handleNavigate(walletfeed)} darkmode={darkMode.toString()}>
                        Wallet
                    </MenuLinks>
                    <MenuLinks onClick={() => handleNavigate(alerts)} darkmode={darkMode.toString()}>
                        Alerts
                    </MenuLinks>
                    <a href={guideUrl} target="_blank">
                        <MenuLinks darkmode={darkMode.toString()}>Guide</MenuLinks>
                    </a>

                    {/* <a href={nftCheatSheetUrl} target="_blank">
                        <MenuLinks darkmode={darkMode.toString()}>NFT Cheat Sheet</MenuLinks>
                    </a>
                    <a href={methodForScoring} target="_blank">
                        <MenuLinks darkmode={darkMode.toString()}>How We Score</MenuLinks>
                    </a>
                    <a href={submitProject} target="_blank">
                        <MenuLinks darkmode={darkMode.toString()}>Submit Project</MenuLinks>
                    </a> */}
                    <HeaderMenuContainer>
                        {footerSocialMediaData.map((data: footerSocialMediaDataType, index) => (
                            <img
                                style={{ cursor: "pointer" }}
                                src={data.imageUrl}
                                height={data.height}
                                alt={data.alt}
                                onClick={() => window.open(data.url, "_blank")}
                                key={`${index}${data.url}`}
                            />
                        ))}
                    </HeaderMenuContainer>
                    {/* <ConnectWalletButton>Connect Wallet</ConnectWalletButton> */}
                </MobileMenuContainer>
            </Drawer>
        </div>
    );
};

export default MobileHeader;
