import { Grid, Paper, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { getTeamIcon } from "../../utils/appIcons";
import { dateConverts } from "../../utils/getUtcTime";
import { formatNumber } from "../../utils/siteUtil";
import { SummaryContainer } from "../collection/Styled";
import { CalendarPageListType } from "./Type";

type CalendarProfileProps = {
    collectionDescription: CalendarPageListType;
};
const CalendarProfile: FC<CalendarProfileProps> = ({ collectionDescription }) => {
    return (
        <Grid container height={"100%"} justifyContent="center">
            <Grid item style={{ borderRadius: 5 }} xs={12}>
                <Paper style={{ padding: 10, height: "100%" }} elevation={2}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography style={{ fontSize: 14, color: "grey" }}>Mint Price</Typography>
                            <SummaryContainer>
                                <Typography variant={"body1"} style={{ fontSize: 18 }}>
                                    {collectionDescription.mintPriceCurr} {collectionDescription.mintCurrency}
                                </Typography>
                            </SummaryContainer>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{ fontSize: 14, color: "grey" }}>Supply</Typography>
                            <SummaryContainer>
                                <Typography variant={"body1"} style={{ fontSize: 18 }}>
                                    {formatNumber(collectionDescription.mintSupply)}
                                </Typography>
                            </SummaryContainer>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 10 }}>
                        <Grid item xs={6}>
                            <Typography style={{ fontSize: 14, color: "grey" }}>Launch Date</Typography>
                            <SummaryContainer>
                                <Typography variant={"body1"} style={{ fontSize: 18 }}>
                                    {dateConverts(collectionDescription.launchDate)}
                                </Typography>
                            </SummaryContainer>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{ fontSize: 14, color: "grey" }}>Team</Typography>
                            <SummaryContainer>
                                <Grid container gap={"5px"} alignItems={"center"}>
                                    {collectionDescription.team1 && (
                                        <Tooltip title={collectionDescription.team1Role}>
                                            <span
                                                onClick={() => window.open(collectionDescription.team1, "_blank")}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {getTeamIcon[collectionDescription.team1Role.toLowerCase()]}
                                            </span>
                                        </Tooltip>
                                    )}
                                    {collectionDescription.team2 && (
                                        <Tooltip title={collectionDescription.team2Role}>
                                            <span
                                                onClick={() => window.open(collectionDescription.team2, "_blank")}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {getTeamIcon[collectionDescription.team2Role.toLowerCase()]}
                                            </span>
                                        </Tooltip>
                                    )}
                                    {collectionDescription.team3 && (
                                        <Tooltip title={collectionDescription.team3Role}>
                                            <span
                                                onClick={() => window.open(collectionDescription.team3, "_blank")}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {getTeamIcon[collectionDescription.team3Role.toLowerCase()]}
                                            </span>
                                        </Tooltip>
                                    )}
                                </Grid>
                            </SummaryContainer>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CalendarProfile;
