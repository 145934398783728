import { LinearProgress, linearProgressClasses, Typography } from "@mui/material";
import { errorColor, successColor, whiteColor } from "../../constants/ColorConstants";
import styled from "@emotion/styled";

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: errorColor
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: successColor
    }
}));

type TxScoreTextProp = {
    backgroundcolor?: string;
};
export const TxScoreText = styled(Typography)<TxScoreTextProp>`
    padding: 0px 10px;
    margin-top: 11px;
    color: ${whiteColor};
    background-color: ${(props) => props.backgroundcolor || "inherit"};
    border-radius: 10px;
    width: fit-content;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
`;
