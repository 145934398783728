import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { errorColor, negativeTagColor, positiveTagColor, successColor } from "../../constants/ColorConstants";

type ChartContainerProps = {
    height: number;
};
export const ChartContainer = styled.div`
    & .echarts-for-react {
        height: ${(props: ChartContainerProps) => props.height}vh !important;
    }
`;

type StyledTagsProps = {
    tagcolor: "negative" | "positive";
};
export const StyledTags = styled(Typography)<StyledTagsProps>`
    background: ${(props) => (props.tagcolor === "negative" ? negativeTagColor : positiveTagColor)};
    padding: 2px 16px;
    border-radius: 5px;
    border: 1px ${(props) => (props.tagcolor === "negative" ? errorColor : successColor)} solid;
`;

export const SummaryContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
