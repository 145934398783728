import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { captionColor, whiteColor } from "../../constants/ColorConstants";

type TxScoreTextProp = {
    backgroundcolor?: string;
};
export const TxScoreText = styled(Typography)<TxScoreTextProp>`
    margin-top: 15px;
    padding: 0px 10px;
    color: ${whiteColor};
    background-color: ${(props) => props.backgroundcolor};
    border-radius: 10px;
    max-width: 50px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
`;

export const TxTimeStamp = styled(Typography)`
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    padding-top: 5px;
    color: ${captionColor};
`;
