import { Grid } from "@mui/material";
import React, { FC, useContext } from "react";
import { DurationFilterButton } from "../CommonStyle";
import { FilterByTimeOption } from "../../constants/filterOptions";
import { AuthContext } from "../../context/AuthenticationProvider";
import { walletConnect } from "../../utils/connector";

type DurationFilterViewProps = {
    setSelectedFilter: (selectedFilter: string) => void;
    selectedFilter: string;
    filterOptions: FilterByTimeOption[];
    title?: string;
};

const DurationFilterView: FC<DurationFilterViewProps> = ({
    selectedFilter,
    setSelectedFilter,
    title,
    filterOptions
}) => {
    const { isPaidUser } = useContext(AuthContext);
    return (
        <Grid container spacing={0} style={{ marginBottom: 5 }} justifyContent={"end"}>
            {title && <span style={{ marginRight: 10 }}>{title}:</span>}

            {filterOptions.map((item: FilterByTimeOption) => (
                <Grid item key={item.id}>
                    <DurationFilterButton
                        isselected={selectedFilter === item.id ? "true" : "false"}
                        onClick={() => {
                            item.onlyForPaidUser && !isPaidUser ? walletConnect() : setSelectedFilter(item.id);
                        }}
                        isdisabled={(item.onlyForPaidUser && !isPaidUser).toString()}
                    >
                        {item.label}
                    </DurationFilterButton>
                </Grid>
            ))}
        </Grid>
    );
};

export default DurationFilterView;
