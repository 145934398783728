/* eslint-disable no-console */
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import DurationFilterView from "../../commoncomponents/durationfilter/DurationFilterView";
import ListComponent from "../../commoncomponents/ListComponent";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { NotFinancialAdviceMessage } from "../../commoncomponents/NotFinancialAdviceMessage";
import { filterByTimeOptions, getFilterByTimeOptionById } from "../../constants/filterOptions";
import { moveToCollectionId } from "../../utils/siteUtil";
import { DataGridContainer } from "../neftdata/style";
import { getDashboardColumns } from "./hooks";
import { DashboardRecord } from "./type";
import { fetchDashboardData } from "./util";

export const DashboardView: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [dashboardRows, setDashboardRows] = useState<DashboardRecord[]>([]);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const [selectedFilter, setSelectedFilter] = useState<string>("1h");
    const loadDashboardData = (selectedFilter: string) => {
        setIsLoading(true);
        fetchDashboardData(getFilterByTimeOptionById(filterByTimeOptions, selectedFilter))
            .then((data) => {
                setDashboardRows(data);
            })
            .catch(() => setDashboardRows([]))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        loadDashboardData(selectedFilter);
    }, [selectedFilter]);

    return (
        <DataGridContainer style={{ marginTop: 30 }}>
            <Grid container justifyContent="center">
                <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                    <DurationFilterView
                        selectedFilter={selectedFilter}
                        filterOptions={filterByTimeOptions}
                        setSelectedFilter={(selectedOption: string) => setSelectedFilter(selectedOption)}
                    />
                    <NotFinancialAdviceMessage />
                    <LoadingComponent isLoading={isLoading}>
                        <ListComponent
                            columns={getDashboardColumns(true, mobileView)}
                            isDarkMode={true}
                            compact={false}
                            items={dashboardRows}
                            onRowClick={(rows, columnName) => {
                                if (columnName !== "collectionName") {
                                    moveToCollectionId(rows.contract);
                                }
                            }}
                        />
                    </LoadingComponent>
                </Grid>
            </Grid>
        </DataGridContainer>
    );
};
