export const iconMap: Record<string, string> = {
    twitter: "twitter.svg",
    discord: "discord.svg",
    web: "web.svg",
    alarm: "alarm.png",
    solana: "solana.png",
    opensea: "opensea.svg",
    tbc: "leftpointingmagnifyinglens.svg",
    abacus: "abacus.png",
    etherscan: "etherscan-logo-light-circle.svg",
    rare: "rare.svg",
    whale: "whale.svg",
    calendar: "calendar.svg",
    twitterWhite: "twitterWhite.svg",
    calendarAdd: "calendaradd.svg"
};
export const getIcon = (icon: string) => `/icons/${icon}` || "";

export const getTeamIcon: { [key: string]: string } = {
    founder: "🦸",
    artist: "🎨",
    community: "🧑‍💻",
    "creative director": "🎨",
    company: "🏢"
};
