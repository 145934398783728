export const dashboardRoute = "/trending";
export const calenderRoute = "/calendar/:tab";
export const scoreChartRoute = "/calendar/scorechart";
export const selloutChartRoute = "/calendar/selloutchart";
export const websiteChartroute = "/websitechart";
export const launchedNFTRoute = "/minted";
export const huntersRoute = "/hunters";
export const scoreResultsRoute = "/scoreresults";
export const livemint = "/livemint";
export const livewhale = "/livewhale/:tab";
export const livewhalebuysale = "/livewhale/buysell";
export const livewhaleduofeed = "/livewhale/duofeed";
export const livesale = "/livesale";
export const metamask = "/metamask";
export const mintradar = "/mintradar";
export const signal = "/signals";
export const walletfeed = "/wallet";
export const alerts = "/alerts";
export const collection = "/collection";
export const calendarBeta = "/calendar/calendarbeta";
export const calendarPagesRoute = "/calendar/calendar/:nftname/:nftid";
export const signalRoute = "/signals";
