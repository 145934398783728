import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { blackColor, whiteColor } from "../../constants/ColorConstants";
type MenuLinksProps = {
    darkmode?: string;
};
export const BuildVersionText = styled.div<MenuLinksProps>`
    position: relative;
    left: 20px;
    color: ${(props) => (props.darkmode === "true" ? whiteColor : blackColor)};
`;

export const FooterMenuTitle = styled(Typography)`
    font-size: 16px;
    font-weight: bold;
    color: ${whiteColor};
`;
