import { useMemo } from "react";
import { renderValue } from "../common/grid/Utils";
import ProjectNameView from "../neftdata/ProjectNameView";
import HeaderTooltipView from "../../commoncomponents/HeaderTooltip";
import { HeaderRendererProps } from "react-data-grid";
import { renderColumn } from "../dashboard/hooks";

export const getMintRadarColumns = (isDarkMode: boolean, isMobileView: boolean) =>
    useMemo(
        () => [
            {
                key: "collectionName",
                name: "Collection",
                frozen: true,
                width: isMobileView ? "50%" : "26%",
                formatter: (rows: any) => (
                    <ProjectNameView
                        openWebsiteOnClick
                        isDarkMode={isDarkMode}
                        projectName={rows.row.collectionName}
                        socialLinks={rows.row.socialLinks}
                        collectId={rows.row.contractId}
                    />
                ),
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Collection"
                        isMobileView={isMobileView}
                        headerProps={props}
                        hideTooltip
                        isNotCenter
                    />
                ),
                sortable: true
            },
            {
                key: "mintPrice",
                name: "Mint Price",
                sortable: true,
                sortDescendingFirst: true,
                width: "10%",
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Mint Price"
                        description="Our estimated Mint Price"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) =>
                    renderValue(`${rows.row.mintPrice}${rows.row.mintPrice ? " Ξ" : ""}`, isDarkMode)
            },
            {
                key: "mintCount",
                name: "Mint #",
                sortable: true,
                sortDescendingFirst: true,
                width: "10%",
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Mints"
                        description="Total NFTs minted during the interval"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) => renderValue(rows.row.mintCount, isDarkMode)
            },
            {
                key: "mktMinSale",
                name: "Sales Floor",
                width: "10%",
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Floor"
                        description="Floor price in the secondary market"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) =>
                    renderValue(`${rows.row.mktMinSale}${rows.row.mktMinSale ? " Ξ" : ""}`, isDarkMode),
                sortable: true,
                sortDescendingFirst: true
            },
            {
                key: "countMktSale",
                name: "Sales #",
                width: "10%",
                sortable: true,
                sortDescendingFirst: true,
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Sales #"
                        description="Total NFTs minted during the interval"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                formatter: (rows: any) => renderValue(rows.row.countMktSale, isDarkMode)
            },
            {
                key: "profitMin",
                sortable: true,
                sortDescendingFirst: true,
                formatter: (rows: any) => renderColumn(`${rows.row.profitMin}%`, rows.row.profitMin > 0),
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Profit%"
                        description="Estimate of secondary market sales floor price vs mint price, with some gas costs factored in."
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                ),
                width: "10%"
            },
            {
                key: "profitScore",
                width: "15%",
                sortable: true,
                sortDescendingFirst: true,
                formatter: (rows: any) => renderColumn(rows.row.profitScore, rows.row.profitScore > 0),
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Profit Score"
                        description="Score of secondary market sales prices vs mint price. Weighted by amount of ETH gained, and profit %"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                )
            },
            {
                key: "heatScore",
                formatter: (rows: any) => renderColumn(`${rows.row.heatScore}`, rows.row.heatScore >= 500),
                sortable: true,
                sortDescendingFirst: true,
                headerRenderer: (props: HeaderRendererProps<any>) => (
                    <HeaderTooltipView
                        columnTitle="Heat"
                        description="Score of number of mints, and sales during interval"
                        isMobileView={isMobileView}
                        headerProps={props}
                    />
                )
            }
        ],
        [isDarkMode, isMobileView]
    );
