import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AuthContext } from "../../context/AuthenticationProvider";
import { walletConnect } from "../../utils/connector";
import { LockIcon, LockScreenContainer, TimelineWrapper } from "./Style";
import TimeLineCard from "./TimeLineCard";
import { CalendarWhaleListType } from "./Type";
import { getCalendarWhaleList } from "./Util";

const CalendarWhaleTwitterTimeline = () => {
    const [whaleList, setWhaleList] = useState<CalendarWhaleListType[]>([]);
    const { nftid } = useParams<{
        nftid: string;
    }>();
    const { isPaidUser } = useContext(AuthContext);

    useEffect(() => {
        getCalendarWhaleList(nftid)
            .then((res: CalendarWhaleListType[]) => {
                setWhaleList(res.filter((item) => item.whaleTwitterUrl));
            })
            .catch(() => {
                setWhaleList([]);
            });
    }, []);

    if (!isPaidUser) {
        return (
            <Grid container justifyContent={"center"}>
                <LockIcon style={{ zIndex: 1, marginTop: 40 }} onClick={walletConnect} />
            </Grid>
        );
    }

    return (
        <TimelineWrapper>
            {whaleList.map((item, index) => (
                <TimeLineCard key={index} twitterName={item.whaleTwitterName || ""} />
            ))}
        </TimelineWrapper>
    );
};

export default CalendarWhaleTwitterTimeline;
