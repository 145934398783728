import { Client } from "../../commoncomponents/Base/api";
import { getNftsUpcomingApiUrl } from "../../constants/apiConstants";
import { LOCKED, nftFilters } from "../../constants/appConstants";
import { compareAddedDate, comparePresaleLauchDateTimeRange, compareTimeRange } from "../../utils/getUtcTime";
import { CalendarAdvanceFilterType } from "../calendarbeta/Type";
import { AdvanceFilterType } from "../upcomingneft/Type";
import { neftDataGridType } from "./type";

const transformData = (res: any) => {
    const data = res.data;
    return data.map((nft: any) => ({
        id: nft.nftId,
        project: nft.nftName || "",
        score: nft.score === LOCKED ? nft.score : parseInt(nft.score) || 0,
        blockChain: nft.blockchain || "",
        addedDate: nft.addedDate || "",
        launchDate: nft.launchDate || "",
        calendarLink: nft.calendarLink || "",
        mintPriceEth: parseFloat(nft.mintPriceEth) || 0,
        mintPrice: parseFloat(nft.mintPriceCurr) || 0,
        mintSupply: parseFloat(nft.mintSupply) || 0,
        mintCurrency: nft.mintCurrency || "",
        notes: nft.prelaunchNotes || "",
        preSaleDate: nft.presaleDate || "",
        dateStatus: nft.status || "",
        refered: nft.referringUser || "",
        selloutMins: nft.selloutMins || 0,
        team1: nft.team1 || "",
        team2: nft.team2 || "",
        team3: nft.team3 || "",
        team1Role: nft.team1Role || "",
        team2Role: nft.team2Role || "",
        team3Role: nft.team3Role || "",
        twitterFollowers: parseInt(nft.twitterFollowers) || 0,
        twitterAvgLikes: parseInt(nft.twitterAvgLikes) || 0,
        whaleFollows: parseInt(nft.whaleFollows) || 0,
        discordMembers: parseInt(nft.discordMembers) || 0,
        discordActiveFollowers: parseInt(nft.discordActiveFollowers) || 0,
        selloutChance: nft.selloutChance && nft.selloutChance > 0 ? Math.round(nft.selloutChance * 100) : 0,
        selloutMinsRange: nft.selloutMinsRange || "",
        socialLinks: {
            twitterUrl: nft.twitterUrl,
            websiteUrl: nft.websiteUrl,
            discordUrl: nft.discordUrl,
            marketAffUrl: nft.marketAffUrl || "",
            imageUrl: nft.thumbnailUrl
        },
        launchConfirmed: nft.launchConfirmed,
        poolScore: nft.poolScore || "",
        engageMentScore: nft.score === LOCKED ? nft.score : parseInt(nft.engagementScore) || "",
        bsScore: nft.bsScore || "",
        activeScore: parseInt(nft.activeScore) || ""
    }));
};

export const getNeftUpcomingData = (forceRefresh = false) =>
    Client.getInstance()
        .getData(getNftsUpcomingApiUrl(), forceRefresh)
        .then((res) => Promise.resolve(transformData(res)));

export const includeInFilteredItems = (searchText: string, item: neftDataGridType): boolean => {
    if (item.project && item.project.toLowerCase().includes(searchText)) {
        return true;
    }
    return false;
};

export const getFilter = (filter: string, rows: neftDataGridType[]) => {
    if (filter === nftFilters.only_eth) {
        return rows.filter((nft) => nft.blockChain === "ETH");
    }
    if (filter === nftFilters.only_sol) {
        return rows.filter((nft) => nft.blockChain === "SOL");
    }
    if (filter === nftFilters.whale) {
        return rows.filter((nft) => nft.whaleFollows);
    }
    if (filter === nftFilters.other_chains) {
        return rows.filter((nft) => nft.blockChain !== "SOL" && nft.blockChain !== "ETH");
    }
    if (filter === nftFilters.next_2h) {
        return rows.filter((nft) => compareTimeRange(nft.launchDate, 2));
    }
    if (filter === nftFilters.next_24h) {
        return rows.filter((nft) => compareTimeRange(nft.launchDate, 24));
    }
    if (filter === nftFilters.early_wl) {
        return rows.filter((nft) => comparePresaleLauchDateTimeRange(nft.preSaleDate, nft.launchDate, 10));
    }
    if (filter === nftFilters.added_recently) {
        return rows.filter((nft) => compareAddedDate(nft.addedDate));
    }
    if (filter === nftFilters.team) {
        return rows.filter((nft) => nft.team1 || nft.team2 || nft.team3);
    }
    return rows.filter((nft) => nft.score > 500);
};

export const onFilter = (filters: string[], rows: neftDataGridType[]) => {
    filters.map((filter) => {
        rows = getFilter(filter, rows);
    });
    return rows;
};

export const sortListByKey = (type: string, rows: neftDataGridType[]): number[] => {
    if (type === "twitterFollowers") {
        const sortedRows = rows.sort((a, b) => a.twitterFollowers - b.twitterFollowers);
        return [sortedRows[0].twitterFollowers || 0, sortedRows[sortedRows.length - 1].twitterFollowers || 0];
    }
    if (type === "discordMembers") {
        const sortedRows = rows.sort((a, b) => a.discordMembers - b.discordMembers);
        return [sortedRows[0].discordMembers || 0, sortedRows[sortedRows.length - 1].discordMembers || 0];
    }
    if (type === "mintPrice") {
        const sortedRows = rows.sort((a, b) => a.mintPrice - b.mintPrice);
        return [sortedRows[0].mintPrice || 0, sortedRows[sortedRows.length - 1].mintPrice || 0];
    }
    const sortedRows = rows.sort((a, b) => a.mintSupply - b.mintSupply);
    return [sortedRows[0].mintSupply || 0, sortedRows[sortedRows.length - 1].mintSupply || 0];
};

export const filterGridByRange = (value: AdvanceFilterType, selectedFilter: string[], rows: neftDataGridType[]) => {
    const { mintPriceMinMax, supplyPriceMinMax } = value;
    const data = onFilter(selectedFilter, rows);
    const filterBymintPrice = data.filter(
        (item) => item.mintPriceEth >= mintPriceMinMax[0] && item.mintPriceEth <= mintPriceMinMax[1]
    );

    return filterBymintPrice.filter(
        (item) => item.mintSupply >= supplyPriceMinMax[0] && item.mintSupply <= supplyPriceMinMax[1]
    );
};

export const filterCalendarGridByRange = (
    value: CalendarAdvanceFilterType,
    selectedFilter: string[],
    rows: neftDataGridType[]
) => {
    const { discordMinMax, twitterMinMax, mintPriceMinMax, supplyPriceMinMax } = value;
    const data = onFilter(selectedFilter, rows);
    const filterBymintPrice = data.filter(
        (item) => item.mintPrice >= mintPriceMinMax[0] && item.mintPrice <= mintPriceMinMax[1]
    );

    const filterBySupplyPrice = filterBymintPrice.filter(
        (item) => item.mintSupply >= supplyPriceMinMax[0] && item.mintSupply <= supplyPriceMinMax[1]
    );

    const filterByDiscord = filterBySupplyPrice.filter(
        (item) => item.discordMembers >= discordMinMax[0] && item.discordMembers <= discordMinMax[1]
    );

    return filterByDiscord.filter(
        (item) => item.twitterFollowers >= twitterMinMax[0] && item.twitterFollowers <= twitterMinMax[1]
    );
};
