import React, { FC, useContext } from "react";
import { useHistory } from "react-router";
import { ThemeContext } from "../../context/ThemeProvider";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { CustomAppBar, CustomToolbar, HeaderContainer, HeaderMenuContainer, MenuLinks } from "../Header/headerStyled";
import {
    footerMenuData,
    footerSocialMediaData,
    footerSocialMediaDataType,
    footerMenuDataType,
    footerMenuListData
} from "../../constants/menuData";
import blackLogo from "../../public/images/logo_beta.png";
import { FooterMenuTitle } from "./Footerstyled";

type FooterPropType = {
    buildVersion: string;
};
const global = window as any;

const Footer: FC<FooterPropType> = ({ buildVersion }): JSX.Element => {
    const themeContext = useContext(ThemeContext);
    const { darkMode } = themeContext;
    const history = useHistory();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));

    return (
        <CustomAppBar darkmode={darkMode.toString()} position="static" margintop={"40px"}>
            <CustomToolbar style={{ padding: 20 }}>
                <Grid container justifyContent={"space-evenly"}>
                    <Grid item xs={12} sm={"auto"}>
                        <HeaderMenuContainer style={{ flexDirection: "column" }}>
                            <img
                                onClick={() => global.window.logBadgeClick()}
                                id="badge-button"
                                style={{ height: 65 }}
                                src={blackLogo}
                                alt="Cryptoscores"
                            />
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                {footerSocialMediaData.map((data: footerSocialMediaDataType) => (
                                    <a key={data.url} href={data.url} target="_blank">
                                        <img src={data.imageUrl} height={data.height} alt={data.alt} />
                                    </a>
                                ))}
                            </div>
                            <a href="#">
                                <img
                                    onClick={() => global.window.logBadgeClick()}
                                    id="badge-button"
                                    style={{ width: 150, height: 35 }}
                                    src="https://static.alchemyapi.io/images/marketing/badgeLight.png"
                                    alt="Alchemy Supercharged"
                                />
                            </a>
                        </HeaderMenuContainer>
                    </Grid>
                    {Object.entries(footerMenuListData).map(([key, value]) => (
                        <Grid item key={key}>
                            <FooterMenuTitle>{value.title}</FooterMenuTitle>
                            {value.subItem.map((item) => (
                                <a key={item.url} href={item.url} target="_blank" style={{ textDecoration: "none" }}>
                                    <MenuLinks style={{ paddingLeft: 0, fontWeight: 400 }} darkmode={darkMode.toString()}>
                                        {item.title}
                                    </MenuLinks>
                                </a>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </CustomToolbar>
        </CustomAppBar>
    );
};

export default Footer;
