import { Grid, Switch } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { GroupButtonTitle } from "../../commoncomponents/CommonStyle";
import { OptionType } from "../../commoncomponents/CommonType";
import SplitButtonWrapper from "../../commoncomponents/SplitButtonWrapper";
import { CollectionIntervalOptions, CollectionSpanOptions } from "../../constants/appConstants";
import { useChartOptions, useTradeChartOptions } from "./Hooks";
import { ChartContainer } from "./Styled";
import { ScatterChartData, TradeChartData } from "./Types";
import {
    getCollectionData,
    convertCollectionDataToScatterChartData,
    convertCollectionDataToBarChartData,
    convertCollectionDataToTradeChartData
} from "./Util";
import highchartsMore from "highcharts/highcharts-more";
highchartsMore(Highcharts);

export const CollectionChart = () => {
    const [isChartLoading, setIsChartLoading] = useState(false);
    const [intervalInMins, setIntervalInMins] = useState<OptionType>({
        value: 30,
        label: "30M"
    });
    const [spanInMins, setSpanInMins] = useState<OptionType>({
        value: 240,
        label: "4H"
    });
    const [showOutliners, setShowOutliners] = useState(false);
    const toggleOutliners = useCallback(() => setShowOutliners(!showOutliners), [showOutliners]);

    const [tradeChartData, setTradeChartData] = useState<TradeChartData>();
    const [scatterChartData, setScatterChartData] = useState<ScatterChartData>();
    const [barChartData, setBarChartData] = useState<any>(); //remove any
    const [allChartData, setAllChartData] = useState<any>(); //remove any
    const { collectionId } = useParams<{
        collectionId: string;
    }>();

    useEffect(() => {
        setIsChartLoading(true);
        setScatterChartData(undefined);
        setBarChartData(undefined);
        getCollectionData(collectionId.trim(), 7 * 24 * 60)
            .then((data) => {
                setAllChartData(data);
                setScatterChartData(convertCollectionDataToScatterChartData(data, 7 * 24 * 60, showOutliners));
                setBarChartData(convertCollectionDataToBarChartData(data, intervalInMins.value, spanInMins.value));
            })
            .catch(() => setScatterChartData(undefined))
            .finally(() => setIsChartLoading(false));
    }, []);

    useEffect(() => {
        if (isChartLoading || !allChartData) {
            return;
        }
        setScatterChartData(convertCollectionDataToScatterChartData(allChartData, spanInMins.value, showOutliners));
        setBarChartData(convertCollectionDataToBarChartData(allChartData, intervalInMins.value, spanInMins.value));
    }, [isChartLoading, intervalInMins, spanInMins, allChartData, showOutliners]);

    useEffect(() => {
        if (isChartLoading || !allChartData) {
            return;
        }
        setTradeChartData(
            convertCollectionDataToTradeChartData(allChartData, intervalInMins.value, spanInMins.value, showOutliners)
        );
    }, [isChartLoading, intervalInMins, spanInMins, allChartData, showOutliners]);
    const option = useChartOptions(scatterChartData, barChartData, intervalInMins, spanInMins);
    const tradeChartOption = useTradeChartOptions(tradeChartData, intervalInMins, spanInMins);

    if (isChartLoading) {
        return (
            <Grid container justifyContent={"center"} alignItems={"center"} style={{ marginTop: 20, marginBottom: 10 }}>
                Loading chart data...
            </Grid>
        );
    }

    const controls = (
        <Grid container justifyContent={"center"} alignItems={"center"} style={{ marginTop: 10, marginBottom: 0 }}>
            <Grid item xs={9}>
                <Grid container>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}>
                        <h4 style={{ textAlign: "center", fontSize: 20 }}>Collection trading chart</h4>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container justifyContent={"flex-end"}>
                            <Grid item>
                                <GroupButtonTitle>Interval</GroupButtonTitle>
                                <SplitButtonWrapper
                                    options={CollectionIntervalOptions}
                                    selectedOption={intervalInMins}
                                    setOptionChange={(option: OptionType) => setIntervalInMins(option)}
                                />
                            </Grid>
                            <Grid item style={{ marginLeft: 10 }}>
                                <GroupButtonTitle>Span</GroupButtonTitle>
                                <SplitButtonWrapper
                                    options={CollectionSpanOptions}
                                    selectedOption={spanInMins}
                                    setOptionChange={(option: OptionType) => setSpanInMins(option)}
                                />
                            </Grid>
                            <Grid item style={{ marginLeft: 10 }}>
                                <GroupButtonTitle>Outliers</GroupButtonTitle>
                                <Switch checked={showOutliners} onChange={toggleOutliners} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <>
            {controls}
            <Grid container justifyContent="center">
                <Grid item xs={12} md={9}>
                    <ChartContainer height={60}>
                        <HighchartsReact highcharts={Highcharts} options={option} />
                    </ChartContainer>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{ marginTop: 30 }}>
                <Grid item xs={12} md={9}>
                    <ChartContainer height={60}>
                        <HighchartsReact highcharts={Highcharts} options={tradeChartOption} />
                    </ChartContainer>
                </Grid>
            </Grid>
        </>
    );
};
