import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import DurationFilterView from "../../commoncomponents/durationfilter/DurationFilterView";
import ListComponent from "../../commoncomponents/ListComponent";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { MAX_POLLING_COUNT, WHALE_FEED_POLLING_DELAY } from "../../constants/appConstants";
import { filterByWalletFeedOptions, getFilterByTimeOptionById } from "../../constants/filterOptions";
import { DataGridContainer } from "../neftdata/style";
import UseIntervalHook from "../payment/UseIntervalHook";
import { getWalletFeedColumns } from "./hooks";
import { WalletFeedType } from "./type";
import { getWalletFeedData, onFilterWallet } from "./util";
import fadeImage from "../../images/mintradar_fade_btn.png";
import NonPaidUserWrapper from "../common/NonPaidUserWrapper";
import WalletFeedFilter from "./WalletFeedFilter";
import { moveToCollectionId } from "../../utils/siteUtil";

const WalletFeedDataGrid = () => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string>("1h");
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const { walletAddress } = useParams<{
        walletAddress: string;
    }>();
    const [walletFeedRows, setWalletFeedRows] = useState<WalletFeedType[]>([]);
    const [selectedAdditionalFilter, setSelectedAdditionalFilter] = useState<string[]>([]);
    const [filteredRows, setFilteredRows] = useState<WalletFeedType[]>([]);

    useEffect(() => {
        fetchWalletFeed(true);
    }, [selectedFilter]);

    UseIntervalHook(
        () => {
            fetchWalletFeed(false);
        },
        ["5m", "30m", "1h"].includes(selectedFilter) ? WHALE_FEED_POLLING_DELAY : 0,
        MAX_POLLING_COUNT
    );

    const fetchWalletFeed = useCallback(
        (showLoadIndicator) => {
            showLoadIndicator && setIsLoading(true);
            getWalletFeedData(
                walletAddress.trim(),
                getFilterByTimeOptionById(filterByWalletFeedOptions, selectedFilter)
            )
                .then((data) => {
                    setWalletFeedRows(data);
                    setFilteredRows(onFilterWallet(selectedAdditionalFilter, data));
                })
                .catch(() => setWalletFeedRows([]))
                .finally(() => setIsLoading(false));
        },
        [selectedFilter, selectedAdditionalFilter]
    );

    const onFilterClick = (value: string) => {
        let filteredValues = [];
        if (selectedAdditionalFilter.includes(value)) {
            filteredValues = selectedAdditionalFilter.filter((item) => item !== value);
            setSelectedAdditionalFilter(filteredValues);
            setFilteredRows(onFilterWallet(filteredValues, walletFeedRows));
            return;
        }
        setSelectedAdditionalFilter((prev) => [...prev, value]);
        setFilteredRows(onFilterWallet([...selectedAdditionalFilter, value], walletFeedRows));
    };

    return (
        <DataGridContainer style={{ marginTop: 30 }}>
            <Grid container justifyContent="center">
                <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 6}>
                    <NonPaidUserWrapper imageSrc={fadeImage}>
                        <WalletFeedFilter
                            selectedFilter={selectedAdditionalFilter}
                            onFilterClick={onFilterClick}
                            selectedTimeFilter={selectedFilter}
                            setSelectedTimeFilter={(selectedOption: string) => setSelectedFilter(selectedOption)}
                        />
                        <LoadingComponent isLoading={isLoading}>
                            <ListComponent
                                columns={getWalletFeedColumns(true, mobileView)}
                                isDarkMode={true}
                                compact={false}
                                items={filteredRows}
                                initialSortDirection={"DESC"}
                                onRowClick={(rows, columnName) => {
                                    if (columnName !== "collectionName") {
                                        moveToCollectionId(rows.contractId);
                                    }
                                }}
                            />
                        </LoadingComponent>
                    </NonPaidUserWrapper>
                </Grid>
            </Grid>
        </DataGridContainer>
    );
};

export default WalletFeedDataGrid;
