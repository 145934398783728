import React, { createContext, ReactNode, useEffect, useState } from "react";
import { AuthProviderType } from "./types";

export const AuthContext = createContext<AuthProviderType>({
    metaMaskPublicId: "",
    setMetaMaskId: () => {
        return;
    },
    isWalletConnected: false,
    setIsWalletConnected: () => {
        return;
    },
    isPaidUser: false,
    setIsPaidUser: () => {
        return;
    }
});

type AuthProviderProps = {
    children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [metaMaskId, setMetaMaskId] = useState("");
    const [isWalletConnected, setIsWalletConnected] = useState<boolean>(false);
    const [isPaidUser, setIsPaidUser] = useState<boolean>(sessionStorage.getItem("paidCryptoscoresUser") === "1");

    const context = {
        metaMaskPublicId: metaMaskId,
        setMetaMaskId: (maskId: string) => setMetaMaskId(maskId),
        setIsWalletConnected: (isWalletConnected: boolean) => setIsWalletConnected(isWalletConnected),
        isWalletConnected: isWalletConnected,
        setIsPaidUser: (isPaidUser: boolean) => setIsPaidUser(isPaidUser),
        isPaidUser: isPaidUser
    };

    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};
