import { Typography } from "@mui/material";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Client } from "../../commoncomponents/Base/api";
import { maxPollingAttempts, verifyPollingDelay } from "../../constants/appConstants";
import { AuthContext } from "../../context/AuthenticationProvider";
import { TargetPaymentInfo } from "../payment/Types";
import UseIntervalHook from "../payment/UseIntervalHook";
import { getTargetWalletAddress, reportPayment, verifyWalletAddress } from "../payment/Utils";
import { ConnectButton } from "./style";
import SubscribeButton from "./SubscribeButton";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";

declare global {
    interface Window {
        ethereum: any;
    }
}

window.ethereum = window.ethereum || {};

const Subscription: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [userStatus, setUserStatus] = useState<string>();
    const [pollingDelay, setPollingDelay] = useState(0);
    const [walletAddress, setWalletAddress] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isMetamaskLocked, setIsMetamaskLocked] = useState<boolean>(false);
    const [targetPaymentInfo, setTargetPaymentInfo] = useState<TargetPaymentInfo>();
    const { setIsWalletConnected, setIsPaidUser, isPaidUser } = useContext(AuthContext);

    const connectWalletHandler = useCallback(() => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            setIsLoading(true);
            window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then((accounts: any) => {
                    if (!accounts || !accounts[0]) {
                        setWalletAddress(undefined);
                        return;
                    }
                    setWalletAddress(accounts[0]);
                    sessionStorage.setItem("walletAddress", accounts[0]);
                    verifyIfPaidUser(accounts[0], false);
                })
                .catch((error: any) => {
                    if (error.code === -32002) {
                        window.ethereum._metamask.isUnlocked().then((isUnlocked: boolean) => {
                            isUnlocked && window.location.reload();
                        });
                    }
                    setErrorMessage(error.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setErrorMessage("Please install MetaMask browser extension to interact");
        }
    }, []);

    useEffect(() => {
        if (window.ethereum.isMetaMask && window.ethereum._metamask) {
            window.ethereum._metamask.isUnlocked().then((isUnlocked: boolean) => {
                setIsMetamaskLocked(!isUnlocked);
            });
        }
        getTargetWalletAddress().then((data) => {
            setTargetPaymentInfo(data);
        });
        const sessionWalletAddress = sessionStorage.getItem("walletAddress");
        if (sessionWalletAddress) {
            Client.getInstance().setUserWalletAddressHeader(sessionWalletAddress);
            setWalletAddress(sessionWalletAddress);
            if (sessionStorage.getItem("paidCryptoscoresUser") === "1") {
                setIsPaidUser(true);
                setUserStatus("confirmed");
            } else {
                verifyIfPaidUser(sessionWalletAddress, false);
            }
            setIsWalletConnected(true);
        } else {
            connectWalletHandler();
        }
    }, []);

    const verifyIfPaidUser = (walletAddress: string, silent = true) => {
        !silent && setIsLoading(true);
        verifyWalletAddress(walletAddress).then((statusObj) => {
            setIsLoading(false);
            setUserStatus(statusObj.status);

            if (!silent) {
                if (statusObj.status === "confirmed") {
                    sessionStorage.setItem("paidCryptoscoresUser", "1");
                }
                Client.getInstance().setUserWalletAddressHeader(walletAddress);
                setWalletAddress(walletAddress);
                setIsPaidUser(statusObj.status === "confirmed" || false);
                setUserStatus(statusObj.status);
                setIsWalletConnected(true);
            }
            if (silent && statusObj.status === "confirmed") {
                window.location.reload();
            }
        });
    };

    const reportPaymentMade = (txHash: string) => {
        if (!targetPaymentInfo || !walletAddress) {
            return;
        }
        reportPayment(txHash, walletAddress, targetPaymentInfo.securedWalletAddress)
            .then(() => {
                setPollingDelay(verifyPollingDelay);
            })
            .catch(() => {
                setPollingDelay(verifyPollingDelay);
                setErrorMessage("Error while reporting payment");
            });
    };

    UseIntervalHook(
        () => {
            walletAddress && verifyIfPaidUser(walletAddress, true);
        },
        pollingDelay,
        maxPollingAttempts
    );
    // listen for account changes
    if (window.ethereum.isMetaMask) {
        window.ethereum.on("accountsChanged", (accounts: string[]) => {
            sessionStorage.removeItem("walletAddress");
            sessionStorage.removeItem("paidCryptoscoresUser");
            window.location.reload();
        });
        window.ethereum.on("chainChanged", () => {
            window.location.reload();
        });
    }

    if (!window.ethereum.isMetaMask) {
        return (
            <ConnectButton
                style={{ fontSize: 10, wordBreak: "break-all" }}
                onClick={() => {
                    window.open("https://metamask.io/", "_blank");
                }}>
                Pay with Metamask
            </ConnectButton>
        );
    }
    if (!walletAddress) {
        return (
            <ConnectButton style={{ fontSize: 10, wordBreak: "break-all" }} id={"wallet-connect-button"} onClick={connectWalletHandler}>
                {isMetamaskLocked ? (
                    <div>
                        <div style={{ color: "#FF5251" }}>Login to MetaMask</div>
                        {targetPaymentInfo && (
                            <div style={{ fontSize: 10 }}>To SUBSCRIBE Lifetime Ξ{targetPaymentInfo.paymentValue}</div>
                        )}
                    </div>
                ) : (
                    <div>
                        Subscribe
                        {targetPaymentInfo && (
                            <div style={{ fontSize: 10 }}>Lifetime Ξ{targetPaymentInfo.paymentValue}</div>
                        )}
                    </div>
                )}
            </ConnectButton>
        );
    }

    if (isLoading) {
        return <ConnectButton disabled>Loading</ConnectButton>;
    }
    if (isPaidUser) {
        return (
            <ConnectButton>
                <DiamondOutlinedIcon />
            </ConnectButton>
        );
    }

    if (targetPaymentInfo && userStatus === "un-confirmed") {
        return <SubscribeButton targetPaymentInfo={targetPaymentInfo} reportPaymentMade={reportPaymentMade} />;
    }
    if (errorMessage) {
        return <Typography color="error">{errorMessage}</Typography>;
    }
    return null;
};

export default Subscription;
