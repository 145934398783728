import { createContext, useContext, useState } from "react";
import { neftDataGridType } from "../neftdata/type";

interface NeftDataContextType {
    neftDataList: neftDataGridType[];
    setNeftData: (data: neftDataGridType[]) => void;
}

export const NeftDataContext = createContext<NeftDataContextType>({
    neftDataList: [],
    setNeftData: () => {
        return;
    }
});

export const NeftDataProvider = ({ children }: any) => {
    const [neftData, setNeftData] = useState<neftDataGridType[]>([]);

    const context = {
        setNeftData: (data: neftDataGridType[]) => setNeftData(data),
        neftDataList: neftData
    };

    return <NeftDataContext.Provider value={context}>{children}</NeftDataContext.Provider>;
};
