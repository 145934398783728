import { useMemo } from "react";
import { HighChartTheme } from "../../commoncomponents/CommonStyle";
import { getIcon, iconMap } from "../../utils/appIcons";
import { renderValue } from "../common/grid/Utils";
import { CalendarPageListType } from "./Type";
import whaleIcon from "../../images/whale.svg";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { TwitterButton } from "./Style";

export const useDiscordMemberChartOptions = (collectionList: CalendarPageListType[]) =>
    useMemo(() => {
        if (!collectionList) {
            return null;
        }

        return {
            chart: {
                type: "line",
                ...HighChartTheme.chart,
                height: 250
            },
            title: {
                text: "",
                ...HighChartTheme.title
            },
            yAxis: [
                {
                    ...HighChartTheme.yAxis,
                    title: {
                        enabled: false
                    }
                }
            ],
            legend: {
                ...HighChartTheme.legend,
                enabled: true
            },
            credits: {
                enabled: false
            },
            xAxis: {
                ...HighChartTheme.xAxis,
                type: "datetime",
                title: {
                    enabled: false
                }
            },
            series: [
                {
                    name: "Discord Members",
                    data: collectionList.map((item) => [
                        new Date(item.crawlTs).getTime(),
                        parseInt(item.discordMembers)
                    ])
                }
            ],

            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: "horizontal",
                                align: "center",
                                verticalAlign: "bottom"
                            }
                        }
                    }
                ]
            }
        };
    }, [collectionList]);

export const useDiscordEngagedChartOptions = (collectionList: CalendarPageListType[]) =>
    useMemo(() => {
        if (!collectionList) {
            return null;
        }

        return {
            chart: {
                type: "line",
                ...HighChartTheme.chart,
                height: 250
            },
            title: {
                text: "",
                ...HighChartTheme.title
            },
            yAxis: [
                {
                    ...HighChartTheme.yAxis,
                    title: {
                        enabled: false
                    }
                }
            ],
            legend: {
                ...HighChartTheme.legend,
                enabled: true
            },
            credits: {
                enabled: false
            },
            xAxis: {
                ...HighChartTheme.xAxis,
                type: "datetime",
                title: {
                    enabled: false
                }
            },
            series: [
                {
                    name: "Discord Engaged",
                    data: collectionList.map((item) => [
                        new Date(item.crawlTs).getTime(),
                        parseInt(item.discordActiveFollowers)
                    ])
                }
            ],

            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: "horizontal",
                                align: "center",
                                verticalAlign: "bottom"
                            }
                        }
                    }
                ]
            }
        };
    }, [collectionList]);

export const usetwitterFollowerChartOptions = (collectionList: CalendarPageListType[]) =>
    useMemo(() => {
        if (!collectionList) {
            return null;
        }

        return {
            chart: {
                type: "line",
                ...HighChartTheme.chart,
                height: 250
            },
            title: {
                text: "",
                ...HighChartTheme.title
            },
            yAxis: [
                {
                    ...HighChartTheme.yAxis,
                    title: {
                        enabled: false
                    }
                }
            ],
            legend: {
                ...HighChartTheme.legend,
                enabled: true
            },
            credits: {
                enabled: false
            },
            xAxis: {
                ...HighChartTheme.xAxis,
                type: "datetime",
                title: {
                    enabled: false
                }
            },
            series: [
                {
                    name: "Twitter Follower",
                    data: collectionList.map((item) => [
                        new Date(item.crawlTs).getTime(),
                        parseInt(item.twitterFollowers)
                    ])
                }
            ],

            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: "horizontal",
                                align: "center",
                                verticalAlign: "bottom"
                            }
                        }
                    }
                ]
            }
        };
    }, [collectionList]);

export const usetwiiterEngagedChartOptions = (collectionList: CalendarPageListType[]) =>
    useMemo(() => {
        if (!collectionList) {
            return null;
        }

        return {
            chart: {
                type: "line",
                height: 250,
                ...HighChartTheme.chart
            },
            title: {
                text: "",
                ...HighChartTheme.title
            },
            yAxis: [
                {
                    ...HighChartTheme.yAxis,
                    title: {
                        enabled: false
                    }
                }
            ],
            legend: {
                ...HighChartTheme.legend,
                enabled: true
            },
            credits: {
                enabled: false
            },
            xAxis: {
                ...HighChartTheme.xAxis,
                type: "datetime",
                title: {
                    enabled: false
                }
            },
            series: [
                {
                    name: "Twitter Engaged",
                    data: collectionList.map((item) => [
                        new Date(item.crawlTs).getTime(),
                        parseInt(item.twitterAvgLikes)
                    ])
                }
            ],

            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: "horizontal",
                                align: "center",
                                verticalAlign: "bottom"
                            }
                        }
                    }
                ]
            }
        };
    }, [collectionList]);

export const renderWhaleFeedColumns = (isDarkMode: boolean, isMobileView: boolean, nftName: string) => [
    {
        key: "thumbnailurl",
        name: "",
        width: "10%",
        formatter: (rows: any) => (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <img style={{ height: 40, width: 40, borderRadius: 24 }} src={rows.row.thumbnailurl || whaleIcon} />
            </div>
        ),
        sortable: false
    },
    {
        key: "whaleTwitterName",
        name: "Whale",
        width: "20%",
        formatter: (rows: any) => (
            <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                {renderValue(rows.row.whaleTwitterName, isDarkMode)}
            </div>
        ),
        sortable: true
    },
    {
        key: "whaleTwitterUrl",
        name: "Twitter",
        width: "10%",
        formatter: (rows: any) =>
            rows.row.whaleTwitterName && (
                <div
                    style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
                    onClick={() => window.open(rows.row.whaleTwitterUrl, "_blank")}
                >
                    <img style={{ height: 30, width: 30 }} src={getIcon(iconMap.twitter)} />
                </div>
            ),
        sortable: false
    },
    {
        key: "whaleOsAffUrl",
        name: "Whale Collection",
        width: "20%",
        formatter: (rows: any) => (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                {rows.row.whaleOsAffUrl && (
                    <img
                        onClick={() => window.open(rows.row.whaleOsAffUrl, "_blank")}
                        style={{ height: 30, width: 30 }}
                        src={getIcon(iconMap.opensea)}
                    />
                )}
                {rows.row.whaleOsAffUrl && (
                    <img
                        onClick={() => window.open(rows.row.whaleLooksAffUrl, "_blank")}
                        style={{ height: 30, width: 30, marginLeft: 10 }}
                        src={getIcon(iconMap.rare)}
                    />
                )}
            </div>
        ),
        sortable: false
    },
    {
        key: "whalecswallet",
        name: "Latest Trades",
        width: "10%",
        formatter: (rows: any) =>
            rows.row.whaleCsWallet && (
                <div
                    onClick={() => window.open(rows.row.whaleCsWallet)}
                    style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
                >
                    <AccountBalanceWalletIcon />
                </div>
            ),
        sortable: false
    },
    {
        key: "id",
        name: "Chat",
        width: isMobileView ? 300 : "30%",
        formatter: (rows: any) => (
            <TwitterButton onClick={() => window.open(rows.row.tweetToWhaleLink, "_blank")}>
                <img src={getIcon(iconMap.twitterWhite)} height={25} width={25} />
                <span>Tweet to @${rows.row.whaleTwitterName}</span>
            </TwitterButton>
        ),
        sortable: false
    }
];
