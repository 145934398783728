import { Avatar, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { SocialIconContainer } from "../neftdata/style";
import { CollectionDescriptionType } from "./Types";
import noImage from "../../images/broken.jpg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import { getIcon, iconMap } from "../../utils/appIcons";
import { bannerBackgroundColor } from "../../constants/ColorConstants";

type CollectionDescriptionProps = {
    collectionDescription: CollectionDescriptionType | null;
};
const CollectionDescription: FC<CollectionDescriptionProps> = ({ collectionDescription }) => {
    if (!collectionDescription) {
        return null;
    }

    return (
        <Grid container spacing={6}>
            <Grid item xs={12} md={"auto"}>
                <Avatar
                    style={{ height: 98, width: 98 }}
                    sizes="large"
                    src={collectionDescription?.largeImageUrl || collectionDescription?.imageUrl || noImage}
                />
            </Grid>
            <Grid item xs={12} md={9}>
                <Grid container>
                    <Grid item xs={12} sm={"auto"}>
                        <Typography style={{ marginRight: 10, fontWeight: 500, fontSize: 24, lineHeight: 1 }}>
                            {collectionDescription?.collectionName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={"auto"}>
                        <div style={{ display: "flex", gap: 2 }}>
                            {collectionDescription?.socialLinks?.websiteUrl && (
                                <SocialIconContainer>
                                    <a href={collectionDescription.socialLinks.websiteUrl} target={"_blank"}>
                                        <WebAssetIcon style={{ fontSize: 25, color: bannerBackgroundColor }} />
                                    </a>
                                </SocialIconContainer>
                            )}
                            {collectionDescription?.socialLinks?.twitterUrl && (
                                <SocialIconContainer>
                                    <a href={collectionDescription.socialLinks.twitterUrl} target={"_blank"}>
                                        <img src={getIcon(iconMap.twitter)} height={25} width={25} />
                                    </a>
                                </SocialIconContainer>
                            )}
                            {collectionDescription?.socialLinks?.discordUrl && (
                                <SocialIconContainer>
                                    <a href={collectionDescription.socialLinks.discordUrl} target={"_blank"}>
                                        <img src={getIcon(iconMap.discord)} height={21} width={21} />
                                    </a>
                                </SocialIconContainer>
                            )}
                            {collectionDescription?.socialLinks?.etherscanUrl && (
                                <SocialIconContainer>
                                    <a href={collectionDescription.socialLinks.etherscanUrl} target={"_blank"}>
                                        <img src={getIcon(iconMap.etherscan)} height={25} width={25} />
                                    </a>
                                </SocialIconContainer>
                            )}
                            {collectionDescription?.socialLinks?.openseaSlug && collectionDescription.marketAffUrl && (
                                <SocialIconContainer>
                                    <a href={collectionDescription.marketAffUrl} target={"_blank"}>
                                        <img src={getIcon(iconMap.opensea)} height={25} width={25} />
                                    </a>
                                </SocialIconContainer>
                            )}
                            {collectionDescription?.looksUrl && (
                                <SocialIconContainer>
                                    <a href={collectionDescription.looksUrl} target={"_blank"}>
                                        <img src={getIcon(iconMap.rare)} height={25} width={25} />
                                    </a>
                                </SocialIconContainer>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Typography color={"GrayText"} display={"flex"} alignItems={"center"} variant={"body2"}>
                    {collectionDescription?.contractId}
                    <ContentCopyIcon
                        onClick={() => navigator.clipboard.writeText(collectionDescription?.contractId || "")}
                        style={{ marginLeft: 5, fontSize: 16, cursor: "pointer" }}
                    />
                </Typography>
                {collectionDescription?.description && (
                    <div style={{ fontSize: 10 }}>{collectionDescription?.description}</div>
                )}
            </Grid>
        </Grid>
    );
};

export default CollectionDescription;
