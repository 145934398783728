import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import DurationFilterView from "../../commoncomponents/durationfilter/DurationFilterView";
import ListComponent from "../../commoncomponents/ListComponent";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { NotFinancialAdviceMessage } from "../../commoncomponents/NotFinancialAdviceMessage";
import { LIVE_SALE_POLLING_DELAY, MAX_POLLING_COUNT } from "../../constants/appConstants";
import { filterByTimeOptions, getFilterByTimeOptionById } from "../../constants/filterOptions";
import { moveToCollectionId } from "../../utils/siteUtil";
import { DataGridContainer } from "../neftdata/style";
import UseIntervalHook from "../payment/UseIntervalHook";
import { renderLiveSaleColumns } from "./hooks";
import { LiveSaleType } from "./type";
import { getLiveSaleData } from "./util";

const LiveSaleView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [liveSale, setLiveSale] = useState<LiveSaleType[]>([]);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const [selectedFilter, setSelectedFilter] = useState<string>("1h");

    useEffect(() => {
        selectedFilter !== "" && fetchLiveSale(true);
    }, [selectedFilter]);

    UseIntervalHook(
        () => {
            fetchLiveSale(false);
        },
        ["1m", "5m", "30m"].includes(selectedFilter) ? LIVE_SALE_POLLING_DELAY : 0,
        MAX_POLLING_COUNT
    );

    const fetchLiveSale = useCallback(
        (showLoadIndicator) => {
            showLoadIndicator && setIsLoading(true);
            getLiveSaleData(getFilterByTimeOptionById(filterByTimeOptions, selectedFilter))
                .then((data) => {
                    setLiveSale(data);
                })
                .catch(() => setLiveSale([]))
                .finally(() => setIsLoading(false));
        },
        [selectedFilter]
    );

    return (
        <DataGridContainer style={{ marginTop: 30 }}>
            <Grid container justifyContent="center">
                <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                    <DurationFilterView
                        filterOptions={filterByTimeOptions}
                        selectedFilter={selectedFilter}
                        setSelectedFilter={(selectedOption: string) => setSelectedFilter(selectedOption)}
                    />
                    <NotFinancialAdviceMessage />
                    <LoadingComponent isLoading={isLoading}>
                        <ListComponent
                            columns={renderLiveSaleColumns(true, mobileView)}
                            isDarkMode={true}
                            compact={false}
                            items={liveSale}
                            initialSortKey={"numTrans"}
                            initialSortDirection={"DESC"}
                            onRowClick={(rows, columnName) => {
                                if (columnName !== "collectionName") {
                                    moveToCollectionId(rows.contractId);
                                }
                            }}
                        />
                    </LoadingComponent>
                </Grid>
            </Grid>
        </DataGridContainer>
    );
};

export default LiveSaleView;
