import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { FC, useContext } from "react";
import { Bar } from "react-chartjs-2";
import { gridSelloutStyleOptions } from "../../commoncomponents/CommonStyle";
import { getBarHeight } from "../../utils/bargraphUtils";
import { GraphNeftDataType } from "../scorechart/util";
import SellOutUnpaid from "../../images/selloutchart_fade3.png";
import NonPaidUserWrapper from "../common/NonPaidUserWrapper";
import { AuthContext } from "../../context/AuthenticationProvider";

type ScoreChartViewProps = {
    nftLists: GraphNeftDataType;
};

const SelloutChartView: FC<ScoreChartViewProps> = ({ nftLists }) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const { isPaidUser } = useContext(AuthContext);

    if (nftLists.labels.length === 0 && isPaidUser) {
        return (
            <Typography variant={"h6"} align={"center"}>
                Nothing to show
            </Typography>
        );
    }

    return (
        <NonPaidUserWrapper imageSrc={SellOutUnpaid}>
            <Bar
                key={mobileView.toString()}
                data={nftLists}
                options={gridSelloutStyleOptions(
                    "cryptoscores.io: (Beta) Model Prediction for an NFT project to sell out"
                )}
                height={getBarHeight(nftLists.labels.length, mobileView)}
            />
        </NonPaidUserWrapper>
    );
};

export default SelloutChartView;
