import Styled from "@emotion/styled";
import { Button, Grid, IconButton } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

type LockScreenContainerProps = {
    ispaid: boolean;
};
export const LockScreenContainer = Styled(Grid)<LockScreenContainerProps>`
    ${(props) =>
        !props.ispaid &&
        `
    overflow: hidden;
    opacity: 0.2;
    pointer-events: none;
    `}
`;

export const LockIcon = Styled(LockOutlinedIcon)`
    color: rgb(255, 255, 255);
    cursor: pointer;
    position: absolute;
    left: 50%;
    font-size: 44px;
    top: 36%;
`;

export const ChartWrapper = Styled(Grid)`
    position: relative;
`;

export const TwitterEmbedLink = Styled.div`
    width: 50%;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const TimelineWrapper = Styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 450px;
    gap: 30px;

    @media (min-width: 768px) and (max-width: 956px) {
        grid-template-columns: repeat(3, 1fr) !important;
    }

    @media (max-width: 768px) {
        display:  flex;
        flex-direction: column;
        div {
            height: 450px;
        }
    }
`;

export const TwitterButtonWrapper = Styled.div`
    iframe {
        max-width: 300px !important;
        border-radius: 55px;
        text-overflow: ellipse;
    }
`;

export const CalendarSocialIconContainer = Styled(IconButton)`
padding: 2px;
&:hover {
        background-color: transparent;
    }
`;

export const TwitterButton = Styled(Button)`
    border-radius: 9999px;
    padding: 1px 12px 1px 12px;
    background-color: #0c7abf;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    font-size: 12px;
`;
