import CollectionDescription from "./CollectionDescription";
import CollectionSummaryView from "./CollectionSummaryView";
import { CollectionChart } from "./CollectionChart";
import { useEffect, useState } from "react";
import { TAB_CHART } from "../../constants/appConstants";
import { DataGridContainer } from "../neftdata/style";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { CollectionDescriptionType } from "./Types";
import { useParams } from "react-router";
import { getCollectionDescription } from "./Util";
import CollectionTab from "./CollectionTab";
import CalendarPageNewFeed from "../calendarpage/CalendarPageNewFeed";

const CollectionView = () => {
    const [selectedTab, setSelectedTab] = useState(TAB_CHART);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const [collectionDescription, setCollectionDescription] = useState<CollectionDescriptionType | null>(null);

    const { collectionId } = useParams<{
        collectionId: string;
    }>();

    useEffect(() => {
        getCollectionDescription(collectionId).then((res) => {
            setCollectionDescription(res);
        });
    }, []);

    const renderView = () => {
        if (selectedTab === TAB_CHART) {
            return <CollectionChart />;
        }

        return (
            <CalendarPageNewFeed
                twitterUrl={collectionDescription?.socialLinks.twitterUrl || ""}
                collectionName={collectionDescription?.collectionName || ""}
            />
        );
    };

    return (
        <DataGridContainer style={{ marginTop: 10 }}>
            <Grid container justifyContent="center">
                <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                    <CollectionDescription collectionDescription={collectionDescription} />
                    <CollectionSummaryView />
                    <CollectionTab
                        hideNewsFeed={!!collectionDescription?.socialLinks.twitterUrl}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                    {renderView()}
                </Grid>
            </Grid>
        </DataGridContainer>
    );
};

export default CollectionView;
