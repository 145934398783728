import { FC, useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { DataGridContainer } from "../neftdata/style";
import { getNeftLaunchedData } from "./utils";
import { MintedNft } from "./types";
import MintTab from "./MintTab";
import { MINT_ALL } from "../../constants/appConstants";
import MintedNftAllList from "./MintedNftAllList";
import MintedNftNonSoldList from "./MintedNftNonSoldList";

const MintedNftView: FC = () => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<MintedNft[]>([]);
    const [selectedTab, setSelectedTab] = useState(MINT_ALL);

    useEffect(() => {
        setIsLoading(true);
        getNeftLaunchedData()
            .then((res: MintedNft[]) => {
                setRows(res);
            })
            .catch(() => setRows([]))
            .finally(() => setIsLoading(false));
    }, []);

    const renderGrid = () => {
        if (selectedTab === MINT_ALL) {
            return <MintedNftAllList rows={rows} />;
        }
        return <MintedNftNonSoldList rows={rows.filter((item) => item.soldOut === false)} />;
    };

    return (
        <LoadingComponent isLoading={isLoading}>
            <DataGridContainer>
                <Grid container justifyContent="center">
                    <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                        <MintTab selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                        {renderGrid()}
                    </Grid>
                </Grid>
            </DataGridContainer>
        </LoadingComponent>
    );
};

export default MintedNftView;
