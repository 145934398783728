import { useMediaQuery, useTheme } from "@mui/material";
import { FC, useContext } from "react";
import ListComponent from "../../commoncomponents/ListComponent";
import { AuthContext } from "../../context/AuthenticationProvider";
import { moveToCalendar } from "../../utils/siteUtil";
import { neftDataGridType } from "../neftdata/type";
import { getCalendarColumns } from "./GridHooks";

type CalendarViewProps = {
    nftLists: neftDataGridType[];
};

const CalendarView: FC<CalendarViewProps> = ({ nftLists }) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));
    const { isPaidUser } = useContext(AuthContext);

    return (
        <ListComponent
            columns={getCalendarColumns(true, mobileView, isPaidUser)}
            initialSortKey={"launchDate"}
            initialSortDirection={"ASC"}
            isDarkMode={true}
            compact={false}
            items={nftLists}
            onRowClick={(rows, columnName) => {
                if (!["collectionName", "team", "launchDate"].includes(columnName) && isPaidUser) {
                    moveToCalendar(rows.project, rows.id);
                }
            }}
        />
    );
};

export default CalendarView;
