import { Client } from "../../commoncomponents/Base/api";
import { getNftsScoreApiUrl } from "../../constants/apiConstants";
import { ScoreRowType } from "./types";

const transformGridData = (data: any, scoreType: string): ScoreRowType[] => {
    if (!data) {
        return [];
    }
    return data
        .map((row: any) => ({
            groupType: row.groupType,
            scoreType: row.scoreType,
            soldoutPerc: row.soldoutPerc,
            soldoutN: row.soldoutN,
            notsoldoutPerc: row.notsoldoutPerc,
            notsoldoutN: row.notsoldoutN
        }))
        .filter((record: ScoreRowType) => record.scoreType === scoreType);
};

export const getNftScoreResultData = () =>
    Client.getInstance()
        .getData(getNftsScoreApiUrl())
        .then(({ data }) =>
            Promise.resolve({
                scoreVsActualGridData: transformGridData(data, "Scores"),
                selloutPercentVsActualGridData: transformGridData(data, "Sellout")
            })
        );
