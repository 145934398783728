import React, { useContext, useEffect, useMemo } from "react";
import Header from "./components/Header/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeContext } from "./context/ThemeProvider";
import { themes } from "./constants/themeData";
import {
    globalFonts,
    primaryButtonColor,
    primaryDarkBackgroundColor,
    primaryLightBackgroundColor
} from "./constants/ColorConstants";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { AuthProvider } from "./context/AuthenticationProvider";
import { buildVersion } from "./constants/appConstants";
import Footer from "./components/Footer/Footer";
import ApplicationRoutes from "./routes/ApplicationRoutes";
import { NeftDataProvider } from "./components/context/DataContext";
import { Grid } from "@mui/material";
import { ComponentContainer } from "./commoncomponents/CommonStyle";
import MainSponsorBanner from "./components/sponsorbanner/MainSponsorBanner";

const App = () => {
    const getLibrary = (provider: any) => {
        return new Web3(provider);
    };
    const usetheme = useTheme();
    const themeContext = useContext(ThemeContext);
    const { darkMode, setDarkMode } = themeContext;
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: darkMode ? themes.dark : themes.light,
                    background: { default: darkMode ? primaryDarkBackgroundColor : primaryLightBackgroundColor }
                },
                typography: {
                    fontFamily: globalFonts.join(",")
                },
                components: {
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                background: primaryButtonColor,
                                "&:hover": {
                                    backgroundColor: primaryButtonColor
                                }
                            }
                        }
                    }
                }
            }),
        [darkMode]
    );

    useEffect(() => {
        if (prefersDarkMode) {
            setDarkMode(true);
            return;
        }
        setDarkMode(false);
    }, [prefersDarkMode]);

    useEffect(() => {
        setDarkMode(true);
    }, []);

    return (
        <Grid
            container
            flexDirection={"column"}
            style={{
                minHeight: "100vh"
            }}>
            <AuthProvider>
                <Web3ReactProvider getLibrary={getLibrary}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <ComponentContainer>
                            <Header />
                        </ComponentContainer>
                        <ComponentContainer>
                            <MainSponsorBanner />
                        </ComponentContainer>
                        <ComponentContainer>
                            <NeftDataProvider>
                                <ApplicationRoutes />
                            </NeftDataProvider>
                        </ComponentContainer>
                        <footer style={{ marginTop: "auto", width: "100%" }}>
                            <Footer buildVersion={buildVersion} />
                        </footer>
                    </ThemeProvider>
                </Web3ReactProvider>
            </AuthProvider>
        </Grid>
    );
};

export default App;
