import { HeaderRendererProps } from "react-data-grid";
import HeaderTooltipView from "../../commoncomponents/HeaderTooltip";
import { renderValue } from "../common/grid/Utils";
import { renderColumn } from "../dashboard/hooks";
import ProjectNameView from "../neftdata/ProjectNameView";

export const renderLiveSaleColumns = (isDarkMode: boolean, isMobileView: boolean) => [
    {
        key: "collectionName",
        name: "Collection",
        frozen: true,
        width: isMobileView ? "50%" : "23%",
        formatter: (rows: any) => (
            <ProjectNameView
                openWebsiteOnClick
                isDarkMode={isDarkMode}
                projectName={rows.row.collectionName}
                socialLinks={rows.row.socialLinks}
                collectId={rows.row.contractId}
            />
        ),
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Collection"
                isMobileView={isMobileView}
                headerProps={props}
                hideTooltip
                isNotCenter
            />
        ),
        sortable: true
    },
    {
        key: "minSale",
        name: "MIN Sale",
        frozen: isMobileView ? false : true,
        sortable: true,
        sortDescendingFirst: true,
        width: "8%",
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Floor"
                description="Lowest NFT price sold in the secondary market"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => renderValue(`${rows.row.minSale}${rows.row.minSale ? " Ξ" : ""}`, isDarkMode)
    },
    {
        key: "medianSale",
        name: "MEDIAN Sale",
        sortable: true,
        width: "8%",
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Median"
                description="The middle NFT price sold in the secondary market"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => renderValue(`${rows.row.medianSale}${rows.row.medianSale ? " Ξ" : ""}`, isDarkMode)
    },
    {
        key: "maxSale",
        name: "MAX Sale",
        sortable: true,
        width: "8%",
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Max"
                description="The maximum NFT price sold in the secondary market"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => renderValue(`${rows.row.maxSale}${rows.row.maxSale ? " Ξ" : ""}`, isDarkMode)
    },
    {
        key: "vwap",
        name: "VWAP",
        sortable: true,
        width: "8%",
        sortDescendingFirst: true,
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="VWAP"
                description="Volume-weighted average price, using Floor, Max, and Last Price Matched)"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => renderValue(`${rows.row.vwap}${rows.row.vwap ? " Ξ" : ""}`, isDarkMode)
    },
    {
        key: "totVol",
        name: "Volume",
        sortable: true,
        width: "8%",
        sortDescendingFirst: true,
        formatter: (rows: any) =>
            renderValue(
                `${rows.row.totVol ? `${rows.row.totVol.toFixed(rows.row.totVol >= 1 ? 0 : 2)} Ξ` : ""}`,
                isDarkMode
            )
    },
    {
        key: "whalesBuysCount",
        name: "Whales Buys",
        sortable: true,
        sortDescendingFirst: true,
        width: "11%",
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Whales Buys"
                description="Total number of NFTs bought by Whales"
                isMobileView={isMobileView}
                headerProps={props}
            />
        ),
        formatter: (rows: any) => renderColumn(`${rows.row.whalesBuysCount}`, rows.row.whalesBuysCount > 0)
    },
    {
        key: "percUnique",
        name: "Unique%",
        width: "9%",
        sortable: true,
        sortDescendingFirst: true,
        formatter: (rows: any) => renderValue(`${rows.row.percUnique}%`, isDarkMode),
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Unique %"
                description="Unique wallets that bought an NFT"
                isMobileView={isMobileView}
                headerProps={props}
            />
        )
    },
    {
        key: "sweepNfts",
        width: "10%",
        sortable: true,
        sortDescendingFirst: true,
        formatter: (rows: any) => renderValue(`${rows.row.sweepNfts}`, isDarkMode),
        headerRenderer: (props: HeaderRendererProps<any>) => (
            <HeaderTooltipView
                columnTitle="Sweep NFTs"
                description="The total number of NFTs bought with transactions having more than one NFT per transaction"
                isMobileView={isMobileView}
                headerProps={props}
            />
        )
    },
    {
        key: "numTrans",
        name: "Sales",
        sortable: true,
        sortDescendingFirst: true,
        formatter: (rows: any) => renderValue(rows.row.numTrans, isDarkMode)
    }
];
