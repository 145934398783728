import { Client } from "../../commoncomponents/Base/api";
import { getHuntersApiUrl } from "../../constants/apiConstants";
import { Hunter } from "./types";

const transformHuntersTableData = (data: any): Hunter[] => {
    if (!data) {
        return [];
    }
    return data.map((row: Hunter) => ({
        referringUser: row.referringUser,
        nftsHunted: row.nftsHunted,
        averageScore: row.averageScore || "",
        countSoldOut: row.countSoldOut
    }));
};

export const getHuntersData = () =>
    Client.getInstance()
        .getData(getHuntersApiUrl())
        .then(({ data }) => Promise.resolve(transformHuntersTableData(data)));
