/* eslint-disable no-console */
import { Button, Grid, Paper } from "@mui/material";
import { ethers } from "ethers";
import React, { FC, useState } from "react";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { TargetPaymentInfo } from "./Types";

type MakePaymentViewProps = {
    targetPaymentInfo: TargetPaymentInfo;
    reportPaymentMade: (txHash: string) => void;
};

const MakePaymentView: FC<MakePaymentViewProps> = ({ targetPaymentInfo, reportPaymentMade }) => {
    const [error, setError] = useState("");
    const [txs, setTxs] = useState<ethers.providers.TransactionResponse[]>([]);
    const startPayment = async () => {
        try {
            if (!targetPaymentInfo) {
                return;
            }
            await window.ethereum.request({ method: "eth_requestAccounts" });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            ethers.utils.getAddress(targetPaymentInfo.securedWalletAddress);
            const tx = await signer.sendTransaction({
                to: targetPaymentInfo.securedWalletAddress,
                value: ethers.utils.parseEther(targetPaymentInfo.paymentValue)
            });
            console.log("tx", tx);
            setTxs([tx]);

            reportPaymentMade(tx.hash);

            // console.log("Transaction request successfully sent! See Etherscan for details:");
            // console.log(`https://rinkeby.etherscan.io/tx/${tx.hash}`);
            // console.log("Now just waiting for transaction to be completed..");

            // const completedTransaction = await tx.wait();
            // console.log("logs bloom", completedTransaction.logsBloom);
            // delete completedTransaction.logsBloom;
            // console.log("Transaction request successfully sent! Details:");
            // console.log(completedTransaction);
        } catch (err: any) {
            setError(err.message);
        }
    };

    const handleSubmit = async (e: any) => {
        setError("");
        await startPayment();
    };

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
                <div style={{ marginTop: 10, display: "flex", justifyContent: "center" }}>
                    {targetPaymentInfo && (
                        <Button
                            onClick={(e) => {
                                handleSubmit(e);
                            }}>
                            Buy cryptoscores Founder Membership
                        </Button>
                    )}
                </div>
                {error && <div style={{ color: "red" }}>{error}</div>}
            </Grid>
        </Grid>
    );
};
export default MakePaymentView;
