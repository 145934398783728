export const average = (data: number[]): number => {
    const sum = data.reduce((sum, value) => sum + value, 0);
    return sum / data.length;
};

export const standardDeviation = (values: number[]): number => {
    const avg = average(values);
    const squareDiffs = values.map((value) => Math.pow(value - avg, 2));
    const avgSquareDiff = average(squareDiffs);
    return Math.sqrt(avgSquareDiff);
};

export const logValue = (value: number): number => Math.log(Math.max(value, 0.000001));

export const avgLogValue = (values: number[]): number => {
    const logValues = values.map((value) => logValue(value));
    return average(logValues);
};

export const stdDeviationOfLogValues = (values: number[]): number => {
    const logValues = values.map((value) => logValue(value));
    return standardDeviation(logValues);
};
