import { Grid, TextField, Typography } from "@mui/material";
import React, { useState, FC } from "react";
import { FilterButton } from "../neftdata/style";
import { AdvanceFilterType } from "./Type";

type AdvanceFilterProps = {
    mintPriceMinMax: number[];
    supplyPriceMinMax: number[];
    applyFilter: (ranges: AdvanceFilterType) => void;
};

const AdvanceFilter: FC<AdvanceFilterProps> = ({ mintPriceMinMax, supplyPriceMinMax, applyFilter }) => {
    const [mintPriceRange, setMintPriceRange] = useState(["", ""]);
    const [supplyPriceRange, setSupplyPriceRange] = useState(["", ""]);

    const handleSubmit = () => {
        applyFilter({
            mintPriceMinMax: [
                mintPriceRange[0] === "" ? mintPriceMinMax[0] : parseFloat(mintPriceRange[0]),
                mintPriceRange[1] === "" ? mintPriceMinMax[1] : parseFloat(mintPriceRange[1])
            ],
            supplyPriceMinMax: [
                supplyPriceRange[0] === "" ? supplyPriceMinMax[0] : parseFloat(supplyPriceRange[0]),
                supplyPriceRange[1] === "" ? supplyPriceMinMax[1] : parseFloat(supplyPriceRange[1])
            ]
        });
    };

    return (
        <Grid>
            <Grid container>
                <Grid item xs={12} md={3}>
                    <Typography fontWeight={"bold"} fontSize={"12px"} style={{ marginTop: 10 }}>Mint Price ETH</Typography>
                    <Grid container alignItems={"center"} spacing={2} style={{ marginTop: -10 }}>
                        <Grid item xs={4}>
                            <TextField
                                onChange={({ target }) => setMintPriceRange((prev) => [target.value, prev[1]])}
                                label={"Min"}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={"bold"} fontSize={"12px"}>to</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                onChange={({ target }) => setMintPriceRange((prev) => [prev[0], target.value])}
                                label={"Max"}
                                size={"small"}
                            />
                        </Grid>
                    </Grid>
                    <Typography fontWeight={"bold"} fontSize={"12px"} style={{ marginTop: 10 }}>Supply</Typography>
                    <Grid container alignItems={"center"} spacing={2} style={{ marginTop: -10 }}>
                        <Grid item xs={4}>
                            <TextField
                                onChange={({ target }) => setSupplyPriceRange((prev) => [target.value, prev[1]])}
                                label={"Min"}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={"bold"} fontSize={"12px"}>to</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                onChange={({ target }) => setSupplyPriceRange((prev) => [prev[0], target.value])}
                                label={"Max"}
                                size={"small"}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: -5 }}>
                        <Grid item xs={8}>
                            <FilterButton selected={0} style={{ width: 250 }} onClick={handleSubmit}>
                                Apply filters
                            </FilterButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AdvanceFilter;
