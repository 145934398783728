import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import DurationFilterView from "../../commoncomponents/durationfilter/DurationFilterView";
import ListComponent from "../../commoncomponents/ListComponent";
import LoadingComponent from "../../commoncomponents/LoadingComponent";
import { NotFinancialAdviceMessage } from "../../commoncomponents/NotFinancialAdviceMessage";
import { MAX_POLLING_COUNT, MINT_RADAR_POLLING_DELAY } from "../../constants/appConstants";
import {
    filterByTimeMintRadarSalesOptions,
    filterByTimeMintRadarMintsOptions,
    getFilterByTimeOptionById
} from "../../constants/filterOptions";
import { DataGridContainer } from "../neftdata/style";
import UseIntervalHook from "../payment/UseIntervalHook";
import { getMintRadarColumns } from "./hooks";
import { MintRadarType } from "./types";
import { getMintRadarData } from "./util";
import fadeImage from "../../images/mintradar_fade_btn.png";
import NonPaidUserWrapper from "../common/NonPaidUserWrapper";
import { AuthContext } from "../../context/AuthenticationProvider";
import { moveToCollectionId } from "../../utils/siteUtil";

const MintRadarView = () => {
    const [selectedMintFilter, setSelectedMintFilter] = useState<string>("1h");
    const [selectedSaleFilter, setSelectedSaleFilter] = useState<string>("1h");
    const [mintRadarRows, setMintRadarRows] = useState<MintRadarType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { isPaidUser } = useContext(AuthContext);

    useEffect(() => {
        isPaidUser && fetchMintRadar(true);
    }, [selectedMintFilter, selectedSaleFilter]);

    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(800));

    UseIntervalHook(
        () => {
            isPaidUser && fetchMintRadar(false);
        },
        ["5m", "30m", "1h"].includes(selectedSaleFilter) ? MINT_RADAR_POLLING_DELAY : 0,
        MAX_POLLING_COUNT
    );

    const fetchMintRadar = useCallback(
        (showLoadIndicator) => {
            showLoadIndicator && setIsLoading(true);
            getMintRadarData(
                getFilterByTimeOptionById(filterByTimeMintRadarMintsOptions, selectedMintFilter),
                getFilterByTimeOptionById(filterByTimeMintRadarSalesOptions, selectedSaleFilter)
            )
                .then((res) => {
                    setMintRadarRows(res);
                })
                .catch(() => setMintRadarRows([]))
                .finally(() => setIsLoading(false));
        },
        [selectedMintFilter, selectedSaleFilter]
    );

    return (
        <DataGridContainer style={{ marginTop: 30 }}>
            <Grid container justifyContent="center">
                <Grid item style={{ padding: mobileView ? 10 : 0 }} xs={mobileView ? 12 : 10}>
                    <NonPaidUserWrapper imageSrc={fadeImage}>
                        <div style={{ display: "flex" }}>
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "20px"
                                }}
                            >
                                Compare sales on secondary market for newly minted projects
                            </div>
                            <div>
                                <DurationFilterView
                                    title="Mint"
                                    filterOptions={filterByTimeMintRadarMintsOptions}
                                    selectedFilter={selectedMintFilter}
                                    setSelectedFilter={(selectedOption: string) =>
                                        setSelectedMintFilter(selectedOption)
                                    }
                                />

                                <div style={{ marginTop: 10 }}>
                                    <DurationFilterView
                                        title="Sales"
                                        filterOptions={filterByTimeMintRadarSalesOptions}
                                        selectedFilter={selectedSaleFilter}
                                        setSelectedFilter={(selectedOption: string) =>
                                            setSelectedSaleFilter(selectedOption)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <NotFinancialAdviceMessage />
                        <LoadingComponent isLoading={isLoading}>
                            <ListComponent
                                columns={getMintRadarColumns(true, mobileView)}
                                isDarkMode={true}
                                compact={false}
                                items={mintRadarRows}
                                initialSortDirection={"DESC"}
                                onRowClick={(rows, columnName) => {
                                    if (columnName !== "collectionName") {
                                        moveToCollectionId(rows.contractId);
                                    }
                                }}
                            />
                        </LoadingComponent>
                    </NonPaidUserWrapper>
                </Grid>
            </Grid>
        </DataGridContainer>
    );
};

export default MintRadarView;
